// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Account, Concept, Customer, Feature, AdminRole, AdminGroup, UserConcepts, Language, Timeline, Item, Receipt, Invoice, ZatcaInvoice, Invoker, ZatcaInvoicesSequence, BranchAddress, Header, Branch, Buyer, CommercialDiscountData, ItemDiscountData, TaxableItem, ItemData, ExtraReceiptDiscountData, TaxTotal, DocumentType, Issuer, Receiver, UnitValue, Tax, InvoiceLine, ZatcaInvoiceLine } = initSchema(schema);

export {
  Account,
  Concept,
  Customer,
  Feature,
  AdminRole,
  AdminGroup,
  UserConcepts,
  Language,
  Timeline,
  Item,
  Receipt,
  Invoice,
  ZatcaInvoice,
  Invoker,
  ZatcaInvoicesSequence,
  BranchAddress,
  Header,
  Branch,
  Buyer,
  CommercialDiscountData,
  ItemDiscountData,
  TaxableItem,
  ItemData,
  ExtraReceiptDiscountData,
  TaxTotal,
  DocumentType,
  Issuer,
  Receiver,
  UnitValue,
  Tax,
  InvoiceLine,
  ZatcaInvoiceLine
};