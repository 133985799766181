import * as Yup from 'yup'
import { Pages } from '../constants/enums'

const useFormValidations = (slug: string, action: string) => {
  const phoneRegExp = /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/

  const loginValidation = () =>
    Yup.object().shape({
      email: Yup.string()
        .required('Email is required')
        .email('Email is invalid'),
      password: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .max(40, 'Password must not exceed 40 characters'),
    })

  const conceptValidation = () =>
    Yup.object().shape({
      name: Yup.string().required('Name is required'),
      // Credentials
      clientId: Yup.string().required('Client ID is required'),
      clientSecret: Yup.string().required('Client secret is required'),

      // Branch Address Validation
      country: Yup.string().required('Country is required'),
      governate: Yup.string().required('governate is required'),
      regionCity: Yup.string().required('Region City is required'),
      street: Yup.string().required('Street is required'),
      buildingNumber: Yup.string().required('Building Number is required'),
    })

  const accountValidation = () =>
    Yup.object().shape({
      domain: Yup.string().required('Domain is required'),
      siteTitle: Yup.string().required('Site Title is required'),
      tagline: Yup.string().required('Tagline is required'),
      description: Yup.string().required('Description is required'),
      siteAddress: Yup.string().required('Site Address is required'),
    })

  const customerValidation = () =>
    Yup.object().shape({
      ArNumber: Yup.string().required('Ar Number is required'),
      name: Yup.string().required('Name is required'),
      type: Yup.string().required('Type is required'),
      registrationNumber: Yup.string().required(
        'registrationNumber is required',
      ),
      country: Yup.string().required('country is required'),
      governate: Yup.string().required('governate is required'),
      regionCity: Yup.string().required('regionCity is required'),
      street: Yup.string().required('street is required'),
      buildingNumber: Yup.string()
        .required('buildingNumber is required')
        .matches(/^\d{4}$/, 'Building number must be a 4-digit number'),
      postalCode: Yup.string()
        .required('postalCode is required')
        .matches(/^\d{5}$/, 'Postal code must be a 5-digit number'),
    })

  const invokerValidation = () =>
    Yup.object().shape({
      userName: Yup.string().required('User Name is required'),
      password: Yup.string().required('Password is required'),
    })

  const zatcaValidation = () =>
    Yup.object().shape({
      billNumber: Yup.string()
        .required('Bill Number is required')
        .matches(
          /^(\d+(\.\d*)?|\.\d+)$/,
          'Bill Number must contain numbers only',
        )
        .test(
          'positive',
          'Bill Number must be a positive number',
          (value) => typeof value === 'string' && parseFloat(value) > 0,
        ),
      taxExclusiveAmount: Yup.string()
        .required('Net Amount is required')
        .matches(
          /^(\d+(\.\d*)?|\.\d+)$/,
          'Net Amount must contain numbers only',
        )
        .test(
          'positive',
          'Net Amount must be a positive number',
          (value) => typeof value === 'string' && parseFloat(value) > 0,
        ),
      taxInclusiveAmount: Yup.string()
        .required('Total Amount is required')
        .matches(
          /^(\d+(\.\d*)?|\.\d+)$/,
          'Total Amount must contain numbers only',
        )
        .test(
          'positive',
          'Total Amount must be a positive number',
          (value) => typeof value === 'string' && parseFloat(value) > 0,
        ),
    })

  const languageValidation = () =>
    Yup.object().shape({
      name: Yup.string().required('Name is required'),
      code: Yup.string().required('Code is required'),
    })

  const featureValidation = () =>
    Yup.object().shape({
      precedence: Yup.string().required('Precedence is required'),
      name: Yup.string().required('Name is required'),
      slug: Yup.string().required('URL is required'),
    })

  const adminRolesValidation = () =>
    Yup.object().shape({
      name: Yup.string().required('Name is required'),
    })

  const adminGroupsValidation = () =>
    Yup.object().shape({
      name: Yup.string().required('Name is required'),
    })

  const itemValidation = () =>
    Yup.object().shape({
      conceptID: Yup.string().required('Branch is required'),
      internalCode: Yup.string().required('Internal Code is required'),
      itemCode: Yup.string().required('Item Code is required'),
      description: Yup.string().required('Description is required'),
      type: Yup.string().required('Type is required'),
      unit: Yup.string().required('Unit is required'),
    })

  const getValidationSchema = () => {
    switch (slug) {
      case Pages.LOGIN:
        return loginValidation()
      case Pages.ACCOUNTS:
        return accountValidation()
      case Pages.CONCEPTS:
        return conceptValidation()
      case Pages.FEATURES:
        return featureValidation()
      case Pages.LANGUAGES:
        return languageValidation()
      case Pages.ADMIN_ROLES:
        return adminRolesValidation()
      case Pages.ADMIN_GROUPS:
        return adminGroupsValidation()
      case Pages.CUSTOMERS:
        return customerValidation()
      case Pages.ZactaINVOICES:
        return zatcaValidation()
      case Pages.Zacta_CREDIT_NOTES:
        return zatcaValidation()
      case Pages.INVOKERS:
        return invokerValidation()
      case Pages.ITEMS:
        return itemValidation()
      default:
        return Yup.object()
    }
  }

  return {
    getValidationSchema,
  }
}

export default useFormValidations
