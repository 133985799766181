// actions
const SET = "customers/SET";
const SET_SEARCH_TEXT = "customers/SET_SEARCH_TEXT";
const SET_SELECTED = "customers/SET_SELECTED";

const CHANGE_LIMIT = "customers/CHANGE_LIMIT";
const NEXT_ACTION = "customers/NEXT_ACTION";
const SET_NEXT_TOKEN = "customers/SET_NEXT_TOKEN";
const SET_PREVIOUS_TOKENS = "customers/SET_PREVIOUS_TOKENS";

const DEFAULT_STATE = {
  listing: [],
  searchText: "",
  selected: null,

  limit: 10,
  nextToken: null,
  previousTokens: [],
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case SET_SEARCH_TEXT:
      return Object.assign({}, state, { searchText: action.searchText });
    case SET_SELECTED:
      return Object.assign({}, state, { selected: action.model });

    case SET_NEXT_TOKEN:
      return Object.assign({}, state, { nextToken: action.nextToken });
    case SET_PREVIOUS_TOKENS:
      return Object.assign({}, state, {
        previousTokens: action.previousTokens,
      });

    case CHANGE_LIMIT:
      return Object.assign({}, state, {
        limit: action.limit,
        nextToken: action.nextToken,
        nextNextToken: action.nextNextToken,
        previousTokens: action.previousTokens,
      });

    case NEXT_ACTION:
      return Object.assign({}, state, {
        nextToken: action.nextToken,
        previousTokens: action.previousTokens,
      });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setListing = (listing: any[]) => ({ listing, type: SET });

export const setSearchText = (searchText: string) => ({
  searchText,
  type: SET_SEARCH_TEXT,
});

export const setSelected = (model: any) => ({ model, type: SET_SELECTED });

export const changeLimit = (limit: any) => ({
  limit: limit,
  nextToken: null,
  nextNextToken: null,
  previousTokens: [],

  type: CHANGE_LIMIT,
});

export const nextAction = (nextToken: any, previousTokens: any[]) => ({
  nextToken: nextToken,
  previousTokens: previousTokens ? [...previousTokens, nextToken] : [nextToken],

  type: NEXT_ACTION,
});

export const setNextToken = (nextToken: any) => ({
  nextToken,
  type: SET_NEXT_TOKEN,
});

export const setPreviousToken = (previousTokens: any) => ({
  previousTokens,
  type: SET_PREVIOUS_TOKENS,
});
