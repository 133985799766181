// actions
const SET = "features/SET";
const SET_ALL = "features/SET_ALL";
const SET_SEARCH_TEXT = "features/SET_SEARCH_TEXT";
const SET_SELECTED = "features/SET_SELECTED";
const SET_NEXT_TOKEN = "features/SET_NEXT_TOKEN";

const DEFAULT_STATE = {
  listing: [],
  listingAll: [],
  searchText: "",
  selected: "",

  nextToken: null,
  nextNextToken: undefined,
  previousTokens: [],
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case SET_ALL:
      return Object.assign({}, state, { listingAll: action.listing });
    case SET_SEARCH_TEXT:
      return Object.assign({}, state, { searchText: action.searchText });
    case SET_SELECTED:
      return Object.assign({}, state, { conceptsSelected: action.concept });
    case SET_NEXT_TOKEN:
      return Object.assign({}, state, { nextToken: action.id });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setListing = (listing: any[]) => ({ listing, type: SET });
export const setListingAll = (listing: any[]) => ({ listing, type: SET_ALL });
export const setSelected = (single: any) => ({ single, type: SET_SELECTED });
export const setSearchText = (searchText: string) => ({
  searchText,
  type: SET_SEARCH_TEXT,
});
export const setNextToken = (id: any) => ({ id, type: SET_NEXT_TOKEN });
