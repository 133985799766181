import { Auth } from "aws-amplify";
import awsmobile from "../aws-exports";
import AWS from 'aws-sdk';

function getAmplifyEnv() {
    const cloudLogicCustom = awsmobile.aws_cloud_logic_custom[0];
    const endpointUrl = cloudLogicCustom.endpoint;
    const parts = endpointUrl.split('/'); 
    const env = parts[parts.length - 1]; 
    return env;
};

 async function getToken() {
    try {
        const userSession = await Auth.currentSession()
        const accessTokenObj = userSession?.getAccessToken()
        const accessToken = accessTokenObj.getJwtToken()
        return accessToken;
    } catch (err:any) {
        console.log({ err })
     }
};

export default async function invokeLambda(functionName: string, requestBody: any) {
  try {
    AWS.config.region = awsmobile.aws_cognito_region;
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: awsmobile.aws_cognito_identity_pool_id,
    });
    let lambda = new AWS.Lambda();

    const accessToken = await getToken();
    const options = {
      method: 'POST',
        headers: {
        authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
      };
      
    let endpointWithParams = `${awsmobile.aws_cloud_logic_custom[0].endpoint}`;

    let response = await lambda
      .invoke({
        FunctionName: `${functionName}-${getAmplifyEnv()}`,
          Payload: JSON.stringify({
                    ...options,
                    uri: endpointWithParams  
            }),
      })
      .promise();

    let payload: any;
    let body: any;
    if (response.Payload) {
      if (typeof response.Payload === 'string') {
        payload = JSON.parse(response.Payload);
      } else {
        console.log(response);
        throw new Error('the payload is not of type string');
      }
    }

    if (payload.body) {
      if (typeof payload.body === 'string') {
        body = JSON.parse(payload.body);
        return body;
      } else {
        console.log(response);
        throw new Error('the body is not of type string');
      }
    } else {
      return payload;
    }
  } catch (error) {
      console.error('Error invoking lambda function:', error);
  }
}