import { deleteFeature, updateFeature } from "./../graphql/mutations";
import { CreateFeatureInput, UpdateFeatureInput } from "./../models/GQL_API";
import { API } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

import { useDispatch, useSelector } from "react-redux";
import {
  setListing,
  setListingAll,
  setNextToken,
  setSearchText,
  setSelected,
} from "../store/ducks/feature";
import { Feature } from "../models";
import { HeadCell } from "../models/dataTable";
import useApp from "./useApp";
import { getFeature, listFeatures } from "../graphql/queries";
import { createFeature } from "../graphql/mutations";
import { ListingVariables } from "../models/app";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { session, showConfirm, showError } = useApp();
  const listing = useSelector(
    (state: any) => state[`${listingName}`]["listing"]
  );
  const listingAll = useSelector(
    (state: any) => state[`${listingName}`]["listingAll"]
  );

  const nextToken = useSelector(
    (state: any) => state[`${listingName}`]["nextToken"]
  );

  const searchText = useSelector(
    (state: any) => state[`${listingName}`]["searchText"]
  );
  const selected = useSelector(
    (state: any) => state[`${listingName}`]["selected"]
  );

  async function fetch(params: ListingVariables) {
    try {
      const { startIndex, limit, session } = params;

      const filter: any = {
        deleted: { eq: "0" },
      };

      if (searchText) filter.name = { contains: searchText.toLowerCase() };

      const featureList: any = await API.graphql<Feature>({
        query: listFeatures,
        variables: { filter, limit, nextToken: nextToken },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      const currentNextToken = featureList.data.listFeatures.nextToken;
      const listing = featureList.data.listFeatures.items;

      dispatch(setListing(listing));
      dispatch(setNextToken(currentNextToken));

      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message);
    }
  }

  async function fetchAll(params: ListingVariables) {
    try {
      const { session } = params;

      const filter: any = {
        deleted: { eq: "0" },
      };

      const featureList: any = await API.graphql<Feature>({
        query: listFeatures,
        variables: { filter },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      const listing = featureList.data.listFeatures.items;

      dispatch(setListingAll(listing));

      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message);
    }
  }

  async function get(id: string) {
    try {
      let single: Feature | undefined;
      if (listing.length !== 0) {
        single = listing.find((resource: any) => resource.id === id);
      }

      if (single === undefined) {
        const listing: any = await API.graphql<Feature>({
          query: getFeature,
          variables: { id },
          authMode: true
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
        single = listing.data.getFeature;
      }

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function create(data: any) {
    try {
      const createInput: CreateFeatureInput = {
        precedence: data.precedence,
        name: data.name,
        slug: data.slug,
        deleted: "0",
        createdAt: new Date().getTime().toString(),
        createdByID: session.sub,
        createdByName: session.name,
      };

      if (data.icon) createInput.icon = data.icon;
      if (data.parent) createInput.parent = data.parent;
      if (data.private) createInput.private = data.private;

      await API.graphql<Feature>({
        query: createFeature,
        variables: { input: createInput },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      showConfirm(`New ${singleName} has been created successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function update(id: string, data: any) {
    try {
      const original = await get(id);

      if (original) {
        const updateInput: UpdateFeatureInput = {
          id: original.id,
          precedence: data.precedence ? data.precedence : original!.precedence,
          name: data.name ? data.name : original!.name,
          slug: data.slug ? data.slug : original!.slug,
          icon: data.icon ? data.icon : original!.icon,
          parent: data.parent ? data.parent : original!.parent,
          private: data.private ? data.private : original!.private,
        };

        await API.graphql<Feature>({
          query: updateFeature,
          variables: { input: updateInput },
          authMode: true
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
      }
      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function trash(id: string) {
    try {
      const original = await get(id);

      if (original) {
        const updateInput: UpdateFeatureInput = {
          id: original.id,
          deleted: "1",
        };

        await API.graphql<Feature>({
          query: updateFeature,
          variables: { input: updateInput },
          authMode: true
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
      }

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(resourceIds: any) {
    for (let id of resourceIds) {
      try {
        await trash(id);
      } catch (err: Error | any) {
        showError(err);
      }
    }

    dispatch(
      setListing(
        listing.filter((resource: any) => !resourceIds.has(resource.id))
      )
    );

    showConfirm(
      `${resourceIds.size} ${listingName} items has been moved to trash`
    );
  }

  async function remove(id: any) {
    try {
      await API.graphql<Feature>({
        query: deleteFeature,
        variables: { id: id },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      dispatch(
        setListing(
          listing.filter((resource: any) => resource.id !== id)
        )
      );

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      console.log(err);
      showError(err);
    }
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["name"];

  const options: any[] = [{ label: "None", value: "-1" }];

  if (listing)
    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }

  const api: any = {};

  api[`${listingName}Listing`] = listing;
  api[`${listingName}AllListing`] = listingAll;
  api[`${listingName}Options`] = options;
  api[`${listingName}SearchText`] = searchText;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Model`] = Feature as any;
  api[`${listingName}Selected`] = selected;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchAll`] = fetchAll;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}ChangeListing`] = (listing: Feature[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeAllListing`] = (listing: Feature[]) =>
    dispatch(setListingAll(listing));
  api[`${listingName}Search`] = (searchText: string) =>
    dispatch(setSearchText(searchText));
  api[`${listingName}Select`] = (conceptID: string) =>
    dispatch(setSelected(conceptID));

  return api;
};

export default useResource;
