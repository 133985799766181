import { Invoice } from './../API'
import { CONCEPT_TYPES, FormActions, Pages } from '../constants/enums'
import useLocalization from './useLocalization'
import useLanguage from './useLanguage'
import useFeature from './useFeature'
import useAdmin from './useAdmin'
import useAdminRole from './useAdminRole'
import useConcept from './useConcept'
import { itemTypeOptions } from '../constants/options'
import useCustomer from './useCustomer'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

const useFormFields = (slug: string, model?: any) => {
  const { strings } = useLocalization()
  const { languagesOptions } = useLanguage('languages', 'language')
  const { featuresOptions } = useFeature('features', 'feature')
  const { adminsCognitoGroups, adminsOptions } = useAdmin('admins', 'admin')
  const { adminRolesOptions } = useAdminRole('adminRoles', 'adminRole')
  const { conceptsOptions } = useConcept('concepts', 'concept')
  const { customersOptions } = useCustomer('customers', 'customer')
  const { conceptsSelected } = useConcept('concepts', 'concept')

  const conceptsListing = useSelector((state: any) => state.concepts.listing)
  const [isZatca, setIsZatca] = useState(false)

  useEffect(() => {
    const selectedConcept = conceptsListing.find(
      (concept: any) => concept.id === conceptsSelected,
    )
    if (!selectedConcept) return
    if (selectedConcept.type === CONCEPT_TYPES.Z) {
      setIsZatca(true)
    } else {
      setIsZatca(false)
    }
  }, [conceptsListing])

  const companyTypeOptions = [
    { label: 'B - Business in Egypt', value: 'B' },
    { label: 'Z - Business in Saudi Arabia', value: 'Z' },
    { label: 'P - Natural Person', value: 'P' },
    { label: 'F - Foreigner', value: 'F' },
  ]

  const loginFields = () => [
    {
      name: 'email',
      label: strings.email,
      type: 'textField',
      autoFocus: true,
      defaultValue: '',
    },
    {
      name: 'password',
      label: strings.password,
      type: 'password',
      autoFocus: false,
      defaultValue: '',
    },
  ]

  const registerFields = () => [
    {
      name: 'name',
      label: 'Full Name',
      type: 'textField',
      autoFocus: true,
      defaultValue: '',
    },
    {
      name: 'email',
      label: strings.email,
      type: 'textField',
      autoFocus: false,
      defaultValue: '',
    },
    {
      name: 'password',
      label: strings.password,
      type: 'password',
      autoFocus: false,
      defaultValue: '',
    },
  ]

  const adminFields = () => [
    {
      name: 'name',
      label: 'Full Name',
      type: 'textField',
      autoFocus: true,
      defaultValue: '',
    },
    {
      name: 'email',
      label: strings.email,
      type: 'textField',
      autoFocus: false,
      defaultValue: '',
    },
    {
      name: 'password',
      label: strings.password,
      type: 'password',
      autoFocus: false,
      defaultValue: '',
    },
    {
      name: 'group',
      label: 'Admin Group',
      type: 'select',
      autoFocus: false,
      defaultValue: adminsCognitoGroups[0].value,
      options: adminsCognitoGroups,
    },
  ]

  const forgetPasswordFields = () => [
    {
      name: 'email',
      label: strings.email,
      type: 'textField',
      autoFocus: true,
      defaultValue: '',
    },
  ]

  const resetPasswordFields = () => [
    {
      name: 'email',
      label: strings.email,
      type: 'textField',
      autoFocus: true,
      defaultValue: '',
    },
    {
      name: 'code',
      label: strings.code,
      type: 'textField',
      autoFocus: false,
      defaultValue: '',
    },
    {
      name: 'newPassword',
      label: strings.password,
      type: 'password',
      autoFocus: false,
      defaultValue: '',
    },
  ]

  const accountFields = () => [
    {
      name: 'siteTitle',
      label: 'Site Title',
      type: 'textField',
      autoFocus: false,
      defaultValue: model ? model.siteTitle : '',
    },
    {
      name: 'domain',
      label: 'Domain',
      type: 'textField',
      autoFocus: true,
      defaultValue: model ? model.domain : '',
    },
    {
      name: 'tagline',
      label: 'Tagline',
      type: 'textField',
      autoFocus: false,
      defaultValue: model ? model.tagline : '',
    },
    {
      name: 'description',
      label: 'Description',
      type: 'textField',
      autoFocus: false,
      defaultValue: model ? model.description : '',
    },
    {
      name: 'siteAddress',
      label: 'Site Address',
      type: 'textField',
      autoFocus: false,
      defaultValue: model ? model.siteAddress : '',
    },
    {
      name: 'defaultLanguage',
      label: 'Default Language',
      type: 'select',
      autoFocus: false,
      defaultValue: model ? model.defaultLanguage : languagesOptions[0].value,
      options: languagesOptions,
    },
  ]

  const adminRoleFields = () => [
    {
      name: 'name',
      label: 'Name',
      type: 'textField',
      autoFocus: true,
      defaultValue: model ? model.name : '',
    },
    {
      name: 'description',
      label: 'Description',
      type: 'textField',
      autoFocus: false,
      defaultValue: model ? model.description : '',
    },
  ]

  const adminGroupsFields = () => [
    {
      name: 'name',
      label: 'Name',
      type: 'textField',
      autoFocus: true,
      defaultValue: model ? model.name : '',
    },
    {
      name: 'description',
      label: 'Description',
      type: 'textField',
      autoFocus: false,
      defaultValue: model ? model.description : '',
    },
  ]

  const conceptFields = () => {
    const defaultTypeID: string = 'B'

    return [
      {
        name: 'name',
        label: 'Concept Name',
        type: 'textField',
        autoFocus: true,
        defaultValue: model ? model.name : '',
      },
      {
        name: 'hotelCode',
        label: 'Hotel Code',
        type: 'textField',
        autoFocus: true,
        defaultValue: model ? model.hotelCode : '',
      },
      {
        name: 'prefix',
        label: 'Document Prefix',
        type: 'textField',
        autoFocus: true,
        defaultValue: model ? model.prefix : '',
      },
      {
        name: 'type',
        label: 'Type',
        type: 'select',
        autoFocus: false,
        defaultValue: model ? model.type : defaultTypeID,
        options: companyTypeOptions,
      },
      {
        name: 'rin',
        label: 'RIN',
        type: 'textField',
        autoFocus: false,
        defaultValue: model ? model.rin : '',
      },
      {
        name: 'companyTradeName',
        label: 'Company Trade Name',
        type: 'textField',
        autoFocus: false,
        defaultValue: model ? model.companyTradeName : '',
      },
      {
        name: 'branchCode',
        label: 'Branch Code',
        type: 'textField',
        autoFocus: false,
        defaultValue: model ? model.branchCode : '',
      },
      {
        name: 'activityCode',
        label: 'Activity Code',
        type: 'textField',
        autoFocus: false,
        defaultValue: model ? model.activityCode : '',
      },

      {
        name: 'clientId',
        label: 'client ID',
        type: 'textField',
        autoFocus: false,
        defaultValue: model ? model.clientId : '',
      },
      {
        name: 'productionCertificate',
        label: 'Production Certificate',
        type: 'password',
        autoFocus: false,
        defaultValue: model ? model.productionCertificate : '',
      },
      {
        name: 'productionApiSecret',
        label: 'Production API Secret',
        type: 'password',
        autoFocus: false,
        defaultValue: model ? model.productionApiSecret : '',
      },
      {
        name: 'clientSecret',
        label: 'Client Secret',
        type: 'password',
        autoFocus: false,
        defaultValue: model ? model.clientSecret : '',
      },
      {
        name: 'synchronizeProcess',
        label: 'Synchronize Integration Process',
        type: 'checkbox',
        autoFocus: false,
        defaultValue: model ? model.synchronizeProcess : false,
      },
    ]
  }

  const getBranchAddressFields = () => [
    {
      name: 'branchId',
      label: 'Branch ID',
      type: 'textField',
      autoFocus: false,
      defaultValue: model ? model.branchAddress?.branchId : '',
    },
    {
      name: 'country',
      label: 'country',
      type: 'textField',
      autoFocus: false,
      defaultValue: model ? model.branchAddress?.country : '',
    },
    {
      name: 'governate',
      label: 'governate',
      type: 'textField',
      autoFocus: false,
      defaultValue: model ? model.branchAddress?.governate : '',
    },
    {
      name: 'regionCity',
      label: 'regionCity',
      type: 'textField',
      autoFocus: false,
      defaultValue: model ? model.branchAddress?.regionCity : '',
    },
    {
      name: 'citySubdivision',
      label: 'City Subdivision',
      type: 'textField',
      autoFocus: false,
      defaultValue: model ? model.branchAddress?.citySubdivision : '',
    },
    {
      name: 'street',
      label: 'street',
      type: 'textField',
      autoFocus: false,
      defaultValue: model ? model.branchAddress?.street : '',
    },
    {
      name: 'buildingNumber',
      label: 'buildingNumber',
      type: 'textField',
      autoFocus: false,
      defaultValue: model ? model.branchAddress?.buildingNumber : '',
    },
    {
      name: 'postalCode',
      label: 'postalCode',
      type: 'textField',
      autoFocus: false,
      defaultValue: model ? model.branchAddress?.postalCode : '',
    },
    {
      name: 'plotIdentification',
      label: 'Plot Identification',
      type: 'textField',
      autoFocus: false,
      defaultValue: model ? model.branchAddress?.plotIdentification : '',
    },
    // {
    //   name: 'floor',
    //   label: 'floor',
    //   type: 'textField',
    //   autoFocus: false,
    //   defaultValue: model ? model.branchAddress?.floor : '',
    // },
    // {
    //   name: 'room',
    //   label: 'room',
    //   type: 'textField',
    //   autoFocus: false,
    //   defaultValue: model ? model.branchAddress?.room : '',
    // },
    // {
    //   name: 'landmark',
    //   label: 'landmark',
    //   type: 'textField',
    //   autoFocus: false,
    //   defaultValue: model ? model.branchAddress?.landmark : '',
    // },
    // {
    //   name: 'additionalInformation',
    //   label: 'additionalInformation',
    //   type: 'textField',
    //   autoFocus: false,
    //   defaultValue: model ? model.branchAddress?.additionalInformation : '',
    // },
  ]

  const getCustomerAddressFields = () => [
    // {
    //   name: 'branchId',
    //   label: 'Branch ID',
    //   type: 'textField',
    //   autoFocus: false,
    //   defaultValue: model ? model.address?.branchId : '',
    // },
    {
      name: 'country',
      label: 'Country',
      type: 'textField',
      autoFocus: false,
      defaultValue: model ? model.address?.country : '',
    },
    {
      name: 'governate',
      label: 'Governate',
      type: 'textField',
      autoFocus: false,
      defaultValue: model ? model.address?.governate : '',
    },
    {
      name: 'regionCity',
      label: 'Region City',
      type: 'textField',
      autoFocus: false,
      defaultValue: model ? model.address?.regionCity : '',
    },
    {
      name: 'street',
      label: 'Street',
      type: 'textField',
      autoFocus: false,
      defaultValue: model ? model.address?.street : '',
    },
    {
      name: 'buildingNumber',
      label: 'Building Number',
      type: 'textField',
      autoFocus: false,
      defaultValue: model ? model.address?.buildingNumber : '',
    },
    {
      name: 'postalCode',
      label: 'Postal Code',
      type: 'textField',
      autoFocus: false,
      defaultValue: model ? model.address?.postalCode : '',
    },
    // {
    //   name: 'floor',
    //   label: 'floor',
    //   type: 'textField',
    //   autoFocus: false,
    //   defaultValue: model ? model.address?.floor : '',
    // },
    // {
    //   name: 'room',
    //   label: 'room',
    //   type: 'textField',
    //   autoFocus: false,
    //   defaultValue: model ? model.address?.room : '',
    // },
    // {
    //   name: 'landmark',
    //   label: 'landmark',
    //   type: 'textField',
    //   autoFocus: false,
    //   defaultValue: model ? model.address?.landmark : '',
    // },
    // {
    //   name: 'additionalInformation',
    //   label: 'additionalInformation',
    //   type: 'textField',
    //   autoFocus: false,
    //   defaultValue: model ? model.address?.additionalInformation : '',
    // },
  ]

  const featureFields = () => [
    {
      name: 'precedence',
      label: 'Precedence',
      type: 'textField',
      autoFocus: true,
      defaultValue: model ? model.precedence : '',
    },
    {
      name: 'name',
      label: 'Feature Name',
      type: 'textField',
      autoFocus: false,
      defaultValue: model ? model.name : '',
    },
    {
      name: 'icon',
      label: 'Feature Icon',
      type: 'textField',
      autoFocus: false,
      defaultValue: model ? model.icon : '',
    },
    {
      name: 'slug',
      label: 'Feature Slug',
      type: 'textField',
      autoFocus: false,
      defaultValue: model ? model.slug : '',
    },
    {
      name: 'parent',
      label: 'Parent',
      type: 'select',
      autoFocus: false,
      defaultValue: model
        ? model.parent
          ? model.parent
          : featuresOptions[0].value
        : featuresOptions[0].value,
      options: featuresOptions,
    },
    {
      name: 'private',
      label: 'Private',
      type: 'checkbox',
      autoFocus: false,
      defaultValue: model ? model.private : false,
    },
  ]

  const languageFields = () => [
    {
      name: 'name',
      label: 'Language Name',
      type: 'textField',
      autoFocus: true,
      defaultValue: model ? model.name : '',
    },
    {
      name: 'code',
      label: 'Language Code',
      type: 'textField',
      autoFocus: false,
      defaultValue: model ? model.code : '',
    },
  ]

  const invoiceFields = () => [
    {
      name: 'internalID',
      label: 'Folio Number',
      type: 'textField',
      autoFocus: true,
      defaultValue: model ? model.internalID : '',
    },
    {
      name: 'netAmount',
      label: 'Net Amount',
      type: 'number',
      autoFocus: true,
      defaultValue: model ? model.netAmount : '',
    },
    {
      name: 'totalAmount',
      label: 'Total Amount',
      type: 'number',
      autoFocus: true,
      defaultValue: model ? model.totalAmount : '',
    },
  ]

  const zactaInvoiceFields = () => [
    {
      name: 'billNumber',
      label: 'Bill Number',
      type: 'textField',
      autoFocus: true,
      defaultValue: model ? model.billNumber : '',
    },
    {
      name: 'taxExclusiveAmount',
      label: 'Net Amount',
      type: 'textField',
      autoFocus: true,
      defaultValue: model ? model.taxExclusiveAmount : '',
    },
    {
      name: 'taxInclusiveAmount',
      label: 'Total Amount',
      type: 'textField',
      autoFocus: true,
      defaultValue: model ? model.taxInclusiveAmount : '',
    },
  ]

  const invokersOperaitonsOptions = [
    { label: 'Invoice', value: 'invoice' },
    { label: 'Credit Note', value: 'creditNote' },
  ]
  const invokersFields = () => [
    {
      name: 'userName',
      label: 'User Name',
      type: 'textField',
      autoFocus: true,
      defaultValue: model ? model.userName : '',
    },
    {
      name: 'password',
      label: 'Password',
      type: 'password',
      autoFocus: true,
      defaultValue: model ? model.password : '',
    },
    {
      name: 'operations',
      label: 'Operations',
      type: 'checkboxes',
      autoFocus: false,
      defaultValue: model ? new Set(model.operations) : new Set([]),
      options: invokersOperaitonsOptions,
      multiple: true,
    },
  ]

  const addInvoiceFields = () => [
    {
      name: 'conceptID',
      label: 'Branch',
      type: 'select',
      autoFocus: true,
      defaultValue: conceptsOptions ? conceptsOptions[0].value : '',
      options: conceptsOptions,
    },
    {
      name: 'customer',
      label: 'Customer',
      type: 'select',
      autoFocus: false,
      defaultValue: customersOptions ? customersOptions[0].value : '',
      options: customersOptions,
    },
    {
      name: 'invoiceNumber',
      label: 'Invoice Number',
      type: 'number',
      autoFocus: false,
      defaultValue: '',
    },
    {
      name: 'invoiceDate',
      label: 'Invoice Date',
      type: 'date',
      autoFocus: false,
      defaultValue: '',
    },
  ]

  const receiptFields = () => [
    {
      name: 'totalSales',
      label: 'Total Sales',
      type: 'number',
      autoFocus: true,
      disabled: true,
      defaultValue: model ? model.totalSales : '',
    },

    {
      name: 'netAmount',
      label: 'Net Amount',
      type: 'number',
      autoFocus: true,
      disabled: true,
      defaultValue: model ? model.netAmount : '',
    },
    {
      name: 'feesAmount',
      label: 'Fees Amount',
      type: 'number',
      autoFocus: true,
      disabled: true,
      defaultValue: model ? model.feesAmount : '',
    },
    {
      name: 'totalAmount',
      label: 'Total Amount',
      type: 'number',
      autoFocus: true,
      disabled: true,
      defaultValue: model ? model.totalAmount : '',
    },
    {
      name: 'paymentMethod',
      label: 'Payment Method',
      type: 'textField',
      autoFocus: false,
      disabled: true,
      defaultValue: model ? model.paymentMethod : '',
    },
  ]

  const receiptImages = () => [
    {
      name: 'qrImage',
      qrString: model ? model.qrString : '',
      label: 'QR Image',
      type: 'image',
      autoFocus: false,
      defaultValue: model ? model.qrImage : '',
    },
  ]

  const customerFields = () => {
    const defaultTypeID: string = 'B'

    return [
      {
        name: 'ArNumber',
        label: 'Ar Number',
        type: 'textField',
        autoFocus: true,
        defaultValue: model ? model.ArNumber : '',
      },
      {
        name: 'name',
        label: 'Name',
        type: 'textField',
        autoFocus: true,
        defaultValue: model ? model.name : '',
      },
      {
        name: 'type',
        label: 'Type',
        type: 'select',
        autoFocus: false,
        defaultValue: model ? model.type : defaultTypeID,
        options: companyTypeOptions,
      },
      {
        name: 'registrationNumber',
        label: 'Registration Number',
        type: 'textField',
        autoFocus: false,
        defaultValue: model ? model.registrationNumber : '',
      },
      // {
      //   name: 'mobileNumber',
      //   label: 'Mobile Number',
      //   type: 'phoneNumber',
      //   autoFocus: false,
      //   defaultValue: model ? model.mobileNumber : '',
      // },
      // {
      //   name: 'paymentNumber',
      //   label: 'Payment Number',
      //   type: 'textField',
      //   autoFocus: false,
      //   defaultValue: model ? model.paymentNumber : '',
      // },
    ]
  }

  const itemFields = () => {
    const defaultTypeID: string = 'EGS'
    return [
      {
        name: 'description',
        label: 'Description',
        type: 'textField',
        autoFocus: false,
        defaultValue: model ? model.description : '',
      },
      {
        name: 'internalCode',
        label: 'Internal Code',
        type: 'textField',
        autoFocus: true,
        defaultValue: model ? model.internalCode : '',
      },
      {
        name: 'itemCode',
        label: 'Item Code',
        type: 'textField',
        autoFocus: true,
        defaultValue: model ? model.itemCode : '',
      },
      {
        name: 'type',
        label: 'Type',
        type: 'select',
        autoFocus: false,
        defaultValue: model ? model.type : defaultTypeID,
        options: itemTypeOptions,
      },
      {
        name: 'unit',
        label: 'Unit',
        type: 'textField',
        autoFocus: false,
        defaultValue: model ? model.unit : '',
      },
      {
        name: 'tax1',
        label: 'Tax 1',
        type: 'textField',
        autoFocus: false,
        defaultValue: model ? model.tax1 : '',
      },
      {
        name: 'tax2',
        label: 'Tax 2',
        type: 'textField',
        autoFocus: false,
        defaultValue: model ? model.tax2 : '',
      },
      {
        name: 'neglectedItem',
        label: 'Neglected Item',
        type: 'checkbox',
        autoFocus: false,
        defaultValue: model ? model.neglectedItem : false,
      },
    ]
  }

  const accountCheckboxes = () => [
    {
      name: 'languages',
      label: 'Languages',
      type: 'checkboxes',
      autoFocus: false,
      defaultValue: model ? new Set(model.languages) : new Set(),
      options: languagesOptions,
    },
    {
      name: 'features',
      label: 'Features',
      type: 'checkboxes',
      autoFocus: false,
      defaultValue: model ? new Set(model.features) : new Set(),
      options: featuresOptions,
    },
  ]

  const adminGroupsCheckboxes = () => [
    {
      name: 'roles',
      label: 'Admin Roles',
      type: 'checkboxes',
      autoFocus: false,
      defaultValue: model ? new Set(model.roles) : new Set(),
      options: adminRolesOptions,
    },
    {
      name: 'users',
      label: 'Admin Users',
      type: 'checkboxes',
      autoFocus: false,
      defaultValue: model ? new Set(model.users) : new Set(),
      options: adminsOptions,
    },
  ]

  const getFormFields = (
    action?: string,
    numToUpgradeWatch?: any,
    numToDowngradeWatch?: any,
    channelsWatch?: any,
  ) => {
    if (
      action &&
      action === FormActions.ADD &&
      slug === (Pages.INVOICES || Pages.ZactaINVOICES)
    ) {
      return addInvoiceFields()
    }
    switch (slug) {
      case Pages.LOGIN:
        return loginFields()
      case Pages.REGISTER:
        return registerFields()
      case Pages.ADMINS:
        return adminFields()
      case Pages.FORGOT_PASSWORD:
        return forgetPasswordFields()
      case Pages.RESET_PASSWORD:
        return resetPasswordFields()
      case Pages.ACCOUNTS:
        return accountFields()
      case Pages.CONCEPTS:
        return conceptFields()
      case Pages.FEATURES:
        return featureFields()
      case Pages.LANGUAGES:
        return languageFields()
      case Pages.ADMIN_ROLES:
        return adminRoleFields()
      case Pages.ADMIN_GROUPS:
        return adminGroupsFields()
      case Pages.CUSTOMERS:
        return customerFields()
      case Pages.INVOKERS:
        return invokersFields()
      case Pages.RECEIPTS:
        return receiptFields()
      case Pages.INVOICES:
        if (isZatca) {
          return zactaInvoiceFields()
        } else {
          return invoiceFields()
        }
      case Pages.CREDIT_NOTES:
        if (isZatca) {
          return zactaInvoiceFields()
        } else {
          return invoiceFields()
        }
      case Pages.ZactaINVOICES:
        return zactaInvoiceFields()
      case Pages.Zacta_CREDIT_NOTES:
        return zactaInvoiceFields()
      case Pages.ITEMS:
        return itemFields()
      default:
        return []
    }
  }

  const getImageFields = () => {
    switch (slug) {
      case Pages.RECEIPTS:
      case Pages.INVOICES:
      case Pages.ZactaINVOICES:
      case Pages.CREDIT_NOTES:
      case Pages.Zacta_CREDIT_NOTES:
        return receiptImages()

      default:
        return []
    }
  }

  const getAddressFields = () => {
    switch (slug) {
      case Pages.CONCEPTS:
        return getBranchAddressFields()
      case Pages.CUSTOMERS:
        return getCustomerAddressFields()
      default:
        return []
    }
  }

  const getCheckboxesFields = () => {
    switch (slug) {
      case Pages.ACCOUNTS:
        return accountCheckboxes()
      case Pages.ADMIN_GROUPS:
        return adminGroupsCheckboxes()
      default:
        return []
    }
  }

  return {
    getFormFields,
    getAddressFields,
    getCheckboxesFields,
    getImageFields,
  }
}

export default useFormFields
