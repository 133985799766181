import { useEffect } from "react";
import useLoading from "../../../hooks/useLoading";
import ContentLoader from "../ContentLoader";

import FeaturedImage from "../FeaturedImage";

interface Props {
  name: string;
  qrString?: string;
  label: string;
  value: any;
  setValue: any;
  register: any;
}

const ImageField: React.FC<Props> = ({
  name,
  label,
  value, // value: image in base64 format
  qrString, // qrString: QR code string
  setValue,
  register,
}) => {
  const { loading, changeLoading } = useLoading();

  async function fetchAttachment() {
    changeLoading(false);
  }

  useEffect(() => {
    register(name);
    fetchAttachment();
    // eslint-disable-next-line
  }, []);

  return loading ? (
    <ContentLoader />
  ) : (
    <FeaturedImage label={label} attachment={value} qrString={qrString} />
  );
};

export default ImageField;
