import { Auth } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  clearSession,
  openSnackBar,
  setSession,
  openModal,
  closeModal,
  openDialog,
  closeDialog,
  setSelectedUser,
  setDateRange,
  setPaginateState,
  setUserPermissions,
  setUserPermissionsLoaded,
  setUserConcepts,
} from "../store/ducks/app";

const useApp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const session = useSelector((state: any) => state.app.session);
  const modalOpen = useSelector((state: any) => state.app.modalOpen);
  const modalContent = useSelector((state: any) => state.app.modalContent);
  const modalContentId = useSelector((state: any) => state.app.modalContentId);
  const dialogOpen = useSelector((state: any) => state.app.dialogOpen);
  const snackBarOpen = useSelector((state: any) => state.app.snackBarOpen);
  const language = useSelector((state: any) => state.app.language);
  const dateRange = useSelector((state: any) => state.app.dateRange);
  const paginateState = useSelector((state: any) => state.app.paginateState);
  const deletePopupOpen = useSelector(
    (state: any) => state.app.deletePopupOpen
  );
  const selectedUser = useSelector((state: any) => state.app.selectedUser);
  const userPermissions = useSelector(
    (state: any) => state.app.userPermissions
  );

  const fetchSession = async () => {
    const session: any = await Auth.currentSession();

    return session.idToken.payload;
  };

  return {
    session,
    modalOpen,
    modalContent,
    modalContentId,
    dialogOpen,
    snackBarOpen,
    language,
    deletePopupOpen,
    selectedUser,
    dateRange,
    paginateState,
    userPermissions,
    setPaginateState: (paginateState: any) =>
      dispatch(setPaginateState(paginateState)),
    fetchSession,
    setPermissions: (permissions: any) =>
      dispatch(setUserPermissions(permissions)),
    setPermissionsLoaded: (permissionsLoaded: any) =>
      dispatch(setUserPermissionsLoaded(permissionsLoaded)),
    setSession: (session: any) => dispatch(setSession(session)),
    clearSession: () => {
      dispatch(clearSession());
    },
    showConfirm: (message: string) =>
      dispatch(openSnackBar(message, "success")),
    showError: (error: any) => {
      console.log("======= ERROR =======", error);
      // dispatch(openSnackBar(error.message || error, "error"));
      dispatch(openSnackBar(error, "error"));
    },
    showWarning: (message: any) => {
      dispatch(openSnackBar(message, "warning"));
    },
    showInfo: (message: any) => {
      dispatch(openSnackBar(message, "info"));
    },
    openModal: (modalContent: any, modalContentId?: any) =>
      dispatch(openModal(modalContent, modalContentId)),
    showDeletePopup: (
      id: string,
      slug: string,
      resourceId: string,
      resource?: any,
      editedLine?: any
    ) => dispatch(openModal({ slug, resource, resourceId, editedLine }, id)),
    hideDeletePopup: () => dispatch(closeModal()),
    modalClose: () => dispatch(closeModal()),
    navigateTo: (url: string) => navigate(url),
    navigateBack: () => navigate(-1),
    openDialog: () => dispatch(openDialog()),
    closeDialog: () => dispatch(closeDialog()),
    setSelectedUser: (user: any) => dispatch(setSelectedUser(user)),
    changeDateRange: (dateRange: any) => dispatch(setDateRange(dateRange)),
    setConcepts: (payload: any | null) =>
      dispatch(setUserConcepts(payload)),
  };
};

export default useApp;
