import { Suspense } from "react";
import Backdrop from "@mui/material/Backdrop";

import ContentLoader from "../ContentLoader";
import useApp from "../../../hooks/useApp";
import Box from "@mui/material/Box";
import CreateModal from "../CreateModal";
import DeleteResource from "../DeleteResource";
import DeleteTrxResource from "../DeleteTrxResource";
import DateRangePickerNew from "../DateRangePicker/DateRangePickerNew";

const MainModal: React.FC = () => {
  const { modalOpen, modalContent, hideDeletePopup, modalContentId } = useApp();

  const renderModalContent = () => {
    if (typeof modalContent === "object") {
      const { slug } = modalContent;

      if (modalContentId === "new") return <CreateModal slug={slug} />;
      if (modalContentId === "date")
        return (
          <DateRangePickerNew title={slug} selection={modalContent.resourceId} />
        );
      if (modalContent.resourceId && modalContentId === "delete")
        return (
          <DeleteResource slug={slug} resourceId={modalContent.resourceId} />
        );
      if (modalContent.resourceId && modalContentId === "deleteTrxLine")
        return (
          <DeleteTrxResource
            slug={slug}
            editedLine={modalContent.editedLine}
            resource={modalContent.resource}
            resourceId={modalContent.resourceId}
          />
        );
    }

    return modalContent;
  };

  return (
    <Backdrop
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        alignItems: "flex-start",
      }}
      open={modalOpen}
    >
      <Box
        sx={{
          position: "absolute",
          inset: 0,
          zIndex: 0,
          cursor: "pointer",
        }}
        onClick={hideDeletePopup}
      />
      <Suspense fallback={<ContentLoader />}>{renderModalContent()}</Suspense>
    </Backdrop>
  );
};

export default MainModal;
