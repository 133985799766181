import * as XLSX from "xlsx";
import { ZatcaInvoice } from "./../API";
import {
  getZatcaInvoice,
  zatcaInvoicesByDeletedSortedByDate,
} from "./../graphql/queries";
import {
  Concept,
  Customer,
  ZatcaInvoiceLine,
  ModelZatcaInvoiceFilterInput,
  UpdateZatcaInvoiceInput,
  ModelStringKeyConditionInput,
} from "./../models/GQL_API";
import {
  deleteInvoice,
  updateInvoice,
  updateZatcaInvoice,
} from "./../graphql/mutations";
import { API } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { useDispatch, useSelector } from "react-redux";
import {
  setListing,
  setListingZatcaInvoicesStats,
  setListingZatcaCreditNotesStats,
  setSearchText,
  setSelected,
  setNextToken,
  changeLimit,
  setPreviousToken,
  nextAction,
  setFilter,
} from "../store/ducks/zatcaInvoice";
import { HeadCell } from "../models/dataTable";
import useApp from "./useApp";
import { zatcaInvoicesByDeleted } from "../graphql/queries";
import {
  ListingByAccountVariables,
  TransactionListingVariables,
  TransactionStats,
} from "../models/app";
import {
  INVOICE_STATUS,
  INVOICE_TYPES,
  LambdaFunctions,
  TimelineActions,
  ZATCA_INVOICE_STATUS,
  ZATCA_INVOICE_TYPES,
} from "../constants/enums";
import {
  EG_OPERATIONS,
  EG_SEND_INVOICE,
  createZatcaEndpoint,
} from "../constants/endPoints";
import useTimeline from "./useTimeline";
import { getFormattedDate } from "../helpers/utils";
import useCustomer from "./useCustomer";
import useItems from "./useItem";
import useConcept from "./useConcept";
import { useEffect, useState } from "react";
import axios from "axios";
import { Auth } from "@aws-amplify/auth";
import { zatcaInvoicesByDeletedCustom } from "../graphqlCustom/queries";
import usePaginate from "./usePaginate";
import invokeLambda from "../Services/invokeLambda";

const useZatcaInvoice = (listingName: string, singleName: string) => {
  const [accessToken, setAccessToken] = useState("");
  const dispatch = useDispatch();
  const { session, showConfirm, showError } = useApp();
  const { conceptsGet } = useConcept("concepts", "concept");
  const { timelinesCreate } = useTimeline("timelines", "timeline");
  const { customersFetch } = useCustomer("customers", "customer");
  const { itemsFetch } = useItems("items", "item");
  const { startIndex, limit, moveForward, next, prev, reset } = usePaginate();

  const { conceptsSelected } = useConcept("concepts", "concept");
  const conceptsListing = useSelector((state: any) => state.concepts.listing);
  const selectedConcept = conceptsListing.find(
    (concept: any) => concept.id === conceptsSelected
  );

  const listing = useSelector(
    (state: any) => state[`${listingName}`]["listing"]
  );

  const searchText = useSelector(
    (state: any) => state[`${listingName}`]["searchText"]
  );
  let paginationFilter = useSelector(
    (state: any) => state.zatcaInvoices.filter
  );

  const selected = useSelector(
    (state: any) => state[`${listingName}`]["selected"]
  );

  const storedLimit = useSelector(
    (state: any) => state[`${listingName}`]["limit"]
  );

  const nextToken = useSelector(
    (state: any) => state[`${listingName}`]["nextToken"]
  );

  const previousTokens = useSelector(
    (state: any) => state[`${listingName}`]["previousTokens"]
  );

  // useEffect(() => {
  //   async function getToken() {
  //     try {
  //       const userSession = await Auth.currentSession()
  //       const accessTokenObj = userSession?.getAccessToken()
  //       const accessToken = accessTokenObj.getJwtToken()
  //       setAccessToken(accessToken)
  //     } catch (err:any) {
  //       console.log({ err })
  //     }
  //   }
  //   getToken()
  // }, [])
  // console.log({paginationFilter})

  async function fetch(props: TransactionListingVariables) {
    try {
      const {
        startIndex,
        limit,
        moveForward,
        conceptID,
        searchText,
        type,
        fromDate,
        toDate,
        conceptsSelectedFilters,
      } = props;

      console.log("Fetch.............");

      let requestLimit = storedLimit;
      let requestToken = nextToken;
      let requestPreviousTokens = previousTokens;

      console.log({
        startIndex,
        limit,
        moveForward,
        conceptID,
        searchText,
        type,
        fromDate,
        toDate,
        conceptsSelectedFilters,
      });
      if (
        paginationFilter &&
        paginationFilter.toString() !==
          [
            limit,
            conceptID,
            searchText,
            type,
            fromDate,
            toDate,
            conceptsSelectedFilters,
          ].toString()
      ) {
        console.log("reset");
        dispatch(setListing([]));
        dispatch(setNextToken(null));
        dispatch(setPreviousToken([]));
        requestLimit = limit;
        requestToken = null;
        requestPreviousTokens = [];
        dispatch(changeLimit(limit));
        dispatch(setFilter([]));
        await reset(limit);
      }

      // Clear pagination data
      if (limit !== storedLimit || startIndex === 0) {
        requestLimit = limit;
        requestToken = null;
        requestPreviousTokens = [];
        dispatch(setFilter([]));
        dispatch(changeLimit(limit));
      }

      // Get token from previous tokens
      if (!moveForward) {
        const updatedPreviousTokens = [...requestPreviousTokens];
        updatedPreviousTokens.pop();

        requestToken =
          updatedPreviousTokens.length >= 2
            ? updatedPreviousTokens[updatedPreviousTokens.length - 2]
            : null;
        dispatch(
          setNextToken(updatedPreviousTokens[updatedPreviousTokens.length - 1])
        );
        dispatch(setPreviousToken(updatedPreviousTokens));
      }

      const createdAtFilter: ModelStringKeyConditionInput = {};
      const filter: any = {};
      filter.and = [];
      filter.or = [];

      // if (type) {
      //   // filter.type = { eq: type };
      //   filter.type = { contains: type };
      // }

      if (type) {
        if (
          type === ZATCA_INVOICE_TYPES.STANDARD ||
          type === ZATCA_INVOICE_TYPES.SIMPLIFIED
        ) {
          // filter.type = { eq: type };
          // const or = [
          //   { type: { eq: ZATCA_INVOICE_TYPES.STANDARD } },
          //   { type: { eq: ZATCA_INVOICE_TYPES.SIMPLIFIED } },
          // ];
          filter.or.push({ type: { eq: ZATCA_INVOICE_TYPES.STANDARD } });
          filter.or.push({ type: { eq: ZATCA_INVOICE_TYPES.SIMPLIFIED } });
        } else {
          // filter.type = { eq: ZATCA_INVOICE_TYPES.STANDARD_CREDIT_NOTE };
          const or = [
            { type: { eq: ZATCA_INVOICE_TYPES.SIMPLIFIED_CREDIT_NOTE } },
            { type: { eq: ZATCA_INVOICE_TYPES.STANDARD_CREDIT_NOTE } },
          ];
          filter.or.push({
            type: { eq: ZATCA_INVOICE_TYPES.SIMPLIFIED_CREDIT_NOTE },
          });
          filter.or.push({
            type: { eq: ZATCA_INVOICE_TYPES.STANDARD_CREDIT_NOTE },
          });
        }
      }

      if (searchText) {
        // Search Filter
        // filter.or = [];
        filter.or.push({ billNumber: { contains: searchText } });
        filter.or.push({ folioNumber: { contains: searchText } });
      }

      // Concepts Filter
      if (conceptsSelectedFilters && conceptsSelectedFilters.length > 0) {
        // filter.or = [];
        for (let conceptsSelected of conceptsSelectedFilters) {
          filter.or.push({ conceptID: { eq: conceptsSelected.id } });
        }
      } else {
        if (conceptID) filter.conceptID = { eq: conceptID };
      }

      // Date Filter
      console.log({ fromDate, toDate });
      // if (fromDate && toDate) {
      //   // filter.and = [];
      //   const fromDateAt_12AM = new Date(fromDate);
      //   fromDateAt_12AM.setHours(0, 0, 0, 0);

      //   filter.and.push({
      //     // createdAt: { ge: "2024-03-08T22:29:08.000Z" },
      //     createdAt: { ge: new Date(fromDateAt_12AM).toISOString() },
      //   });
      //   const toDatePlusOneDay = new Date(toDate);
      //   toDatePlusOneDay.setDate(toDatePlusOneDay.getDate() + 1);
      //   console.log({ toDatePlusOneDay });
      //   filter.and.push({
      //     createdAt: {
      //       // lt: "2024-03-09T22:29:08.000Z"
      //       lt: new Date(toDatePlusOneDay).toISOString(),
      //     },
      //   });
      //   console.log({ createdAt: filter.and });
      // }

      if (fromDate && toDate) {
        const toDatePlusOneDay = new Date(toDate);
        const fromDateAt_12AM = new Date(fromDate);
        fromDateAt_12AM.setHours(0, 0, 0, 0);
        toDatePlusOneDay.setDate(toDatePlusOneDay.getDate() + 1);
        createdAtFilter.between = [
          new Date(fromDateAt_12AM).toISOString(),
          new Date(toDatePlusOneDay).toISOString(),
        ];
      }

      if (filter.and && filter.and.length === 0) {
        delete filter.and;
      }
      if (filter.or && filter.or.length === 0) {
        delete filter.or;
      }

      // const variables: any = {
      //   deleted: '0',
      //   filter,
      //   limit: requestLimit,
      //   // nextToken: requestToken,
      //   sortDirection: 'DESC',
      // }
      // if (requestToken) {
      //   variables.nextToken = requestToken
      // }

      // const invoicesList: any = await API.graphql<ZatcaInvoice>({
      //   query: zatcaInvoicesByDeleted,
      //   variables,
      //   authMode: true
      //     ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
      //     : GRAPHQL_AUTH_MODE.AWS_IAM,
      // })
      // let listing = invoicesList.data.zatcaInvoicesByDeleted.items;

      // Check if the condition meets for looping

      // let shouldFetchMore = true
      // let listing: any = [...invoicesList.data.zatcaInvoicesByDeleted.items]

      // while (shouldFetchMore) {
      //   const invoicesList: any = await API.graphql<ZatcaInvoice>({
      //     query: zatcaInvoicesByDeleted,
      //     variables,
      //     authMode: true
      //       ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
      //       : GRAPHQL_AUTH_MODE.AWS_IAM,
      //   });

      //   const currentListing = invoicesList.data.zatcaInvoicesByDeleted.items;
      //   if (
      //     currentListing.length < limit &&
      //     invoicesList.data.zatcaInvoicesByDeleted.nextToken
      //   ) {
      //     variables.nextToken =
      //       invoicesList.data.zatcaInvoicesByDeleted.nextToken;
      //     listing.push(...currentListing);
      //   } else {
      //     shouldFetchMore = false;
      //     listing.push(...currentListing);
      //   }
      // }

      // let newNextToken = nextToken
      // while (shouldFetchMore) {
      //   const invoicesList: any = await API.graphql<ZatcaInvoice>({
      //     query: zatcaInvoicesByDeleted,
      //     variables,
      //     authMode: true
      //       ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
      //       : GRAPHQL_AUTH_MODE.AWS_IAM,
      //   })

      //   const currentListing = invoicesList.data.zatcaInvoicesByDeleted.items
      //   newNextToken = invoicesList.data.zatcaInvoicesByDeleted.nextToken

      //   // Check if the total number of items fetched plus the current batch exceeds the limit
      //   if (
      //     listing.length + currentListing.length < limit &&
      //     invoicesList.data.zatcaInvoicesByDeleted.nextToken
      //   ) {
      //     variables.nextToken =
      //       invoicesList.data.zatcaInvoicesByDeleted.nextToken
      //     listing.push(...currentListing)
      //   } else {
      //     // If fetching more would exceed the limit or no next token available, stop fetching
      //     shouldFetchMore = false
      //     // Add only the items that will keep the total within the limit
      //     // const remainingItems = currentListing.slice(
      //     //   0,
      //     //   limit - listing.length
      //     // );
      //     // listing.push(...remainingItems);
      //     listing.push(...currentListing)
      //   }
      // }

      let allItems = [];

      let shouldFetchMore = true;

      while (shouldFetchMore && allItems.length < requestLimit) {
        const variables: any = {
          deleted: "0",
          filter,
          limit: requestLimit,
          // nextToken: requestToken,
          sortDirection: "DESC",
          createdAt: createdAtFilter,
        };
        if (requestToken) variables.nextToken = requestToken;
        const invoiceList: any = await API.graphql<ZatcaInvoice>({
          query: zatcaInvoicesByDeletedSortedByDate,
          variables,
          authMode: true
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });

        const currentItems =
          invoiceList.data.zatcaInvoicesByDeletedSortedByDate.items;
        allItems.push(...currentItems);

        // Update nextToken and check if should continue fetching
        requestToken =
          invoiceList.data.zatcaInvoicesByDeletedSortedByDate.nextToken;
        shouldFetchMore = requestToken ? true : false;
      }

      if (moveForward) {
        const currentNextToken = requestToken;
        dispatch(nextAction(currentNextToken, requestPreviousTokens));
      }
      // console.log(listing);

      console.log({ allItems });
      dispatch(
        setFilter([
          limit,
          conceptID,
          searchText,
          type,
          fromDate,
          toDate,
          conceptsSelectedFilters,
        ])
      );
      return allItems;
    } catch (err: any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  }

  /*
   * Fetch all invoice within selected range
   * Filter then using invoice status (Valid, Invalid, Rejected, Cancelled)
   * Return Hash map contains (Status, # of invoices, total, percentage)
   */
  // async function fetchStats(props: TransactionListingVariables) {
  //   try {
  //     const {
  //       limit,
  //       conceptID,
  //       fromDate,
  //       toDate,
  //       conceptsSelectedFilters,
  //       type,
  //     } = props

  //       const filter: ModelZatcaInvoiceFilterInput = {}

  //     if (type) {
  //       filter.type = { eq: type }
  //     }

  //     if (conceptsSelectedFilters && conceptsSelectedFilters.length > 0) {
  //       // Concepts Filter
  //       filter.or = []
  //       for (let conceptsSelected of conceptsSelectedFilters) {
  //         filter.or.push({ conceptID: { eq: conceptsSelected.id } })
  //       }
  //     } else {
  //       if (conceptID) filter.conceptID = { eq: conceptID }
  //     }

  //     // Date Filter
  //     if (fromDate && toDate) {
  //       filter.and = [];

  //       filter.and.push({
  //         createdAt: { ge: new Date(fromDate).toISOString() },
  //       });
  //       filter.and.push({
  //         createdAt: {
  //           lt: new Date(toDate).toISOString(),
  //         },
  //       });
  //       }

  //       const variables: any = {
  //       deleted: '0',
  //       filter,
  //       limit: requestLimit,
  //       nextToken: requestToken,
  //     }

  //     const invoiceList: any = await API.graphql<ZatcaInvoice>({
  //       query: zatcaInvoicesByDeleted,
  //       variables,
  //       authMode: true
  //         ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
  //         : GRAPHQL_AUTH_MODE.AWS_IAM,
  //     })

  //     const listing = invoiceList.data.zatcaInvoicesByDeleted.items
  //     // Calculate stats
  //     let DocumentType = type
  //     if (DocumentType === undefined) DocumentType = INVOICE_TYPES.INVOICE

  //     const stats = await InvoicesStats(listing, DocumentType)

  //     return stats
  //   } catch (err:any) {
  //     showError(
  //       typeof err.message === 'string' ? err.message : 'Error occurred',
  //     )
  //     console.log(err)
  //   }
  // }
  async function fetchStats(props: TransactionListingVariables) {
    try {
      const {
        limit,
        conceptID,
        fromDate,
        toDate,
        conceptsSelectedFilters,
        type,
      } = props;
      console.log({ limit });
      const filter: any = {};
      const createdAtFilter: ModelStringKeyConditionInput = {};

      // if (type) {
      //   filter.type = { eq: type };
      // }

      // if (type) {
      //   // filter.type = { eq: type };
      //   filter.type = { contains: type };
      // }
      // if (type) {
      //   if (
      //     type === ZATCA_INVOICE_TYPES.STANDARD ||
      //     type === ZATCA_INVOICE_TYPES.SIMPLIFIED
      //   ) {
      //     // filter.type = { eq: type };
      //     filter.or = [
      //       { type: { eq: ZATCA_INVOICE_TYPES.STANDARD } },
      //       { type: { eq: ZATCA_INVOICE_TYPES.SIMPLIFIED } },
      //     ]
      //   } else {
      //     // filter.type = { eq: ZATCA_INVOICE_TYPES.STANDARD_CREDIT_NOTE };
      //     filter.or = [
      //       { type: { eq: ZATCA_INVOICE_TYPES.SIMPLIFIED_CREDIT_NOTE } },
      //       { type: { eq: ZATCA_INVOICE_TYPES.STANDARD_CREDIT_NOTE } },
      //     ]
      //   }
      // }

      if (conceptsSelectedFilters && conceptsSelectedFilters.length > 0) {
        // Concepts Filter
        filter.or = [];
        for (let conceptsSelected of conceptsSelectedFilters) {
          filter.or.push({ conceptID: { eq: conceptsSelected.id } });
        }
      } else {
        if (conceptID) filter.conceptID = { eq: conceptID };
      }

      // Date Filter
      // if (fromDate && toDate) {
      //   filter.and = [];

      //   filter.and.push({
      //     createdAt: { ge: new Date(fromDate).toISOString() },
      //   });
      //   filter.and.push({
      //     createdAt: {
      //       lt: new Date(toDate).toISOString(),
      //     },
      //   });
      // }

      if (fromDate && toDate) {
        const toDatePlusOneDay = new Date(toDate);
        toDatePlusOneDay.setDate(toDatePlusOneDay.getDate() + 1);
        createdAtFilter.between = [
          new Date(fromDate).toISOString(),
          new Date(toDatePlusOneDay).toISOString(),
        ];
      }

      // const invoiceList: any = await API.graphql<ZatcaInvoice>({
      //   query: zatcaInvoicesByDeleted,
      //   variables: { filter, limit, deleted: "0" },
      //   authMode: true
      //     ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
      //     : GRAPHQL_AUTH_MODE.AWS_IAM,
      // });

      // const listing = invoiceList.data.zatcaInvoicesByDeleted.items;

      let allItems = [];

      let shouldFetchMore = true;
      let nextToken = undefined;

      while (shouldFetchMore) {
        const variables: any = {
          filter,
          limit: 100000,
          deleted: "0",
          sortDirection: "DESC",
          createdAt: createdAtFilter,
        };
        if (nextToken === null) break;
        if (nextToken) variables.nextToken = nextToken;
        const invoiceList: any = await API.graphql<ZatcaInvoice>({
          query: zatcaInvoicesByDeletedCustom,
          variables,
          authMode: true
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
        console.log({ invoiceList });

        const currentItems =
          invoiceList.data.zatcaInvoicesByDeletedSortedByDate.items;
        allItems.push(...currentItems);

        // Update nextToken and check if should continue fetching
        nextToken =
          invoiceList.data.zatcaInvoicesByDeletedSortedByDate.nextToken;
        shouldFetchMore = nextToken ? true : false;
      }

      //   let shouldFetchMore = true;
      //   let listing = [];

      //   while (shouldFetchMore) {
      //     const invoiceList: any = await API.graphql<ZatcaInvoice>({
      //       query: zatcaInvoicesByDeleted,
      //       variables: { filter, limit, deleted: "0", nextToken: null },
      //       authMode: true
      //         ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
      //         : GRAPHQL_AUTH_MODE.AWS_IAM,
      //     });

      //     const currentListing = invoiceList.data.zatcaInvoicesByDeleted.items;

      //   if (invoiceList.data.zatcaInvoicesByDeleted.nextToken) {
      //     // If there's a nextToken, continue fetching
      //     filter.nextToken = invoiceList.data.zatcaInvoicesByDeleted.nextToken;
      //     listing.push(...currentListing);
      //   } else {
      //     // Stop fetching if there's no nextToken
      //     shouldFetchMore = false;
      //     listing.push(...currentListing); // Add the current items to the listing
      //   }
      // }

      // Calculate stats
      let DocumentType = type;
      if (DocumentType === undefined)
        DocumentType = ZATCA_INVOICE_TYPES.STANDARD;

      const stats = await InvoicesStats(allItems);
      console.log({ stats });
      return stats;
    } catch (err: any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  }

  async function InvoicesStats(listing: any[]) {
    try {
      let validCounterStandard: TransactionStats = {
        id: 1,
        status: INVOICE_STATUS.VALID,
        counter: 0,
        total: 0,
        percentage: 0,
        fill: "#84c529",
      };

      let invalidCounterStandard: TransactionStats = {
        id: 2,
        status: INVOICE_STATUS.INVALID,
        counter: 0,
        total: 0,
        percentage: 0,
        fill: "#fc2d42",
      };

      let totalCounterStandard: TransactionStats = {
        id: 3,
        status: "total",
        counter: 0,
        total: 0,
        percentage: 100,
        fill: "#172329",
      };

      let validCounterCredit: TransactionStats = { ...validCounterStandard };
      let invalidCounterCredit: TransactionStats = {
        ...invalidCounterStandard,
      };
      let totalCounterCredit: TransactionStats = { ...totalCounterStandard };

      listing.forEach(({ status, taxInclusiveAmount, type }) => {
        if (
          type === ZATCA_INVOICE_TYPES.SIMPLIFIED ||
          type === ZATCA_INVOICE_TYPES.STANDARD
        ) {
          // Process standard invoices
          switch (status) {
            case ZATCA_INVOICE_STATUS.SUCCESS:
              validCounterStandard.counter++;
              validCounterStandard.total += taxInclusiveAmount;
              break;
            case ZATCA_INVOICE_STATUS.FAIL:
              invalidCounterStandard.counter++;
              invalidCounterStandard.total += taxInclusiveAmount;
              break;
            default:
              break;
          }
        } else if (
          type === ZATCA_INVOICE_TYPES.SIMPLIFIED_CREDIT_NOTE ||
          type === ZATCA_INVOICE_TYPES.STANDARD_CREDIT_NOTE
        ) {
          // Process credit note invoices
          switch (status) {
            case ZATCA_INVOICE_STATUS.SUCCESS:
              validCounterCredit.counter++;
              validCounterCredit.total += taxInclusiveAmount;
              break;
            case ZATCA_INVOICE_STATUS.FAIL:
              invalidCounterCredit.counter++;
              invalidCounterCredit.total += taxInclusiveAmount;
              break;
            default:
              break;
          }
        }
      });

      // Calculate totals and percentages for standard invoices
      const totalInvoicesStandard =
        validCounterStandard.counter + invalidCounterStandard.counter;
      const totalAmountStandard =
        validCounterStandard.total + invalidCounterStandard.total;
      totalCounterStandard.counter = totalInvoicesStandard;
      totalCounterStandard.total = totalAmountStandard;

      if (totalInvoicesStandard > 0) {
        validCounterStandard.percentage =
          (validCounterStandard.counter / totalInvoicesStandard) * 100;
        invalidCounterStandard.percentage =
          (invalidCounterStandard.counter / totalInvoicesStandard) * 100;
      }

      // Calculate totals and percentages for credit invoices
      const totalInvoicesCredit =
        validCounterCredit.counter + invalidCounterCredit.counter;
      const totalAmountCredit =
        validCounterCredit.total + invalidCounterCredit.total;
      totalCounterCredit.counter = totalInvoicesCredit;
      totalCounterCredit.total = totalAmountCredit;

      if (totalInvoicesCredit > 0) {
        validCounterCredit.percentage =
          (validCounterCredit.counter / totalInvoicesCredit) * 100;
        invalidCounterCredit.percentage =
          (invalidCounterCredit.counter / totalInvoicesCredit) * 100;
      }

      const standardInvoicesStats: TransactionStats[] = [
        validCounterStandard,
        invalidCounterStandard,
        totalCounterStandard,
      ];
      const creditInvoicesStats: TransactionStats[] = [
        validCounterCredit,
        invalidCounterCredit,
        totalCounterCredit,
      ];

      // Dispatch the results for each type
      dispatch(setListingZatcaInvoicesStats(standardInvoicesStats));
      dispatch(setListingZatcaCreditNotesStats(creditInvoicesStats));

      return { standardInvoicesStats, creditInvoicesStats };
    } catch (err: any) {
      throw err;
    }
  }

  // async function InvoicesStats(listing: any[], type: string) {
  //   try {
  //     let validCounter: TransactionStats = {
  //       id: 1,
  //       status: INVOICE_STATUS.VALID,
  //       counter: 0,
  //       total: 0,
  //       percentage: 0,
  //       fill: "#84c529",
  //     };

  //     let invalidCounter: TransactionStats = {
  //       id: 2,
  //       status: INVOICE_STATUS.INVALID,
  //       counter: 0,
  //       total: 0,
  //       percentage: 0,
  //       fill: "#fc2d42",
  //     };

  //     let totalCounter: TransactionStats = {
  //       id: 3,
  //       status: "total",
  //       counter: 0,
  //       total: 0,
  //       percentage: 100,
  //       fill: "#172329",
  //     };

  //     let totalInvoices = 0;
  //     let totalAmount = 0;

  //     listing.forEach(({ status, taxInclusiveAmount }) => {
  //       switch (status) {
  //         case ZATCA_INVOICE_STATUS.SUCCESS:
  //           validCounter.counter++;
  //           validCounter.total += taxInclusiveAmount;
  //           break;
  //         case ZATCA_INVOICE_STATUS.FAIL:
  //           invalidCounter.counter++;
  //           invalidCounter.total += taxInclusiveAmount;
  //           break;
  //         default:
  //           break;
  //       }
  //     });

  //     totalInvoices = validCounter.counter + invalidCounter.counter;
  //     totalAmount = validCounter.total + invalidCounter.total;
  //     totalCounter.counter = totalInvoices;
  //     totalCounter.total = totalAmount;

  //     if (totalInvoices > 0) {
  //       validCounter.percentage = (validCounter.counter / totalInvoices) * 100;
  //       invalidCounter.percentage =
  //         (invalidCounter.counter / totalInvoices) * 100;
  //     }

  //     const InvoicesStats: TransactionStats[] = [
  //       validCounter,
  //       invalidCounter,
  //       totalCounter,
  //     ];

  //     if (
  //       type === ZATCA_INVOICE_TYPES.SIMPLIFIED ||
  //       type === ZATCA_INVOICE_TYPES.STANDARD
  //     )
  //       dispatch(setListingZatcaInvoicesStats(InvoicesStats));
  //     else if (
  //       type === ZATCA_INVOICE_TYPES.SIMPLIFIED_CREDIT_NOTE ||
  //       type === ZATCA_INVOICE_TYPES.STANDARD_CREDIT_NOTE
  //     )
  //       dispatch(setListingZatcaCreditNotesStats(InvoicesStats));
  //     return InvoicesStats;
  //   } catch (err: any) {
  //     throw err;
  //   }
  // }

  // async function InvoicesStats(listing: any[], type: string) {
  //   try {
  //     // let submitCounter: TransactionStats = {
  //     //   id: 0,
  //     //   status: INVOICE_STATUS.SUBMITTED,
  //     //   counter: 0,
  //     //   total: 0,
  //     //   percentage: 0,
  //     //   fill: "#B835E0",
  //     // };

  //     let validCounter: TransactionStats = {
  //       id: 1,
  //       status: INVOICE_STATUS.VALID,
  //       counter: 0,
  //       total: 0,
  //       percentage: 0,
  //       fill: '#84c529',
  //     }

  //     // let rejectedCounter: TransactionStats = {
  //     //   id: 2,
  //     //   status: INVOICE_STATUS.REJECTED,
  //     //   counter: 0,
  //     //   total: 0,
  //     //   percentage: 0,
  //     //   fill: "#03a9f4",
  //     // };

  //     // let cancelledCounter: TransactionStats = {
  //     //   id: 3,
  //     //   status: INVOICE_STATUS.CANCELLED,
  //     //   counter: 0,
  //     //   total: 0,
  //     //   percentage: 0,
  //     //   fill: "#ff6f00",
  //     // };

  //     let invalidCounter: TransactionStats = {
  //       id: 2,
  //       status: INVOICE_STATUS.INVALID,
  //       counter: 0,
  //       total: 0,
  //       percentage: 0,
  //       fill: '#fc2d42',
  //     }

  //     let totalCounter: TransactionStats = {
  //       id: 3,
  //       status: 'total',
  //       counter: 0,
  //       total: 0,
  //       percentage: 100,
  //       fill: '#172329',
  //     }

  //     let totalInvoices = 0
  //     let totalAmount = 0

  //     listing.forEach(({ status, taxInclusiveAmount }) => {
  //       // submitCounter.counter++;
  //       // submitCounter.total += taxInclusiveAmount;
  //       switch (status) {
  //         case ZATCA_INVOICE_STATUS.SUCCESS:
  //           validCounter.counter++
  //           validCounter.total += taxInclusiveAmount
  //           break
  //         case ZATCA_INVOICE_STATUS.FAIL:
  //           invalidCounter.counter++
  //           invalidCounter.total += taxInclusiveAmount
  //           break
  //         // case INVOICE_STATUS.REJECTED:
  //         //   rejectedCounter.counter++;
  //         //   rejectedCounter.total += taxInclusiveAmount;
  //         //   invalidCounter.counter++;
  //         //   invalidCounter.total += taxInclusiveAmount;
  //         //   break;
  //         default:
  //           break
  //       }
  //     })

  //     // totalInvoices =
  //     //   submitCounter.counter +
  //     //   validCounter.counter +
  //     //   invalidCounter.counter +
  //     //   rejectedCounter.counter +
  //     //   cancelledCounter.counter;

  //     totalInvoices = validCounter.counter + invalidCounter.counter

  //     totalAmount = validCounter.total + invalidCounter.total

  //     totalCounter.counter = totalInvoices
  //     totalCounter.total = totalAmount

  //     // if (totalInvoices > 0) {
  //     //   submitCounter.percentage =
  //     //     (submitCounter.counter / totalInvoices) * 100;
  //     //   validCounter.percentage = (validCounter.counter / totalInvoices) * 100;
  //     //   invalidCounter.percentage =
  //     //     (invalidCounter.counter / totalInvoices) * 100;
  //     //   rejectedCounter.percentage =
  //     //     (rejectedCounter.counter / totalInvoices) * 100;
  //     //   cancelledCounter.percentage =
  //     //     (cancelledCounter.counter / totalInvoices) * 100;
  //     // }
  //     if (totalInvoices > 0) {
  //       validCounter.percentage = (validCounter.counter / totalInvoices) * 100
  //       invalidCounter.percentage =
  //         (invalidCounter.counter / totalInvoices) * 100
  //     }

  //     // const InvoicesStats: TransactionStats[] = [
  //     //   submitCounter,
  //     //   validCounter,
  //     //   rejectedCounter,
  //     //   cancelledCounter,
  //     //   invalidCounter,
  //     //   totalCounter,
  //     // ];
  //     const InvoicesStats: TransactionStats[] = [
  //       validCounter,
  //       invalidCounter,
  //       totalCounter,
  //     ]

  //     if (
  //       type === ZATCA_INVOICE_TYPES.SIMPLIFIED ||
  //       type === ZATCA_INVOICE_TYPES.STANDARD
  //     )
  //       dispatch(setListingZatcaInvoicesStats(InvoicesStats))
  //     else if (
  //       type === ZATCA_INVOICE_TYPES.SIMPLIFIED_CREDIT_NOTE ||
  //       type === ZATCA_INVOICE_TYPES.STANDARD_CREDIT_NOTE
  //     )
  //       dispatch(setListingZatcaCreditNotesStats(InvoicesStats))
  //     return InvoicesStats
  //   } catch (err:any) {
  //     throw err
  //   }
  // }

  async function get(id: string) {
    try {
      let single: ZatcaInvoice | undefined;
      if (listing.length !== 0) {
        single = listing.find((resource: any) => resource.id === id);
      }

      if (single === undefined) {
        const listing: any = await API.graphql<ZatcaInvoice>({
          query: getZatcaInvoice,
          variables: { id },
          authMode: true
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
        single = listing.data.getZatcaInvoice;
      }

      return single;
    } catch (err: any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  }

  // async function update(
  //   id: string,
  //   data: any,
  //   editedLine: any,
  //   action: string,
  // ) {
  //   try {
  //     const original = await get(id)
  //     if (original) {
  //       let updateInput: UpdateZatcaInvoiceInput = {
  //         id: original.id,

  //         dateTimeIssued: data.dateTimeIssued
  //           ? data.dateTimeIssued
  //           : original!.dateTimeIssued,
  //         totalDiscountAmount: data.totalDiscountAmount
  //           ? data.totalDiscountAmount
  //           : original!.totalDiscountAmount,
  //         totalSalesAmount: data.totalSalesAmount
  //           ? data.totalSalesAmount
  //           : original!.totalSalesAmount,
  //         netAmount: data.netAmount ? data.netAmount : original!.netAmount,
  //         totalAmount: data.totalAmount
  //           ? data.totalAmount
  //           : original!.totalAmount,
  //         extraDiscountAmount: data.extraDiscountAmount
  //           ? data.extraDiscountAmount
  //           : original!.extraDiscountAmount,
  //         totalItemsDiscountAmount: data.totalItemsDiscountAmount
  //           ? data.totalItemsDiscountAmount
  //           : original!.totalItemsDiscountAmount,
  //         invoiceLines: data.invoiceLines,
  //       }

  //       if (editedLine) {
  //         let timelineParams: any = {}

  //         // Edit (description, code, type, unit) only
  //         timelineParams = {
  //           actionName: TimelineActions.UPDATE_TRX_LINE,
  //           oldStatus: '',
  //           newStatus: '',
  //           transactionId: original.id,
  //         }
  //         await timelinesCreate(timelineParams)
  //       }

  //       await API.graphql<Invoice>({
  //         query: updateInvoice,
  //         variables: { input: updateInput },
  //         authMode: true
  //           ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
  //           : GRAPHQL_AUTH_MODE.AWS_IAM,
  //       })
  //     }

  //     showConfirm(`${singleName} has been updated successfully`)
  //   } catch (err) {
  //     showError(err)
  //   }
  // }

  async function update({
    BusinessDate,
    BusinessDateTime,
    checkNumber,
    grossAmount,
    netAmount,
    totalDiscount,
    serviceCharges,
    totalTax,
    creditBillReason,
    ArNumber,
    items,
  }: any) {
    try {
      const body = {
        BusinessDate,
        BusinessDateTime,
        checkNumber,
        grossAmount,
        netAmount,
        totalDiscount,
        serviceCharges,
        totalTax,
        // creditBillReason,
        ...(ArNumber && { ArNumber }),
        items,
      };
      if (!items || items.length < 1)
        throw new Error("You must add at least one item");
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
      // console.log(body);
      const response = await axios.post(
        createZatcaEndpoint({
          branchId: conceptsSelected,
          operation: "invoice",
          env: selectedConcept.env,
          type: "simphony",
        }),
        body,
        { headers }
      );
      showConfirm(`${singleName} has been updated successfully`);
      return response.data;
    } catch (error: any) {
      console.error("Error updating Credit invoice :", error);
      throw error;
    }
  }
  async function create({
    BusinessDate,
    BusinessDateTime,
    checkNumber,
    grossAmount,
    netAmount,
    totalDiscount,
    serviceCharges,
    totalTax,
    creditBillReason,
    ArNumber,
    items,
  }: any) {
    try {
      const queryStringParameters = {
        branchId: conceptsSelected,
        operation: "invoice",
        env: selectedConcept.env,
        type: "simphony",
      };
      const body = {
        BusinessDate,
        BusinessDateTime,
        checkNumber,
        grossAmount,
        netAmount,
        totalDiscount,
        serviceCharges,
        totalTax,
        // ...(ArNumber && { ArNumber }),
        items,
        billNumber: checkNumber,
        queryStringParameters,
      };
      // console.log(body);
      if (!items || items.length < 1) {
        throw new Error("You must add at least one item");
      }
      const responseBody = await invokeLambda(
        LambdaFunctions.SEND_INVOICE_ZATCA,
        body
      );
      showConfirm(`${singleName} has been created successfully`);
      return responseBody;
    } catch (error: any) {
      console.error("Error updating Credit invoice :", error);
      throw error;
    }
  }
  // async function create({
  //   BusinessDate,
  //   BusinessDateTime,
  //   checkNumber,
  //   grossAmount,
  //   netAmount,
  //   totalDiscount,
  //   serviceCharges,
  //   totalTax,
  //   creditBillReason,
  //   ArNumber,
  //   items,
  // }: any) {
  //   try {
  //     const body = {
  //       BusinessDate,
  //       BusinessDateTime,
  //       checkNumber,
  //       grossAmount,
  //       netAmount,
  //       totalDiscount,
  //       serviceCharges,
  //       totalTax,
  //       // ...(ArNumber && { ArNumber }),
  //       items,
  //       billNumber: checkNumber
  //     }
  //     // console.log(body);
  //     console.log(body)
  //     if (!items || items.length < 1) {
  //       throw new Error('You must add at least one item')
  //     }
  //     const headers = {
  //       Authorization: `Bearer ${accessToken}`,
  //       'Content-Type': 'application/json',
  //     }
  //     const response = await axios.post(
  //       createZatcaEndpoint({
  //         branchId: conceptsSelected,
  //         operation: 'invoice',
  //         env: selectedConcept.env,
  //         type: 'simphony',
  //       }),
  //       body,
  //       { headers },
  //     )
  //     console.log({ response })
  //     showConfirm(`${singleName} has been created successfully`)
  //     return response.data
  //   } catch (error:any) {
  //     console.error('Error updating Credit invoice :', error)
  //     throw error
  //   }
  // }

  async function trash(id: string) {
    try {
      const original = await get(id);

      if (original) {
        const updateInput: UpdateZatcaInvoiceInput = {
          id: original.id,
          deleted: "1",
        };

        await API.graphql<ZatcaInvoice>({
          query: updateInvoice,
          variables: { input: updateInput },
          authMode: true
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
      }

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err: any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  }

  async function bulkTrash(resourceIds: any) {
    for (let id of resourceIds) {
      try {
        await trash(id);
      } catch (err: any) {
        showError(err);
      }
    }

    dispatch(
      setListing(
        listing.filter((resource: any) => !resourceIds.has(resource.id))
      )
    );

    showConfirm(
      `${resourceIds.size} ${listingName} items has been moved to trash`
    );
  }

  async function remove(id: any) {
    try {
      await API.graphql<ZatcaInvoice>({
        query: deleteInvoice,
        variables: { id: id },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      dispatch(
        setListing(listing.filter((resource: any) => resource.id !== id))
      );

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  }

  /*
   * Cancel document in Egyptian Gateway
   */
  // async function cancelInvoice(document: Invoice, cancelReason: string) {
  //   try {
  //     if (
  //       document.status !== INVOICE_STATUS.SUBMITTED &&
  //       document.status !== INVOICE_STATUS.VALID
  //     ) {
  //       showError(`Can't cancel ${document.status} document`)
  //       return
  //     }

  //     const branchId = document.conceptID
  //     const operation = EG_OPERATIONS.CANCEL_INVOICE
  //     const requestPayload = {
  //       createdByID: session ? session.sub : '',
  //       createdByName: session ? session.name : '',

  //       document: document,
  //       cancelReason: cancelReason ? cancelReason : 'Invalid document.',
  //     }

  //     const requestOptions = {
  //       method: 'POST',
  //       headers: { 'Content-Type': 'application/json' },
  //       body: JSON.stringify(requestPayload),
  //     }

  //     await window
  //       .fetch(
  //         `${EG_SEND_INVOICE}?operation=${operation}&branchId=${branchId}`,
  //         requestOptions,
  //       )
  //       .then((response) => response.text())
  //       .then((text) => {
  //         let responseBody = JSON.parse(text)
  //         if (responseBody.status === 200) {
  //           showConfirm(responseBody.message)
  //         } else {
  //           showError(responseBody.message)
  //         }
  //       })
  //       .catch((err) => {
  //         showError(
  //           typeof err.message === 'string'
  //             ? err.message
  //             : 'error occurred while sending SMS',
  //         )
  //       })
  //   } catch (err) {
  //     console.log(err)
  //   }
  // }

  // cancel ksa invoice

  async function cancelInvoice({
    BusinessDate,
    BusinessDateTime,
    checkNumber,
    grossAmount,
    netAmount,
    totalDiscount,
    serviceCharges,
    totalTax,
    creditBillReason,
    ArNumber,
    items,
    billNumber,
  }: any) {
    try {
      const queryStringParameters = {
        branchId: conceptsSelected,
        operation: "creditNote",
        env: selectedConcept.env,
        type: "simphony",
      };
      const body = {
        BusinessDate,
        BusinessDateTime,
        checkNumber,
        grossAmount,
        netAmount,
        totalDiscount,
        serviceCharges,
        totalTax,
        creditBillReason,
        ...(ArNumber && { ArNumber }),
        items,
        billNumber,
        queryStringParameters,
      };
      const responseBody = await invokeLambda(
        LambdaFunctions.SEND_INVOICE_ZATCA,
        body
      );
      showConfirm(`${singleName} has been Canceled successfully`);
      return responseBody;
    } catch (error: any) {
      console.error("Error canceling Credit invoice :", error);
      throw error;
    }
  }
  // async function cancelInvoice({
  //   BusinessDate,
  //   BusinessDateTime,
  //   checkNumber,
  //   grossAmount,
  //   netAmount,
  //   totalDiscount,
  //   serviceCharges,
  //   totalTax,
  //   creditBillReason,
  //   ArNumber,
  //   items,
  //   billNumber
  // }: any) {
  //   try {
  //     const body = {
  //       BusinessDate,
  //       BusinessDateTime,
  //       checkNumber,
  //       grossAmount,
  //       netAmount,
  //       totalDiscount,
  //       serviceCharges,
  //       totalTax,
  //       creditBillReason,
  //       ...(ArNumber && { ArNumber }),
  //       items,
  //       billNumber
  //     }
  //     const headers = {
  //       Authorization: `Bearer ${accessToken}`,
  //       'Content-Type': 'application/json',
  //       // 'Access-Control-Allow-Origin': '*'
  //     }
  //     // console.log(body);
  //     const response = await axios.post(
  //       createZatcaEndpoint({
  //         branchId: conceptsSelected,
  //         operation: 'creditNote',
  //         env: selectedConcept.env,
  //         type: 'simphony',
  //       }),
  //       body,
  //       { headers },
  //     )
  //     showConfirm(`${singleName} has been Canceled successfully`)
  //     return response.data
  //   } catch (error:any) {
  //     console.error('Error canceling Credit invoice :', error)
  //     throw error
  //   }
  // }

  // async function resubmitInvoice(document: Invoice) {
  //   try {
  //     // Check document status
  //     if (
  //       document.status === INVOICE_STATUS.SUBMITTED ||
  //       document.status === INVOICE_STATUS.VALID
  //     ) {
  //       showError(`Can resubmit ${document.status} document.`)
  //       return
  //     }

  //     const branchId = document.conceptID
  //     const operation = EG_OPERATIONS.RESUBMIT_INVOICE
  //     const requestPayload = {
  //       createdByID: session ? session.sub : '',
  //       createdByName: session ? session.name : '',

  //       document: document,
  //     }

  //     const requestOptions = {
  //       method: 'POST',
  //       headers: { 'Content-Type': 'application/json' },
  //       body: JSON.stringify(requestPayload),
  //     }

  //     await window
  //       .fetch(
  //         `${EG_SEND_INVOICE}?operation=${operation}&branchId=${branchId}`,
  //         requestOptions,
  //       )
  //       .then((response) => response.text())
  //       .then((text) => {
  //         let responseBody = JSON.parse(text)
  //         console.log({ responseBody })

  //         let StatusMessages = responseBody.StatusMessages.Messages
  //         if (StatusMessages.length > 0) {
  //           let errorMessage = ''
  //           StatusMessages.forEach((message: any) => {
  //             errorMessage += message.Description
  //           })

  //           showError(errorMessage)
  //         }
  //       })
  //       .catch((err) => {
  //         showError(
  //           typeof err.message === 'string'
  //             ? err.message
  //             : 'error occurred while sending SMS',
  //         )
  //       })
  //   } catch (err) {
  //     showError(
  //       typeof err.message === 'string' ? err.message : 'Error occurred',
  //     )
  //     console.log(err)
  //   }
  // }

  async function resubmitInvoice({
    id,
    BusinessDate,
    BusinessDateTime,
    checkNumber,
    grossAmount,
    netAmount,
    totalDiscount,
    serviceCharges,
    totalTax,
    creditBillReason,
    ArNumber,
    items,
    billNumber,
  }: any) {
    try {
      const queryStringParameters = {
        branchId: conceptsSelected,
        operation: "resubmitInvoice",
        env: selectedConcept.env,
        type: "simphony",
      };
      const body = {
        id,
        BusinessDate,
        BusinessDateTime,
        checkNumber,
        grossAmount,
        netAmount,
        totalDiscount,
        serviceCharges,
        totalTax,
        // creditBillReason,
        ...(ArNumber && { ArNumber }),
        items,
        billNumber,
        queryStringParameters,
      };
      const responseBody = await invokeLambda(
        LambdaFunctions.SEND_INVOICE_ZATCA,
        body
      );
      showConfirm(`${singleName} has been sent successfully`);
      return responseBody;
    } catch (error: any) {
      console.error("Error sening Credit invoice :", error);
      throw error;
    }
  }
  // async function resubmitInvoice({
  //   id,
  //   BusinessDate,
  //   BusinessDateTime,
  //   checkNumber,
  //   grossAmount,
  //   netAmount,
  //   totalDiscount,
  //   serviceCharges,
  //   totalTax,
  //   creditBillReason,
  //   ArNumber,
  //   items,
  //   billNumber
  // }: any) {
  //   try {
  //     const body = {
  //       id,
  //       BusinessDate,
  //       BusinessDateTime,
  //       checkNumber,
  //       grossAmount,
  //       netAmount,
  //       totalDiscount,
  //       serviceCharges,
  //       totalTax,
  //       // creditBillReason,
  //       ...(ArNumber && { ArNumber }),
  //       items,
  //       billNumber
  //     }
  //     const headers = {
  //       Authorization: `Bearer ${accessToken}`,
  //       'Content-Type': 'application/json',
  //     }
  //     // console.log(body);
  //     const response = await axios.post(
  //       createZatcaEndpoint({
  //         branchId: conceptsSelected,
  //         operation: 'resubmitInvoice',
  //         env: selectedConcept.env,
  //         type: 'simphony',
  //       }),
  //       body,
  //       { headers },
  //     )
  //     showConfirm(`${singleName} has been sent successfully`)
  //     return response.data
  //   } catch (error:any) {
  //     console.error('Error sening Credit invoice :', error)
  //     throw error
  //   }
  // }

  async function exportAll(params: any) {
    try {
      let exportedData = [];

      const data: any = await fetch(params);
      dispatch(setListing(data));

      for (let user of data!) {
        let row: any = { ...user };
        exportedData.push(row);
      }

      return exportedData;
    } catch (err: any) {
      showError(err);
    }
  }

  // async function submitCreditNote(document: Invoice, invoiceLine: InvoiceLine) {
  //   try {
  //     // Check document status
  //     if (
  //       document.status !== INVOICE_STATUS.SUBMITTED &&
  //       document.status !== INVOICE_STATUS.VALID
  //     ) {
  //       showError(`Can send credit note for ${document.status} document.`)
  //       return
  //     }

  //     const branchId = document.conceptID
  //     const operation = EG_OPERATIONS.CANCEL_INVOICE_LINE

  //     /* Create new credit note document */
  //     let taxTotals = []
  //     if (invoiceLine.taxableItems)
  //       for (const taxLine of invoiceLine.taxableItems) {
  //         if (taxLine) {
  //           let tax = {
  //             taxType: taxLine.taxType,
  //             amount: taxLine.amount,
  //           }
  //           taxTotals.push(tax)
  //         }
  //       }

  //     let creditNoteDoc: any = {
  //       // Static Fields
  //       issuer: document.issuer,
  //       receiver: document.receiver,
  //       documentType: 'C',
  //       documentTypeVersion: document.documentTypeVersion,
  //       dateTimeIssued: getFormattedDate(new Date()),
  //       taxpayerActivityCode: document.taxpayerActivityCode,
  //       internalID: document.internalID,
  //       // Zeros Fields
  //       totalDiscountAmount: document.totalDiscountAmount,
  //       extraDiscountAmount: document.extraDiscountAmount,
  //       totalItemsDiscountAmount: document.totalItemsDiscountAmount,
  //       // Calculated Fields
  //       invoiceLines: [invoiceLine],
  //       netAmount: invoiceLine.netTotal,
  //       totalSalesAmount: invoiceLine.salesTotal,
  //       totalAmount: invoiceLine.total,
  //       taxTotals: taxTotals,
  //     }

  //     const requestPayload = {
  //       createdByID: session ? session.sub : '',
  //       createdByName: session ? session.name : '',
  //       document: document,
  //       creditNoteDoc: creditNoteDoc,
  //     }

  //     const requestOptions = {
  //       method: 'POST',
  //       headers: { 'Content-Type': 'application/json' },
  //       body: JSON.stringify(requestPayload),
  //     }

  //     await window
  //       .fetch(
  //         `${EG_SEND_INVOICE}?operation=${operation}&branchId=${branchId}`,
  //         requestOptions,
  //       )
  //       .then((response) => response.text())
  //       .then((text) => {
  //         let responseBody = JSON.parse(text)
  //         console.log({ responseBody })

  //         let StatusMessages = responseBody.StatusMessages.Messages
  //         if (StatusMessages.length > 0) {
  //           let errorMessage = ''
  //           StatusMessages.forEach((message: any) => {
  //             errorMessage += message.Description
  //           })

  //           showError(errorMessage)
  //         }
  //       })
  //       .catch((err) => {
  //         showError(
  //           typeof err.message === 'string'
  //             ? err.message
  //             : 'error occurred while sending SMS',
  //         )
  //       })
  //   } catch (err) {
  //     showError(
  //       typeof err.message === 'string' ? err.message : 'Error occurred',
  //     )
  //     console.log(err)
  //   }
  // }

  /*
   * Get invoices listed in CSV format
   * Convert them to JSON
   * Save this JSON in local file
   * Loop over this list to submit invoice/credit note
   *
   * */
  // async function importDataExcelFile(
  //   file: File,
  //   accountID: string,
  //   conceptID: string,
  // ) {
  //   let folioNo = ''
  //   let quantity = 0
  //   let unitPrice = 0
  //   let netAmount = 0
  //   let salesAmount = 0
  //   let tax = 0
  //   let serviceCharge = 0
  //   let grossAmount = 0
  //   let currency = ''
  //   let exchangeRate = 0
  //   let transactionCode = ''
  //   let accountNumber = ''
  //   let firstName = ''
  //   let lastName = ''
  //   let identificationID = ''

  //   let documentType = 'C' // I for Invoice, C for Credit Note
  //   let documentTypeVersion = '1.0'
  //   let date = '2023-10-30T00:00:00Z'
  //   let prefix = `` // V for Village, I for Island

  //   let invoiceLines: any[] = []
  //   let invoices: any[] = []

  //   const listingByAccountParams: ListingByAccountVariables = {
  //     accountID: accountID,
  //     conceptID: '',
  //     searchText: '',
  //     startIndex: 0,
  //     limit: 1000,
  //     session,
  //   }

  //   try {
  //     /* Database requests for mapping tables */
  //     const databaseMapping = await Promise.all([
  //       conceptsGet(conceptID),
  //       customersFetch(listingByAccountParams),
  //       itemsFetch(listingByAccountParams),
  //     ])

  //     const branch: Concept = databaseMapping[0]
  //     const customers = databaseMapping[1]
  //     const itemsMapping = databaseMapping[2]
  //     prefix = branch.prefix ? `${branch.prefix}-` : ''

  //     let issuer: any = {}
  //     if (branch && branch.branchAddress) {
  //       const branchAddress: any = {}
  //       branchAddress.branchId = branch.branchAddress.branchId
  //       branchAddress.country = branch.branchAddress.country
  //       branchAddress.governate = branch.branchAddress.governate
  //       branchAddress.regionCity = branch.branchAddress.regionCity
  //       branchAddress.street = branch.branchAddress.street
  //       branchAddress.buildingNumber = branch.branchAddress.buildingNumber
  //       branchAddress.postalCode = branch.branchAddress.postalCode
  //       branchAddress.floor = branch.branchAddress.floor
  //       branchAddress.room = branch.branchAddress.room
  //       branchAddress.landmark = branch.branchAddress.landmark
  //       branchAddress.additionalInformation =
  //         branch.branchAddress.additionalInformation

  //       issuer.type = branch.type ? branch.type : 'B'
  //       issuer.id = branch.rin
  //       issuer.name = branch.companyTradeName
  //       issuer.address = branchAddress
  //     }

  //     const reader = new FileReader()
  //     reader.readAsBinaryString(file)

  //     reader.onload = async (event) => {
  //       const data = event.target?.result
  //       const workbook = XLSX.read(data, { type: 'binary' })
  //       const sheetName = workbook.SheetNames[0]
  //       const worksheet = workbook.Sheets[sheetName]
  //       const rows = XLSX.utils.sheet_to_json(worksheet)

  //       let currentFolioNumber = ''
  //       let guestName = ''
  //       let arrivalDate = ''
  //       let departureDate = ''
  //       let roomNumber = ''

  //       let currentCustomer: any = null
  //       let totalNet = 0
  //       let totalTax = 0
  //       let totalService = 0
  //       let totalSalesAmount = 0
  //       let totalAmount = 0
  //       let rate = 1

  //       for (let i = 0; i < rows.length; i++) {
  //         const row: any = rows[i]
  //         folioNo = row['folioNo'] || ''

  //         // Receiver Data
  //         accountNumber = row['accountNumber'] || ''
  //         firstName = row['firstName'] || ''
  //         lastName = row['lastName'] || ''
  //         firstName = firstName.replace("'", ' ').trim()
  //         lastName = lastName.replace("'", ' ').trim()

  //         identificationID = row['identificationID'] || ''

  //         // First Invoice Only
  //         if (currentFolioNumber === '') {
  //           currentFolioNumber = folioNo
  //           guestName = (firstName + ' ' + lastName).trim()
  //           currentCustomer = await getDocumentCustomer(
  //             accountNumber,
  //             firstName,
  //             lastName,
  //             customers,
  //             identificationID,
  //           )
  //         }

  //         if (currentFolioNumber !== folioNo) {
  //           // Loop over invoice lines and add foreign currency
  //           let convertCurrency = false
  //           let invoiceCurrency = 'EGP'
  //           let invoiceRate = 1
  //           for (let invoiceLine of invoiceLines) {
  //             if (invoiceLine.unitValue.currencySold !== 'EGP') {
  //               convertCurrency = true
  //               invoiceCurrency = invoiceLine.unitValue.currencySold
  //               invoiceRate = invoiceLine.unitValue.currencyExchangeRate

  //               break
  //             }
  //           }

  //           let updateInvoiceLines = []
  //           if (convertCurrency) {
  //             for (let invoiceLine of invoiceLines) {
  //               invoiceLine = {
  //                 ...invoiceLine,
  //                 unitValue: {
  //                   amountEGP: invoiceLine.unitValue.amountEGP,
  //                   amountSold: +(
  //                     invoiceLine.unitValue.amountEGP / invoiceRate
  //                   ).toFixed(5),
  //                   currencyExchangeRate: +invoiceRate.toFixed(5),
  //                   currencySold: invoiceCurrency,
  //                 },
  //               }

  //               updateInvoiceLines.push(invoiceLine)
  //             }
  //           } else {
  //             updateInvoiceLines = invoiceLines
  //           }

  //           // Add created invoice
  //           let invoice = {
  //             // Static Fields
  //             documentType: documentType,
  //             documentTypeVersion: documentTypeVersion,
  //             dateTimeIssued: date,

  //             issuer: issuer,
  //             receiver: currentCustomer.address
  //               ? {
  //                   type: currentCustomer.type,
  //                   id: currentCustomer.registrationNumber,
  //                   name: currentCustomer.name,
  //                   address: currentCustomer.address,
  //                 }
  //               : {
  //                   type: currentCustomer.type,
  //                   id: currentCustomer.registrationNumber || '',
  //                   name: currentCustomer.name,
  //                 },
  //             purchaseOrderReference:
  //               guestName !== '' ? 'Guest Name: ' + guestName : '',
  //             purchaseOrderDescription: `Arrival Date ${arrivalDate} Departure Date ${departureDate} Room Number ${roomNumber}`,

  //             taxpayerActivityCode: branch.activityCode,
  //             internalID: `${prefix}${currentFolioNumber.toString()}`,

  //             // Zeros Fields
  //             totalDiscountAmount: 0,
  //             extraDiscountAmount: 0,
  //             totalItemsDiscountAmount: 0,

  //             // Calculated Fields
  //             invoiceLines: updateInvoiceLines,
  //             netAmount: +totalNet.toFixed(5),
  //             totalSalesAmount: +totalSalesAmount.toFixed(5),
  //             totalAmount: +totalAmount.toFixed(5),
  //             taxTotals: [
  //               {
  //                 taxType: 'T1',
  //                 amount: +totalTax.toFixed(5),
  //               },
  //               {
  //                 taxType: 'T9',
  //                 amount: +totalService.toFixed(5),
  //               },
  //             ],
  //           }

  //           if (invoiceLines.length > 0) invoices.push(invoice)

  //           // Init new invoice
  //           totalNet = 0
  //           totalTax = 0
  //           totalService = 0
  //           totalSalesAmount = 0
  //           totalAmount = 0
  //           invoiceLines = []
  //           convertCurrency = false

  //           currentFolioNumber = folioNo
  //           guestName = (firstName + ' ' + lastName).trim()
  //           currentCustomer = await getDocumentCustomer(
  //             accountNumber,
  //             firstName,
  //             lastName,
  //             customers,
  //             identificationID,
  //           )
  //         }

  //         netAmount = row['netAmount'] || 0
  //         quantity = Math.abs(row['quantity']) || 0
  //         salesAmount = Math.abs(row['netAmount']) || 0
  //         tax = Math.abs(row['tax']) || 0
  //         serviceCharge = Math.abs(row['serviceCharge']) || 0
  //         grossAmount = Math.abs(row['grossAmount']) || 0
  //         unitPrice = +salesAmount / +quantity
  //         currency = row['currency'] || ''
  //         exchangeRate = row['exchangeRate'] || ''
  //         transactionCode = row['transactionCode'] || ''

  //         // Reservation Info
  //         arrivalDate = row['arrivalDate'] || ''
  //         departureDate = row['departureDate'] || ''
  //         roomNumber = row['roomNumber'] || ''

  //         if (typeof arrivalDate === 'number') {
  //           arrivalDate = await getDocumentDateFormatted(arrivalDate)
  //         }

  //         if (typeof departureDate === 'number') {
  //           departureDate = await getDocumentDateFormatted(departureDate)
  //         }
  //         // Skip zero lines
  //         if (+netAmount === 0) {
  //           continue
  //         }

  //         if (
  //           (documentType === 'I' && +netAmount < 0) ||
  //           (documentType === 'C' && +netAmount > 0)
  //         ) {
  //           continue
  //         }
  //         netAmount = Math.abs(netAmount)

  //         totalTax += +tax
  //         totalService += +serviceCharge
  //         totalNet += +netAmount
  //         totalSalesAmount += +salesAmount
  //         totalAmount += +grossAmount
  //         rate = +(1 / exchangeRate).toFixed(5)

  //         const itemMapping = itemsMapping.find(
  //           // eslint-disable-next-line no-loop-func
  //           (obj: Item) => obj.internalCode === transactionCode.toString(),
  //         )

  //         let line = {
  //           description: itemMapping ? itemMapping.description : '',
  //           internalCode: transactionCode.toString(),
  //           itemCode: itemMapping ? itemMapping.itemCode : '',
  //           itemType: itemMapping ? itemMapping.type : '',
  //           unitType: itemMapping ? itemMapping.unit : 'EA',

  //           itemsDiscount: 0,
  //           netTotal: +netAmount.toFixed(5),
  //           quantity: quantity,
  //           salesTotal: +salesAmount.toFixed(5),
  //           total: +grossAmount.toFixed(5),
  //           totalTaxableFees: +serviceCharge.toFixed(5),

  //           unitValue:
  //             currency !== 'EGP'
  //               ? {
  //                   amountEGP: +unitPrice.toFixed(5),
  //                   amountSold: +(unitPrice / rate).toFixed(5),
  //                   currencyExchangeRate: rate,
  //                   currencySold: currency,
  //                 }
  //               : {
  //                   amountEGP: +unitPrice.toFixed(5),
  //                   currencySold: currency,
  //                 },
  //           valueDifference: 0,

  //           taxableItems: [
  //             {
  //               amount: +tax.toFixed(5),
  //               rate: 14,
  //               subType: 'V009',
  //               taxType: 'T1',
  //             },
  //             {
  //               amount: +serviceCharge.toFixed(5),
  //               rate: 12,
  //               subType: 'SC01',
  //               taxType: 'T9',
  //             },
  //           ],
  //         }

  //         invoiceLines.push(line)
  //       }

  //       // Add last invoice
  //       let invoice = {
  //         // Static Fields
  //         documentType: documentType,
  //         documentTypeVersion: documentTypeVersion,
  //         dateTimeIssued: date,

  //         issuer: issuer,
  //         receiver: currentCustomer.address
  //           ? {
  //               type: currentCustomer.type,
  //               id: currentCustomer.registrationNumber,
  //               name: currentCustomer.name,
  //               address: currentCustomer.address,
  //             }
  //           : {
  //               type: currentCustomer.type,
  //               id: currentCustomer.registrationNumber || '',
  //               name: currentCustomer.name,
  //             },
  //         purchaseOrderReference:
  //           guestName !== '' ? 'Guest Name: ' + guestName : '',
  //         purchaseOrderDescription: `Arrival Date ${arrivalDate} Departure Date ${departureDate} Room Number ${roomNumber}`,

  //         taxpayerActivityCode: branch.activityCode,
  //         internalID: `${prefix}${currentFolioNumber}`,

  //         // Zeros Fields
  //         totalDiscountAmount: 0,
  //         extraDiscountAmount: 0,
  //         totalItemsDiscountAmount: 0,

  //         // Calculated Fields
  //         invoiceLines: invoiceLines,
  //         netAmount: +totalNet.toFixed(5),
  //         totalSalesAmount: +totalSalesAmount.toFixed(5),
  //         totalAmount: +totalAmount.toFixed(5),
  //         taxTotals: [
  //           {
  //             taxType: 'T1',
  //             amount: +totalTax.toFixed(5),
  //           },
  //           {
  //             taxType: 'T9',
  //             amount: +totalService.toFixed(5),
  //           },
  //         ],
  //       }
  //       if (invoiceLines.length > 0) invoices.push(invoice)

  //       console.log(JSON.stringify(invoices))
  //       return invoices
  //     }
  //   } catch (err) {
  //     console.log(err)
  //     showError(
  //       typeof err.message === 'string' ? err.message : 'Error occurred',
  //     )
  //   }
  // }

  // async function getDocumentCustomer(
  //   accountNumber: string,
  //   firstName: string,
  //   lastName: string,
  //   customers: Customer[],
  //   identificationID: string,
  // ) {
  //   let currentCustomer

  //   if (accountNumber !== '') {
  //     for (let i = 0; i < customers.length; i++) {
  //       if (customers[i].ArNumber === accountNumber + '') {
  //         currentCustomer = customers[i]

  //         // Use customer passport number in case of "F", and there is identificationID
  //         if (identificationID !== '' && currentCustomer.type === 'F') {
  //           currentCustomer.registrationNumber = identificationID
  //         }

  //         break
  //       }
  //     }
  //   } else {
  //     if (identificationID !== '' && identificationID.length !== 14) {
  //       currentCustomer = {
  //         type: 'F',
  //         registrationNumber: identificationID,
  //         name: (firstName + ' ' + lastName).trim(),
  //       }
  //     } else {
  //       currentCustomer = {
  //         // type: identificationID.length === 14 ? "P" : "F",
  //         // id: identificationID,
  //         type: 'P',
  //         registrationNumber: '',
  //         name: (firstName + ' ' + lastName).trim(),
  //       }
  //     }
  //   }

  //   return currentCustomer
  // }

  // async function getDocumentDateFormatted(dateNumber: number) {
  //   let converted_date = new Date(Math.round((dateNumber - 25569) * 864e5))
  //   let converted_date_str = String(converted_date).slice(4, 15)

  //   let date = converted_date_str.split(' ')
  //   let day = date[1]
  //   let month = date[0]
  //   let monthNumber =
  //     'JanFebMarAprMayJunJulAugSepOctNovDec'.indexOf(month) / 3 + 1
  //   let year = date[2]

  //   let result = String(day + '-' + monthNumber + '-' + year)
  //   return result
  // }

  const headCells: readonly HeadCell[] = [
    {
      id: "billNumber",
      numeric: false,
      disablePadding: false,
      label: "Folio_Headers_Info",
    },
    {
      id: "folioNumber",
      numeric: false,
      disablePadding: false,
      label: "Bill_NO",
    },
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: "Type",
    },
    {
      id: "conceptID",
      numeric: false,
      disablePadding: false,
      label: "Concept",
    },
    {
      id: "taxExclusiveAmount",
      numeric: false,
      disablePadding: false,
      label: "Net Amount",
    },
    {
      id: "payableAmount",
      numeric: false,
      disablePadding: false,
      label: "Total Amount",
    },
    {
      id: "issuer",
      numeric: false,
      disablePadding: false,
      label: "Branch",
    },
    {
      id: "customer",
      numeric: false,
      disablePadding: false,
      label: "Customer",
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Status",
    },
    {
      id: "errorMessages",
      numeric: false,
      disablePadding: false,
      label: "Reason",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: false,
      label: "",
    },
  ];

  const lineHeadCells: readonly HeadCell[] = [
    {
      id: "id",
      numeric: false,
      disablePadding: false,
      label: "Internal Code",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "priceAmount",
      numeric: false,
      disablePadding: false,
      label: "Price Amount",
    },
    {
      id: "quantity",
      numeric: false,
      disablePadding: false,
      label: "Quantity",
    },
    {
      id: "roundingAmount",
      numeric: false,
      disablePadding: false,
      label: "Rounding Amount",
    },
    {
      id: "taxAmount",
      numeric: false,
      disablePadding: false,
      label: "Tax Amount",
    },
    {
      id: "taxExclusivePrice",
      numeric: false,
      disablePadding: false,
      label: "Tax ExclusivePrice",
    },
    {
      id: "vatPercent",
      numeric: false,
      disablePadding: false,
      label: "Vat Percent",
    },
  ];

  const dataCells: readonly string[] = [
    "billNumber",
    "folioNumber",
    "type",
    "conceptID",
    "taxExclusiveAmount",
    "payableAmount",
    "issuer",
    "customer",
    "status",
    "errorMessages",
  ];

  const options: any[] = [];

  if (listing)
    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }

  const api: any = {};

  api[`${listingName}Listing`] = listing;
  api[`${listingName}Options`] = options;
  api[`${listingName}SearchText`] = searchText;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}LineHeadCells`] = lineHeadCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Selected`] = selected;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchStats`] = fetchStats;
  api[`${listingName}Get`] = get;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}Export`] = exportAll;
  api[`${listingName}CancelInvoice`] = cancelInvoice;
  api[`${listingName}ResubmitInvoice`] = resubmitInvoice;
  api[`${listingName}Create`] = create;
  // api[`${listingName}SubmitCreditNote`] = submitCreditNote
  // api[`${listingName}ImportData`] = importDataExcelFile

  api[`${listingName}ChangeListing`] = (listing: ZatcaInvoice[]) =>
    dispatch(setListing(listing));
  api[`${listingName}Search`] = (searchText: string) =>
    dispatch(setSearchText(searchText));
  api[`${listingName}Select`] = (zatcaInvoice: ZatcaInvoice) =>
    dispatch(setSelected(zatcaInvoice));

  return api;
};

export default useZatcaInvoice;
