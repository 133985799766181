import { useState } from 'react'
import { Token } from '../models/app'

interface StateProps {
  startIndex: number
  limit: number
  nextToken: Token
  nextNextToken: Token
  previousTokens: Token[]

  moveForward: boolean
}

const INITIAL_STATE: StateProps = {
  startIndex: 0,
  limit: 10,
  nextToken: null,
  nextNextToken: null,
  previousTokens: [],

  moveForward: true,
}

const usePaginate = () => {
  const [state, setState] = useState(INITIAL_STATE)
  const { previousTokens } = state

  return {
    ...state,
    hasNext: !!state.nextNextToken,
    hasPrev: state.previousTokens.length > 0,

    changeLimit: async (limit: number) => {
      setState({
        ...INITIAL_STATE,
        limit: limit,
        nextToken: null,
      })
    },

    changeNextToken: (nextToken: Token) =>
      setState({
        ...state,
        previousTokens: [...previousTokens, nextToken],
        nextToken: nextToken,
      }),
    next: () =>
      setState((state) => ({
        ...state,
        moveForward: true,
        startIndex: state.startIndex + state.limit,
      })),
    prev: () =>
      setState((state) => ({
        ...state,
        moveForward: false,
        startIndex: state.startIndex - state.limit,
      })),

    reset: async (limit: number) => {
      console.log({
        limit,
      })
      setState({
        ...INITIAL_STATE,
        limit: limit,
        nextToken: null,
        previousTokens: [],
        moveForward: true,
        startIndex: 0,
      })
    },
  }
}

export default usePaginate
