import { ADMIN_ROLES } from "../constants/enums";
import { AdminPermissions, GetVariables } from "../models/app";
import useAdminRole from "./useAdminRole";

const usePermissions = () => {
  const { adminRolesGet } = useAdminRole("adminRoles", "adminRole");

  const adminPermissions: AdminPermissions = {
    canEditSettings: false,
    hasAllConcepts: false,
    canSeeDashboard: false,
    canSeeCustomers: false,
    canSeeReceipts: false,
    canSeeInvoices: false,
    canSeeZatcaInvoices: false,
    canSeeInvokers: false,
    canPerformInvoicesOperations: false,
    concepts: [],
  };

  const getAdminPermissions = async (props: {
    userGroup: any;
  }): Promise<AdminPermissions> => {
    const { userGroup } = props;

    if (userGroup && userGroup.length > 0) {
      let userRoles: any[] = [];
      for (let group of userGroup) {
        userRoles = userRoles.concat(group.roles);
      }

      console.log({ userRoles });

      for (let role of userRoles) {
        console.log({ role });
        const adminRolesParams: GetVariables = {
          id: role,
        };
        const userRole = await adminRolesGet(adminRolesParams.id);
        switch (userRole.name) {
          case ADMIN_ROLES.SETTINGS:
            adminPermissions.canEditSettings = true;
            break;
          case ADMIN_ROLES.DASHBOARD:
            adminPermissions.canSeeDashboard = true;
            break;
          case ADMIN_ROLES.CUSTOMERS:
            adminPermissions.canSeeCustomers = true;
            break;
          case ADMIN_ROLES.RECEIPTS:
            adminPermissions.canSeeReceipts = true;
            break;
          case ADMIN_ROLES.INVOICES:
            adminPermissions.canSeeInvoices = true;
            break;
          case ADMIN_ROLES.ZATCA_INVOICES:
            adminPermissions.canSeeZatcaInvoices = true;
            break;
          case ADMIN_ROLES.PERFORM_INVOICES_OPERATIONS:
            adminPermissions.canPerformInvoicesOperations = true;
            break;
          case ADMIN_ROLES.INVOKERS:
            adminPermissions.canSeeInvokers = true;
            break;
          case ADMIN_ROLES.ALL_CONCEPTS:
            adminPermissions.hasAllConcepts = true;
            break;
          default:
            break;
        }
      }
    }

    console.log({ adminPermissions });

    return adminPermissions;
  };

  return { getAdminPermissions };
};

export default usePermissions;
