import { Auth } from "aws-amplify";
import { Pages } from "../constants/enums";
import useAccount from "./useAccount";
import useApp from "./useApp";
import useFeature from "./useFeature";
import useConcept from "./useConcept";
import { AdminPermissions, ListingByAccountVariables } from "../models/app";
import useAdminGroup from "./useAdminGroup";
import usePermissions from "./usePermissions";

const useAuth = () => {
  const { showError, showConfirm, setSession, clearSession, navigateTo, setPermissions, setPermissionsLoaded } =
    useApp();
  const { accountsGetDomainName, accountsFetchBySiteAddress } = useAccount(
    "accounts",
    "account"
  );
  const { featuresFetch, featuresChangeListing } = useFeature(
    "features",
    "feature"
  );
  const { conceptsFetch, conceptsChangeListing } = useConcept(
    "concepts",
    "concept"
  );
    const { adminGroupsFetchCurrentUser } = useAdminGroup(
    'adminGroups',
    'adminGroup',
  );
  const { getAdminPermissions } = usePermissions();

  async function register(data: any) {
    try {
      const domain = await accountsGetDomainName();

      await Auth.signUp({
        username: `${domain}_${data.email}`,
        password: data.password,
        attributes: {
          name: data.name,
          email: `${domain}_${data.email}`,
        },
        clientMetadata: {
          accountID: "",
          accountName: domain,
        },
      });

      navigateTo("/");
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error Occurred"
      );
      console.log("error signing up:", err);
    }
  }

  async function login(data: any) {
    try {
      const domain = await accountsGetDomainName();

      const user = await Auth.signIn({
        username: `${domain}_${data.email}`,
        password: data.password,
      });

      const session = user.signInUserSession.idToken.payload;
      setSession(session);

      const account = await accountsFetchBySiteAddress(session);
      const listingByAccountParams: ListingByAccountVariables = {
        accountID: account ? account.id : "",
        searchText: "",
        startIndex: 0,
        limit: 1000,
        session,
      };
      const [accountFeatures, concepts, userGroup] = await Promise.all([featuresFetch(listingByAccountParams), conceptsFetch(listingByAccountParams), adminGroupsFetchCurrentUser(session.sub)]);
      conceptsChangeListing(concepts);
      featuresChangeListing(accountFeatures);
      const permissions: AdminPermissions = await getAdminPermissions({
        userGroup,
      });
      setPermissions(permissions);
      setPermissionsLoaded(true);
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error Occurred"
      );
      console.log("error signing in", err);
    }
  }

  async function logout() {
    try {
      await Auth.signOut();

      clearSession();
      setPermissionsLoaded(false);
      navigateTo("/");
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error Occurred"
      );
      console.log("error signing out: ", err);
    }
  }

  async function forgetPassword(data: any) {
    try {
      const domain = await accountsGetDomainName();

      // Send confirmation code to user's email
      await Auth.forgotPassword(`${domain}_${data.email}`);
      showConfirm("Verification code has been sent to your email.");
      navigateTo(`/${Pages.RESET_PASSWORD}`);
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error Occurred"
      );
      console.log("error sending code to email: ", err);
    }
  }

  async function forgetPasswordSubmit(data: any) {
    try {
      const domain = await accountsGetDomainName();

      const resetPassword = await Auth.forgotPasswordSubmit(
        `${domain}_${data.email}`,
        data.code,
        data.newPassword
      );
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error Occurred"
      );
      console.log(err);
    }
  }

  return { login, register, logout, forgetPassword, forgetPasswordSubmit };
};

export default useAuth;
