import { deleteInvoker, updateInvoker } from "./../graphql/mutations";
import {
  CreateInvokerInput,
  Invoker,
  UpdateInvokerInput,
} from "./../models/GQL_API";
import { API } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

import { useDispatch, useSelector } from "react-redux";
import {
  setListing,
  setListingAll,
  setNextToken,
  setSearchText,
  setSelected,
} from "../store/ducks/invokers";
import { HeadCell } from "../models/dataTable";
import useApp from "./useApp";
import { getInvoker, listInvokers } from "../graphql/queries";
import { createInvoker } from "../graphql/mutations";
import { ListingVariables } from "../models/app";
import useConcept from "./useConcept";
import { genSaltSync, hashSync } from "bcrypt-ts";
import { extractSelectedCheckboxes } from "../helpers/utils";

const useInvokers = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { session, showConfirm, showError } = useApp();
  const { conceptsSelected } = useConcept("concepts", "concept");

  const listing = useSelector(
    (state: any) => state[`${listingName}`]["listing"]
  );
  const listingAll = useSelector(
    (state: any) => state[`${listingName}`]["listingAll"]
  );

  const nextToken = useSelector(
    (state: any) => state[`${listingName}`]["nextToken"]
  );

  const searchText = useSelector(
    (state: any) => state[`${listingName}`]["searchText"]
  );
  const selected = useSelector(
    (state: any) => state[`${listingName}`]["selected"]
  );

  async function fetch(params: ListingVariables) {
    try {
      const { startIndex, limit, session } = params;

      const filter: any = {
        deleted: { eq: "0" },
      };

      if (searchText) filter.name = { contains: searchText.toLowerCase() };

      const invokerList: any = await API.graphql<Invoker>({
        query: listInvokers,
        variables: { filter, limit, nextToken: nextToken },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      const currentNextToken = invokerList.data.listInvokers.nextToken;
      const listing = invokerList.data.listInvokers.items;

      dispatch(setListing(listing));
      dispatch(setNextToken(currentNextToken));

      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message);
    }
  }

  async function fetchAll(params: ListingVariables) {
    try {
      const { session } = params;

      const filter: any = {
        deleted: { eq: "0" },
      };

      const invokerList: any = await API.graphql<Invoker>({
        query: listInvokers,
        variables: { filter },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      const listing = invokerList.data.listInvokers.items;

      dispatch(setListingAll(listing));

      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message);
    }
  }

  async function get(id: string) {
    try {
      let single: Invoker | undefined;
      if (listing.length !== 0) {
        single = listing.find((resource: any) => resource.id === id);
      }

      if (single === undefined) {
        const listing: any = await API.graphql<Invoker>({
          query: getInvoker,
          variables: { id },
          authMode: true
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
        single = listing.data.getInvoker;
      }

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function create(data: any) {
    try {
      const hashedPassword = hashSync(data.password.trim(), 10);
      const createInput: CreateInvokerInput = {
        userName: data.userName.trim().toLowerCase(),
        password: hashedPassword,
        conceptID: conceptsSelected,
        operations: data.operations,
        deleted: "0",
        createdAt: new Date().getTime().toString(),
        createdByID: session.sub,
        createdByName: session.name,
      };
      await API.graphql<Invoker>({
        query: createInvoker,
        variables: { input: createInput },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      showConfirm(`New ${singleName} has been created successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function update(id: string, data: any) {
    try {
      const original = await get(id);
      let hashedPassword = original?.password;
      if (data.password !== hashedPassword) {
        hashedPassword = hashSync(data.password.trim(), 10);
      }
      const operations = extractSelectedCheckboxes("operations_", data);
      if (original) {
        const updateInput: UpdateInvokerInput = {
          id: original.id,
          userName: data.userName ? data.userName.trim().toLowerCase() : original!.userName,
          password: hashedPassword,
          operations: operations,
        };
        await API.graphql<Invoker>({
          query: updateInvoker,
          variables: { input: updateInput },
          authMode: true
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
      }
      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function trash(id: string) {
    try {
      const original = await get(id);

      if (original) {
        const updateInput: UpdateInvokerInput = {
          id: original.id,
          deleted: "1",
        };

        await API.graphql<Invoker>({
          query: updateInvoker,
          variables: { input: updateInput },
          authMode: true
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
      }

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(resourceIds: any) {
    for (let id of resourceIds) {
      try {
        await trash(id);
      } catch (err: Error | any) {
        showError(err);
      }
    }

    dispatch(
      setListing(
        listing.filter((resource: any) => !resourceIds.has(resource.id))
      )
    );

    showConfirm(
      `${resourceIds.size} ${listingName} items has been moved to trash`
    );
  }

  async function remove(id: any) {
    try {
      await API.graphql<Invoker>({
        query: deleteInvoker,
        variables: { id: id },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      dispatch(
        setListing(listing.filter((resource: any) => resource.id !== id))
      );

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      console.log(err);
      showError(err);
    }
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "userName",
      numeric: false,
      disablePadding: false,
      label: "User Name",
    },
    // {
    //   id: "password",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "Password",
    // },
    // {
    //   id: "operations",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "Operations",
    // },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["userName"];

  const options: any[] = [{ label: "None", value: "-1" }];

  if (listing)
    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }

  const api: any = {};

  api[`${listingName}Listing`] = listing;
  api[`${listingName}AllListing`] = listingAll;
  api[`${listingName}Options`] = options;
  api[`${listingName}SearchText`] = searchText;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Selected`] = selected;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchAll`] = fetchAll;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}ChangeListing`] = (listing: Invoker[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeAllListing`] = (listing: Invoker[]) =>
    dispatch(setListingAll(listing));
  api[`${listingName}Search`] = (searchText: string) =>
    dispatch(setSearchText(searchText));
  api[`${listingName}Select`] = (conceptID: string) =>
    dispatch(setSelected(conceptID));

  return api;
};

export default useInvokers;
