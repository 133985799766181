export enum Directions {
  RTL = "rtl",
  LTR = "ltr",
}

export enum Languages {
  ENGLISH = "en",
  ARABIC = "ar",
}

export enum DATE_PICKER_TYPES {
  SINGLE = "single",
  RANGE = "range",
}

export enum LOCAL_STORAGE {
  SYNC_BOOKINGS = "sync-bookings",
  SYNC_GUESTS = "sync-guests",
  SYNC_GUEST_STATS = "sync-guest-stats",
  SELECTED_CONCEPT = "selected-concept",
  BOOKING_DATE_RANGE = "booking-date-range",
  BOOKING_GUEST = "booking-guest",
  BOOKING_REFRESH = "booking-refresh",
  BOOKING_SYNC_DURATION = "booking-sync-duration",
  BOOKING_DATE = "booking-date",
  GUESTS_SEARCH = "guests-search",
}

export enum Pages {
  LOGIN = "",
  REGISTER = "register",
  VERIFY = "verify",
  FORGOT_PASSWORD = "forget-password",
  DASHBOARD = "overview",
  ACCOUNTS = "accounts",
  ADMINS = "admins",
  LANGUAGES = "languages",
  FEATURES = "features",
  PAGES = "pages",
  MEDIA = "media-library",
  MESSAGES = "messages",
  SETTINGS = "settings",
  CONCEPTS = "concepts",
  TIMELINES = "timelines",
  RESET_PASSWORD = "reset-password",
  BOOKING_CONFIRMATION = "reservations",
  ADMIN_ROLES = "admin-roles",
  ADMIN_GROUPS = "admin-groups",
  REPORTS_DASHBOARD = "ReportsDashboard",
  CUSTOMERS = "customers",
  INVOKERS = "invokers",
  ITEMS = "items",
  RECEIPTS = "receipts",
  INVOICES = "invoices",
  ZactaINVOICES = "zatcaInvoices",
  CREDIT_NOTES = "creditNotes",
  Zacta_CREDIT_NOTES = "zatcaCreditNotes",
  CREATE_INVOICE = "createInvoice",
}

export enum Orders {
  ASC = "asc",
  DSC = "desc",
}

export enum FormActions {
  ADD = "Add",
  EDIT = "Edit",
}

export enum TimelineActions {
  CHANGE_NAME = "name",
  CHANGE_GROUP = "Group",
  CHANGE_EMAIL = "Email",
  CHANGE_VERIFICATION_STATUS = "Verify Guest",
  CHANGE_PHONE_NUMBER = "Phone Number",

  // Transaction
  RESUBMIT_DOCUMENT = "Resubmit Document",
  UPDATE_TRX_LINE= "Update Item",
  Delete_TRX_LINE = "Delete Item",
  CHANGE_TOTAL = "Change total",
}

export enum ResourceTypes {
  USER = "user",
  TRANSACTION = "transaction",
}

export enum AuthErrors {
  USER_EXISTS = "UsernameExistsException",
}

export enum AdminsGroups {
  ADMIN = "admin",
}

export enum AdminsConstants {
  ADMINS_API = "AdminQueries",
}

export enum AdminsApiPaths {
  LIST_USERS = "/listUsers",
  LIST_USERS_IN_GROUP = "/listUsersInGroup",
  LIST_GROUPS_FOR_USER = "/listGroupsForUser",
  GET_USER = "/getUser",
  LIST_GROUPS = "/listGroups",
  ADD_USER_TO_GROUP = "/addUserToGroup",
  REMOVE_USER_FROM_GROUP = "/removeUserFromGroup",
  DISABLE_USER = "/disableUser",
  ENABLE_USER = "/enableUser",
}

export const NOTIFICATION_CHANNELS = [
  { label: "email", value: "email" },
  { label: "sms", value: "sms" },
];

export const ENCRYPTION_KEY =
  "123456dasflasdldfl789asjdflkj3fadsfsdlfds0123456";

export enum ADMIN_ROLES {
  SETTINGS = "settings_access",
  DASHBOARD = "dashboard_access",
  CUSTOMERS = "customers_access",
  RECEIPTS = "receipts_access",
  INVOICES = "invoices_access",
  ZATCA_INVOICES = "zatca_invoices_access",
  PERFORM_INVOICES_OPERATIONS = "invoices_operations_access",
  INVOKERS = "invokers_access",
  ALL_CONCEPTS = "all_concepts_access",
}

export enum INVOICE_STATUS {
  VALID = "valid",
  INVALID = "invalid",
  REJECTED = "rejected",
  CANCELLED = "cancelled",
  SUBMITTED = "submitted",
}

export enum INVOICE_TYPES {
  INVOICE = "Invoice",
  CREDIT_NOTE = "Credit Note",
  DEBIT_NOTE = "Debit Note",
  EXPORT_INVOICE = "Export Invoice",
  EXPORT_CREDIT_NOTE = "Export Credit Note",
  EXPORT_DEBIT_NOTE = "Export Debit Note",
}

export const ZATCA_INVOICE_STATUS = { 
  SUCCESS: 'success', 
  FAIL: 'fail' 
};

export const ZATCA_INVOICE_TYPES = { 
  STANDARD: "standard", 
  STANDARD_CREDIT_NOTE: "standard credit note", 
  SIMPLIFIED: 'simplified', 
  SIMPLIFIED_CREDIT_NOTE: 'simplified credit note', 
}

export const CONCEPT_TYPES = { 
  B: "B", 
  Z: "Z", 
}

export const GLOBAL_GUEST_ID = "12345";

export const Zatca_Tax_Rate = 0.15;

export const LambdaFunctions = {
  SEND_INVOICE_ZATCA: "sendInvoiceZatca"
};