import * as XLSX from "xlsx";
import { customerByArNumber, customerByDeleted, getCustomer } from "./../graphql/queries";
import {
  CreateCustomerInput,
  ModelCustomerFilterInput,
  UpdateCustomerInput,
} from "./../models/GQL_API";
import {
  createCustomer,
  deleteCustomer,
  updateCustomer,
} from "./../graphql/mutations";
import { API } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { useDispatch, useSelector } from "react-redux";
import {
  setListing,
  setSearchText,
  setSelected,
  setNextToken,
  setPreviousToken,
  changeLimit,
  nextAction,
} from "../store/ducks/customer";
import { HeadCell } from "../models/dataTable";
import useApp from "./useApp";
import { listCustomers } from "../graphql/queries";
import { Customer } from "../API";
import { ListingByAccountVariables } from "../models/app";
import useAccount from "./useAccount";

const useCustomer = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { session, showConfirm, showError } = useApp();
  const { accountsSelected } = useAccount("accounts", "account");

  const listing = useSelector(
    (state: any) => state[`${listingName}`]["listing"]
  );
  const searchText = useSelector(
    (state: any) => state[`${listingName}`]["searchText"]
  );

  const selected = useSelector(
    (state: any) => state[`${listingName}`]["selected"]
  );

  const storedLimit = useSelector(
    (state: any) => state[`${listingName}`]["limit"]
  );

  const nextToken = useSelector(
    (state: any) => state[`${listingName}`]["nextToken"]
  );
  const previousTokens = useSelector(
    (state: any) => state[`${listingName}`]["previousTokens"]
  );

  async function fetch(props: ListingByAccountVariables) {
    try {
      const { startIndex, limit, searchText, accountID, moveForward } = props;

      let requestLimit = storedLimit;
      let requestToken = nextToken;
      let requestPreviousTokens = previousTokens;

      // Clear pagination data
      if (limit !== storedLimit || startIndex === 0) {
        requestLimit = limit;
        requestToken = null;
        requestPreviousTokens = [];

        dispatch(changeLimit(limit));
      }

      // Get token from previous tokens
      if (!moveForward) {
        const updatedPreviousTokens = [...requestPreviousTokens];
        updatedPreviousTokens.pop();

        requestToken =
          updatedPreviousTokens.length >= 2
            ? updatedPreviousTokens[updatedPreviousTokens.length - 2]
            : null;
        dispatch(
          setNextToken(updatedPreviousTokens[updatedPreviousTokens.length - 1])
        );
        dispatch(setPreviousToken(updatedPreviousTokens));
      }

      const filter: ModelCustomerFilterInput = {
        accountID: { eq: accountID },
      };

      // Search Filter
      if (searchText) {
        filter.or = [];
        filter.or.push({ ArNumber: { contains: searchText } });
        filter.or.push({ name: { contains: searchText } });
        filter.or.push({ registrationNumber: { contains: searchText } });
        filter.or.push({ mobileNumber: { contains: searchText } });
        filter.or.push({ paymentNumber: { contains: searchText } });
      }

      const customerList: any = await API.graphql<Customer>({
        query: customerByDeleted,
        variables: {
          filter,
          limit: requestLimit,
          nextToken: requestToken,
          sortDirection: "DESC",
          deleted: "0"
        },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      if (moveForward) {
        const currentNextToken = customerList.data.customerByDeleted.nextToken;
        dispatch(nextAction(currentNextToken, requestPreviousTokens));
      }

      const listing = customerList.data.customerByDeleted.items;

      return listing;
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  }

  async function get(id: string) {
    try {
      let single: Customer | undefined;
      if (listing.length !== 0) {
        single = listing.find((resource: any) => resource.id === id);
      }

      if (single === undefined) {
        const listing: any = await API.graphql<Customer>({
          query: getCustomer,
          variables: { id },
          authMode: true
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
        single = listing.data.getCustomer;
      }

      return single;
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  }

  async function getCustomerByArNumber(ArNumber: string, nextToken: string | undefined) {
    try {
      const filter = { deleted: { eq: "0" } }
      const variables:any = { ArNumber, filter }
      if (nextToken) {
        variables.nextToken = nextToken;
      }
        const listing: any = await API.graphql<Customer>({
          query: customerByArNumber,
          variables: variables,
          authMode: true
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
        const customer = listing.data.customerByArNumber.items[0];
      return {customer, nextToken: listing.data.customerByArNumber.nextToken};
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  }

  const checkCustomerWithSameArNumber = async (ArNumber: string) => {
    try {
      let response = await getCustomerByArNumber(ArNumber, undefined);
      let customer = response?.customer;
      let nextToken = response?.nextToken;
      while (!customer && nextToken) {
        response = await getCustomerByArNumber(ArNumber, nextToken);
        customer = response?.customer;
        nextToken = response?.nextToken;
      }
      if (customer) throw new Error("Customer already exist with this ArNumber.")
    } catch (err) {
      throw err;
    }
  };

  async function create(data: any) {
    try {
      await checkCustomerWithSameArNumber(data.ArNumber);

      let address = {
        branchId: data.branchId,
        country: data.country,
        governate: data.governate,
        regionCity: data.regionCity,
        street: data.street,
        buildingNumber: data.buildingNumber,
        postalCode: data.postalCode,
        floor: data.floor,
        room: data.room,
        landmark: data.landmark,
        additionalInformation: data.additionalInformation,
      };

      const createInput: CreateCustomerInput = {
        accountID: accountsSelected ? accountsSelected.id : "",
        ArNumber: data.ArNumber,
        type: data.type,
        name: data.name,
        registrationNumber: data.registrationNumber,
        mobileNumber: data.mobileNumber,
        paymentNumber: data.paymentNumber,
        address: address,

        deleted: "0",
        createdAt: new Date().getTime().toString(),
        createdByID: session ? session.sub : "",
        createdByName: session ? session.name : "",
      };

      const newCustomer = await API.graphql<Customer>({
        query: createCustomer,
        variables: { input: createInput },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

         dispatch(
      setListing(
        [newCustomer,...listing]
      )
    );

      showConfirm(`New ${singleName} has been created successfully`);
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  }

  async function update(id: string, data: any) {
    try {
      const original = await get(id);
      if (original) {
        if (data.ArNumber !== original.ArNumber) {
          await checkCustomerWithSameArNumber(data.ArNumber);
        }

        let branchAddress = {
          branchId: data.branchId,
          country: data.country,
          governate: data.governate,
          regionCity: data.regionCity,
          street: data.street,
          buildingNumber: data.buildingNumber,
          postalCode: data.postalCode,
          floor: data.floor,
          room: data.room,
          landmark: data.landmark,
          additionalInformation: data.additionalInformation,
        };

        console.log({ branchAddress });

        const updateInput: UpdateCustomerInput = {
          id: original.id,
          accountID: data.accountID,

          ArNumber: data.ArNumber ? data.ArNumber : original!.ArNumber,
          name: data.name ? data.name : original!.name,
          type: data.type ? data.type : original!.type,
          registrationNumber: data.registrationNumber,
          mobileNumber: data.mobileNumber,
          paymentNumber: data.paymentNumber,
          address: branchAddress,
        };

        await API.graphql<Customer>({
          query: updateCustomer,
          variables: { input: updateInput },
          authMode: true
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
      }

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  }

  async function trash(id: string) {
    try {
      const original = await get(id);

      if (original) {
        const updateInput: UpdateCustomerInput = {
          id: original.id,
          deleted: "1",
        };

        await API.graphql<Customer>({
          query: updateCustomer,
          variables: { input: updateInput },
          authMode: true
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
      }
      dispatch(
        setListing(listing.filter((resource: any) => resource.id !== id))
      );
      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  }

  async function bulkTrash(resourceIds: any) {
    for (let id of resourceIds) {
      try {
        await trash(id);
      } catch (err: Error | any) {
        showError(err);
      }
    }

    dispatch(
      setListing(
        listing.filter((resource: any) => !resourceIds.has(resource.id))
      )
    );

    showConfirm(
      `${resourceIds.size} ${listingName} items has been moved to trash`
    );
  }

  async function remove(id: any) {
    try {
      await API.graphql<Customer>({
        query: deleteCustomer,
        variables: { id: id },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      dispatch(
        setListing(listing.filter((resource: any) => resource.id !== id))
      );

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  }

  async function importDataExcelFile(file: File, conceptID: string) {
    const reader = new FileReader();
    reader.readAsBinaryString(file);

    reader.onload = async (event) => {
      const data = event.target?.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const rows = XLSX.utils.sheet_to_json(worksheet);

      for (let i = 0; i < rows.length; i++) {
        const row: any = rows[i];

        let ArNumber = row["ArNumber"];
        let companyName = row["name"];
        let rin = row["rin"];
        let businessType = row["type"];

        let country = row["country"];
        let city = row["city"];
        let street = row["street"];
        let governate = row["governate"];

        let data = {
          ArNumber: ArNumber,
          type: businessType,
          name: companyName,
          registrationNumber: rin,

          // Address - Default branch
          branchId: "0",
          country: country,
          governate: governate,
          regionCity: city,
          street: street,
          buildingNumber: "1",

          postalCode: "",
          floor: "",
          room: "",
          landmark: "",
          additionalInformation: "",

          mobileNumber: "",
          paymentNumber: "",
        };

        await create(data);
      }
    };
  }

      async function exportAll(params: any) {
    try {
      let exportedData = [];

      const data = await fetch(params);

      for (let user of data!) {
        let row: any = { ...user };
        exportedData.push(row);
      }

      return exportedData;
    } catch (err) {
      showError(err);
    }
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "ArNumber",
      numeric: false,
      disablePadding: false,
      label: "Ar Number",
    },
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: "Type",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "registrationNumber",
      numeric: false,
      disablePadding: false,
      label: "Registration Number",
    },
    // {
    //   id: "mobileNumber",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "Mobile Number",
    // },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: false,
      label: "Actions",
    },
  ];

  const dataCells: readonly string[] = [
    "ArNumber",
    "type",
    "name",
    "registrationNumber",
  ];

  const options: any[] = [];

  if (listing)
    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }

  const api: any = {};

  api[`${listingName}Listing`] = listing;
  api[`${listingName}Options`] = options;
  api[`${listingName}SearchText`] = searchText;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Selected`] = selected;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}Export`] = exportAll;
  api[`${listingName}ImportData`] = importDataExcelFile;

  api[`${listingName}ChangeListing`] = (listing: Customer[]) =>
    dispatch(setListing(listing));
  api[`${listingName}Search`] = (searchText: string) =>
    dispatch(setSearchText(searchText));
  api[`${listingName}Select`] = (conceptID: string) =>
    dispatch(setSelected(conceptID));

  return api;
};

export default useCustomer;
