/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createAccount = /* GraphQL */ `mutation CreateAccount(
  $input: CreateAccountInput!
  $condition: ModelAccountConditionInput
) {
  createAccount(input: $input, condition: $condition) {
    id
    logo
    domain
    siteTitle
    guestsCount
    tagline
    description
    siteAddress
    defaultLanguage
    languages
    features
    status
    socialLinks
    deleted
    createdAt
    createdByID
    createdByName
    callCenterEmployeesIds
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAccountMutationVariables,
  APITypes.CreateAccountMutation
>;
export const updateAccount = /* GraphQL */ `mutation UpdateAccount(
  $input: UpdateAccountInput!
  $condition: ModelAccountConditionInput
) {
  updateAccount(input: $input, condition: $condition) {
    id
    logo
    domain
    siteTitle
    guestsCount
    tagline
    description
    siteAddress
    defaultLanguage
    languages
    features
    status
    socialLinks
    deleted
    createdAt
    createdByID
    createdByName
    callCenterEmployeesIds
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAccountMutationVariables,
  APITypes.UpdateAccountMutation
>;
export const deleteAccount = /* GraphQL */ `mutation DeleteAccount(
  $input: DeleteAccountInput!
  $condition: ModelAccountConditionInput
) {
  deleteAccount(input: $input, condition: $condition) {
    id
    logo
    domain
    siteTitle
    guestsCount
    tagline
    description
    siteAddress
    defaultLanguage
    languages
    features
    status
    socialLinks
    deleted
    createdAt
    createdByID
    createdByName
    callCenterEmployeesIds
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAccountMutationVariables,
  APITypes.DeleteAccountMutation
>;
export const createConcept = /* GraphQL */ `mutation CreateConcept(
  $input: CreateConceptInput!
  $condition: ModelConceptConditionInput
) {
  createConcept(input: $input, condition: $condition) {
    id
    accountID
    name
    hotelCode
    env
    prefix
    type
    rin
    crn
    productionCertificate
    productionApiSecret
    companyTradeName
    branchCode
    activityCode
    branchAddress {
      branchId
      country
      governate
      regionCity
      citySubdivision
      plotIdentification
      street
      buildingNumber
      postalCode
      floor
      room
      landmark
      additionalInformation
      __typename
    }
    clientId
    clientSecret
    synchronizeProcess
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateConceptMutationVariables,
  APITypes.CreateConceptMutation
>;
export const updateConcept = /* GraphQL */ `mutation UpdateConcept(
  $input: UpdateConceptInput!
  $condition: ModelConceptConditionInput
) {
  updateConcept(input: $input, condition: $condition) {
    id
    accountID
    name
    hotelCode
    env
    prefix
    type
    rin
    crn
    productionCertificate
    productionApiSecret
    companyTradeName
    branchCode
    activityCode
    branchAddress {
      branchId
      country
      governate
      regionCity
      citySubdivision
      plotIdentification
      street
      buildingNumber
      postalCode
      floor
      room
      landmark
      additionalInformation
      __typename
    }
    clientId
    clientSecret
    synchronizeProcess
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateConceptMutationVariables,
  APITypes.UpdateConceptMutation
>;
export const deleteConcept = /* GraphQL */ `mutation DeleteConcept(
  $input: DeleteConceptInput!
  $condition: ModelConceptConditionInput
) {
  deleteConcept(input: $input, condition: $condition) {
    id
    accountID
    name
    hotelCode
    env
    prefix
    type
    rin
    crn
    productionCertificate
    productionApiSecret
    companyTradeName
    branchCode
    activityCode
    branchAddress {
      branchId
      country
      governate
      regionCity
      citySubdivision
      plotIdentification
      street
      buildingNumber
      postalCode
      floor
      room
      landmark
      additionalInformation
      __typename
    }
    clientId
    clientSecret
    synchronizeProcess
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteConceptMutationVariables,
  APITypes.DeleteConceptMutation
>;
export const createCustomer = /* GraphQL */ `mutation CreateCustomer(
  $input: CreateCustomerInput!
  $condition: ModelCustomerConditionInput
) {
  createCustomer(input: $input, condition: $condition) {
    id
    accountID
    ArNumber
    type
    name
    registrationNumber
    address {
      branchId
      country
      governate
      regionCity
      citySubdivision
      plotIdentification
      street
      buildingNumber
      postalCode
      floor
      room
      landmark
      additionalInformation
      __typename
    }
    mobileNumber
    paymentNumber
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCustomerMutationVariables,
  APITypes.CreateCustomerMutation
>;
export const updateCustomer = /* GraphQL */ `mutation UpdateCustomer(
  $input: UpdateCustomerInput!
  $condition: ModelCustomerConditionInput
) {
  updateCustomer(input: $input, condition: $condition) {
    id
    accountID
    ArNumber
    type
    name
    registrationNumber
    address {
      branchId
      country
      governate
      regionCity
      citySubdivision
      plotIdentification
      street
      buildingNumber
      postalCode
      floor
      room
      landmark
      additionalInformation
      __typename
    }
    mobileNumber
    paymentNumber
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCustomerMutationVariables,
  APITypes.UpdateCustomerMutation
>;
export const deleteCustomer = /* GraphQL */ `mutation DeleteCustomer(
  $input: DeleteCustomerInput!
  $condition: ModelCustomerConditionInput
) {
  deleteCustomer(input: $input, condition: $condition) {
    id
    accountID
    ArNumber
    type
    name
    registrationNumber
    address {
      branchId
      country
      governate
      regionCity
      citySubdivision
      plotIdentification
      street
      buildingNumber
      postalCode
      floor
      room
      landmark
      additionalInformation
      __typename
    }
    mobileNumber
    paymentNumber
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCustomerMutationVariables,
  APITypes.DeleteCustomerMutation
>;
export const createFeature = /* GraphQL */ `mutation CreateFeature(
  $input: CreateFeatureInput!
  $condition: ModelFeatureConditionInput
) {
  createFeature(input: $input, condition: $condition) {
    id
    name
    icon
    slug
    precedence
    parent
    private
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFeatureMutationVariables,
  APITypes.CreateFeatureMutation
>;
export const updateFeature = /* GraphQL */ `mutation UpdateFeature(
  $input: UpdateFeatureInput!
  $condition: ModelFeatureConditionInput
) {
  updateFeature(input: $input, condition: $condition) {
    id
    name
    icon
    slug
    precedence
    parent
    private
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFeatureMutationVariables,
  APITypes.UpdateFeatureMutation
>;
export const deleteFeature = /* GraphQL */ `mutation DeleteFeature(
  $input: DeleteFeatureInput!
  $condition: ModelFeatureConditionInput
) {
  deleteFeature(input: $input, condition: $condition) {
    id
    name
    icon
    slug
    precedence
    parent
    private
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFeatureMutationVariables,
  APITypes.DeleteFeatureMutation
>;
export const createAdminRole = /* GraphQL */ `mutation CreateAdminRole(
  $input: CreateAdminRoleInput!
  $condition: ModelAdminRoleConditionInput
) {
  createAdminRole(input: $input, condition: $condition) {
    id
    name
    description
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAdminRoleMutationVariables,
  APITypes.CreateAdminRoleMutation
>;
export const updateAdminRole = /* GraphQL */ `mutation UpdateAdminRole(
  $input: UpdateAdminRoleInput!
  $condition: ModelAdminRoleConditionInput
) {
  updateAdminRole(input: $input, condition: $condition) {
    id
    name
    description
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAdminRoleMutationVariables,
  APITypes.UpdateAdminRoleMutation
>;
export const deleteAdminRole = /* GraphQL */ `mutation DeleteAdminRole(
  $input: DeleteAdminRoleInput!
  $condition: ModelAdminRoleConditionInput
) {
  deleteAdminRole(input: $input, condition: $condition) {
    id
    name
    description
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAdminRoleMutationVariables,
  APITypes.DeleteAdminRoleMutation
>;
export const createAdminGroup = /* GraphQL */ `mutation CreateAdminGroup(
  $input: CreateAdminGroupInput!
  $condition: ModelAdminGroupConditionInput
) {
  createAdminGroup(input: $input, condition: $condition) {
    id
    name
    description
    roles
    users
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAdminGroupMutationVariables,
  APITypes.CreateAdminGroupMutation
>;
export const updateAdminGroup = /* GraphQL */ `mutation UpdateAdminGroup(
  $input: UpdateAdminGroupInput!
  $condition: ModelAdminGroupConditionInput
) {
  updateAdminGroup(input: $input, condition: $condition) {
    id
    name
    description
    roles
    users
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAdminGroupMutationVariables,
  APITypes.UpdateAdminGroupMutation
>;
export const deleteAdminGroup = /* GraphQL */ `mutation DeleteAdminGroup(
  $input: DeleteAdminGroupInput!
  $condition: ModelAdminGroupConditionInput
) {
  deleteAdminGroup(input: $input, condition: $condition) {
    id
    name
    description
    roles
    users
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAdminGroupMutationVariables,
  APITypes.DeleteAdminGroupMutation
>;
export const createUserConcepts = /* GraphQL */ `mutation CreateUserConcepts(
  $input: CreateUserConceptsInput!
  $condition: ModelUserConceptsConditionInput
) {
  createUserConcepts(input: $input, condition: $condition) {
    id
    defaultConcept
    concepts
    conceptsRoles
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserConceptsMutationVariables,
  APITypes.CreateUserConceptsMutation
>;
export const updateUserConcepts = /* GraphQL */ `mutation UpdateUserConcepts(
  $input: UpdateUserConceptsInput!
  $condition: ModelUserConceptsConditionInput
) {
  updateUserConcepts(input: $input, condition: $condition) {
    id
    defaultConcept
    concepts
    conceptsRoles
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserConceptsMutationVariables,
  APITypes.UpdateUserConceptsMutation
>;
export const deleteUserConcepts = /* GraphQL */ `mutation DeleteUserConcepts(
  $input: DeleteUserConceptsInput!
  $condition: ModelUserConceptsConditionInput
) {
  deleteUserConcepts(input: $input, condition: $condition) {
    id
    defaultConcept
    concepts
    conceptsRoles
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserConceptsMutationVariables,
  APITypes.DeleteUserConceptsMutation
>;
export const createLanguage = /* GraphQL */ `mutation CreateLanguage(
  $input: CreateLanguageInput!
  $condition: ModelLanguageConditionInput
) {
  createLanguage(input: $input, condition: $condition) {
    id
    name
    code
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateLanguageMutationVariables,
  APITypes.CreateLanguageMutation
>;
export const updateLanguage = /* GraphQL */ `mutation UpdateLanguage(
  $input: UpdateLanguageInput!
  $condition: ModelLanguageConditionInput
) {
  updateLanguage(input: $input, condition: $condition) {
    id
    name
    code
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateLanguageMutationVariables,
  APITypes.UpdateLanguageMutation
>;
export const deleteLanguage = /* GraphQL */ `mutation DeleteLanguage(
  $input: DeleteLanguageInput!
  $condition: ModelLanguageConditionInput
) {
  deleteLanguage(input: $input, condition: $condition) {
    id
    name
    code
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteLanguageMutationVariables,
  APITypes.DeleteLanguageMutation
>;
export const createTimeline = /* GraphQL */ `mutation CreateTimeline(
  $input: CreateTimelineInput!
  $condition: ModelTimelineConditionInput
) {
  createTimeline(input: $input, condition: $condition) {
    id
    actionName
    oldStatus
    newStatus
    userId
    transactionId
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTimelineMutationVariables,
  APITypes.CreateTimelineMutation
>;
export const updateTimeline = /* GraphQL */ `mutation UpdateTimeline(
  $input: UpdateTimelineInput!
  $condition: ModelTimelineConditionInput
) {
  updateTimeline(input: $input, condition: $condition) {
    id
    actionName
    oldStatus
    newStatus
    userId
    transactionId
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTimelineMutationVariables,
  APITypes.UpdateTimelineMutation
>;
export const deleteTimeline = /* GraphQL */ `mutation DeleteTimeline(
  $input: DeleteTimelineInput!
  $condition: ModelTimelineConditionInput
) {
  deleteTimeline(input: $input, condition: $condition) {
    id
    actionName
    oldStatus
    newStatus
    userId
    transactionId
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTimelineMutationVariables,
  APITypes.DeleteTimelineMutation
>;
export const createItem = /* GraphQL */ `mutation CreateItem(
  $input: CreateItemInput!
  $condition: ModelItemConditionInput
) {
  createItem(input: $input, condition: $condition) {
    id
    accountID
    internalCode
    itemCode
    description
    type
    unit
    tax1
    tax2
    neglectedItem
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateItemMutationVariables,
  APITypes.CreateItemMutation
>;
export const updateItem = /* GraphQL */ `mutation UpdateItem(
  $input: UpdateItemInput!
  $condition: ModelItemConditionInput
) {
  updateItem(input: $input, condition: $condition) {
    id
    accountID
    internalCode
    itemCode
    description
    type
    unit
    tax1
    tax2
    neglectedItem
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateItemMutationVariables,
  APITypes.UpdateItemMutation
>;
export const deleteItem = /* GraphQL */ `mutation DeleteItem(
  $input: DeleteItemInput!
  $condition: ModelItemConditionInput
) {
  deleteItem(input: $input, condition: $condition) {
    id
    accountID
    internalCode
    itemCode
    description
    type
    unit
    tax1
    tax2
    neglectedItem
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteItemMutationVariables,
  APITypes.DeleteItemMutation
>;
export const createReceipt = /* GraphQL */ `mutation CreateReceipt(
  $input: CreateReceiptInput!
  $condition: ModelReceiptConditionInput
) {
  createReceipt(input: $input, condition: $condition) {
    id
    conceptID
    header {
      dateTimeIssued
      receiptNumber
      uuid
      previousUUID
      currency
      exchangeRate
      grossWeight
      netWeight
      __typename
    }
    documentType {
      receiptType
      typeVersion
      __typename
    }
    seller {
      rin
      companyTradeName
      branchCode
      branchAddress {
        branchId
        country
        governate
        regionCity
        citySubdivision
        plotIdentification
        street
        buildingNumber
        postalCode
        floor
        room
        landmark
        additionalInformation
        __typename
      }
      deviceSerialNumber
      activityCode
      __typename
    }
    buyer {
      type
      id
      name
      mobileNumber
      paymentNumber
      __typename
    }
    itemData {
      internalCode
      description
      itemType
      itemCode
      unitType
      quantity
      unitPrice
      netSale
      totalSale
      total
      commercialDiscountData {
        amount
        description
        __typename
      }
      itemDiscountData {
        amount
        description
        __typename
      }
      valueDifference
      taxableItems {
        taxType
        amount
        subType
        rate
        __typename
      }
      __typename
    }
    totalSales
    totalCommercialDiscount
    totalItemsDiscount
    extraReceiptDiscountData {
      amount
      description
      __typename
    }
    netAmount
    feesAmount
    totalAmount
    taxTotals {
      taxType
      amount
      __typename
    }
    paymentMethod
    uuid
    qrString
    qrImage
    status
    reason
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReceiptMutationVariables,
  APITypes.CreateReceiptMutation
>;
export const updateReceipt = /* GraphQL */ `mutation UpdateReceipt(
  $input: UpdateReceiptInput!
  $condition: ModelReceiptConditionInput
) {
  updateReceipt(input: $input, condition: $condition) {
    id
    conceptID
    header {
      dateTimeIssued
      receiptNumber
      uuid
      previousUUID
      currency
      exchangeRate
      grossWeight
      netWeight
      __typename
    }
    documentType {
      receiptType
      typeVersion
      __typename
    }
    seller {
      rin
      companyTradeName
      branchCode
      branchAddress {
        branchId
        country
        governate
        regionCity
        citySubdivision
        plotIdentification
        street
        buildingNumber
        postalCode
        floor
        room
        landmark
        additionalInformation
        __typename
      }
      deviceSerialNumber
      activityCode
      __typename
    }
    buyer {
      type
      id
      name
      mobileNumber
      paymentNumber
      __typename
    }
    itemData {
      internalCode
      description
      itemType
      itemCode
      unitType
      quantity
      unitPrice
      netSale
      totalSale
      total
      commercialDiscountData {
        amount
        description
        __typename
      }
      itemDiscountData {
        amount
        description
        __typename
      }
      valueDifference
      taxableItems {
        taxType
        amount
        subType
        rate
        __typename
      }
      __typename
    }
    totalSales
    totalCommercialDiscount
    totalItemsDiscount
    extraReceiptDiscountData {
      amount
      description
      __typename
    }
    netAmount
    feesAmount
    totalAmount
    taxTotals {
      taxType
      amount
      __typename
    }
    paymentMethod
    uuid
    qrString
    qrImage
    status
    reason
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReceiptMutationVariables,
  APITypes.UpdateReceiptMutation
>;
export const deleteReceipt = /* GraphQL */ `mutation DeleteReceipt(
  $input: DeleteReceiptInput!
  $condition: ModelReceiptConditionInput
) {
  deleteReceipt(input: $input, condition: $condition) {
    id
    conceptID
    header {
      dateTimeIssued
      receiptNumber
      uuid
      previousUUID
      currency
      exchangeRate
      grossWeight
      netWeight
      __typename
    }
    documentType {
      receiptType
      typeVersion
      __typename
    }
    seller {
      rin
      companyTradeName
      branchCode
      branchAddress {
        branchId
        country
        governate
        regionCity
        citySubdivision
        plotIdentification
        street
        buildingNumber
        postalCode
        floor
        room
        landmark
        additionalInformation
        __typename
      }
      deviceSerialNumber
      activityCode
      __typename
    }
    buyer {
      type
      id
      name
      mobileNumber
      paymentNumber
      __typename
    }
    itemData {
      internalCode
      description
      itemType
      itemCode
      unitType
      quantity
      unitPrice
      netSale
      totalSale
      total
      commercialDiscountData {
        amount
        description
        __typename
      }
      itemDiscountData {
        amount
        description
        __typename
      }
      valueDifference
      taxableItems {
        taxType
        amount
        subType
        rate
        __typename
      }
      __typename
    }
    totalSales
    totalCommercialDiscount
    totalItemsDiscount
    extraReceiptDiscountData {
      amount
      description
      __typename
    }
    netAmount
    feesAmount
    totalAmount
    taxTotals {
      taxType
      amount
      __typename
    }
    paymentMethod
    uuid
    qrString
    qrImage
    status
    reason
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReceiptMutationVariables,
  APITypes.DeleteReceiptMutation
>;
export const createInvoice = /* GraphQL */ `mutation CreateInvoice(
  $input: CreateInvoiceInput!
  $condition: ModelInvoiceConditionInput
) {
  createInvoice(input: $input, condition: $condition) {
    id
    conceptID
    issuer {
      type
      id
      name
      address {
        branchId
        country
        governate
        regionCity
        citySubdivision
        plotIdentification
        street
        buildingNumber
        postalCode
        floor
        room
        landmark
        additionalInformation
        __typename
      }
      __typename
    }
    receiver {
      type
      id
      name
      address {
        branchId
        country
        governate
        regionCity
        citySubdivision
        plotIdentification
        street
        buildingNumber
        postalCode
        floor
        room
        landmark
        additionalInformation
        __typename
      }
      __typename
    }
    documentType
    documentTypeVersion
    dateTimeIssued
    taxpayerActivityCode
    internalID
    invoiceLines {
      description
      itemType
      itemCode
      unitType
      quantity
      internalCode
      salesTotal
      total
      valueDifference
      totalTaxableFees
      netTotal
      itemsDiscount
      unitValue {
        currencySold
        amountEGP
        amountSold
        currencyExchangeRate
        __typename
      }
      taxableItems {
        taxType
        amount
        subType
        rate
        __typename
      }
      __typename
    }
    invoiceNeglectedLines {
      description
      itemType
      itemCode
      unitType
      quantity
      internalCode
      salesTotal
      total
      valueDifference
      totalTaxableFees
      netTotal
      itemsDiscount
      unitValue {
        currencySold
        amountEGP
        amountSold
        currencyExchangeRate
        __typename
      }
      taxableItems {
        taxType
        amount
        subType
        rate
        __typename
      }
      __typename
    }
    totalDiscountAmount
    totalSalesAmount
    netAmount
    taxTotals {
      taxType
      amount
      __typename
    }
    totalAmount
    extraDiscountAmount
    totalItemsDiscountAmount
    uuid
    type
    cancelReason
    qrString
    qrImage
    status
    reason
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateInvoiceMutationVariables,
  APITypes.CreateInvoiceMutation
>;
export const updateInvoice = /* GraphQL */ `mutation UpdateInvoice(
  $input: UpdateInvoiceInput!
  $condition: ModelInvoiceConditionInput
) {
  updateInvoice(input: $input, condition: $condition) {
    id
    conceptID
    issuer {
      type
      id
      name
      address {
        branchId
        country
        governate
        regionCity
        citySubdivision
        plotIdentification
        street
        buildingNumber
        postalCode
        floor
        room
        landmark
        additionalInformation
        __typename
      }
      __typename
    }
    receiver {
      type
      id
      name
      address {
        branchId
        country
        governate
        regionCity
        citySubdivision
        plotIdentification
        street
        buildingNumber
        postalCode
        floor
        room
        landmark
        additionalInformation
        __typename
      }
      __typename
    }
    documentType
    documentTypeVersion
    dateTimeIssued
    taxpayerActivityCode
    internalID
    invoiceLines {
      description
      itemType
      itemCode
      unitType
      quantity
      internalCode
      salesTotal
      total
      valueDifference
      totalTaxableFees
      netTotal
      itemsDiscount
      unitValue {
        currencySold
        amountEGP
        amountSold
        currencyExchangeRate
        __typename
      }
      taxableItems {
        taxType
        amount
        subType
        rate
        __typename
      }
      __typename
    }
    invoiceNeglectedLines {
      description
      itemType
      itemCode
      unitType
      quantity
      internalCode
      salesTotal
      total
      valueDifference
      totalTaxableFees
      netTotal
      itemsDiscount
      unitValue {
        currencySold
        amountEGP
        amountSold
        currencyExchangeRate
        __typename
      }
      taxableItems {
        taxType
        amount
        subType
        rate
        __typename
      }
      __typename
    }
    totalDiscountAmount
    totalSalesAmount
    netAmount
    taxTotals {
      taxType
      amount
      __typename
    }
    totalAmount
    extraDiscountAmount
    totalItemsDiscountAmount
    uuid
    type
    cancelReason
    qrString
    qrImage
    status
    reason
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateInvoiceMutationVariables,
  APITypes.UpdateInvoiceMutation
>;
export const deleteInvoice = /* GraphQL */ `mutation DeleteInvoice(
  $input: DeleteInvoiceInput!
  $condition: ModelInvoiceConditionInput
) {
  deleteInvoice(input: $input, condition: $condition) {
    id
    conceptID
    issuer {
      type
      id
      name
      address {
        branchId
        country
        governate
        regionCity
        citySubdivision
        plotIdentification
        street
        buildingNumber
        postalCode
        floor
        room
        landmark
        additionalInformation
        __typename
      }
      __typename
    }
    receiver {
      type
      id
      name
      address {
        branchId
        country
        governate
        regionCity
        citySubdivision
        plotIdentification
        street
        buildingNumber
        postalCode
        floor
        room
        landmark
        additionalInformation
        __typename
      }
      __typename
    }
    documentType
    documentTypeVersion
    dateTimeIssued
    taxpayerActivityCode
    internalID
    invoiceLines {
      description
      itemType
      itemCode
      unitType
      quantity
      internalCode
      salesTotal
      total
      valueDifference
      totalTaxableFees
      netTotal
      itemsDiscount
      unitValue {
        currencySold
        amountEGP
        amountSold
        currencyExchangeRate
        __typename
      }
      taxableItems {
        taxType
        amount
        subType
        rate
        __typename
      }
      __typename
    }
    invoiceNeglectedLines {
      description
      itemType
      itemCode
      unitType
      quantity
      internalCode
      salesTotal
      total
      valueDifference
      totalTaxableFees
      netTotal
      itemsDiscount
      unitValue {
        currencySold
        amountEGP
        amountSold
        currencyExchangeRate
        __typename
      }
      taxableItems {
        taxType
        amount
        subType
        rate
        __typename
      }
      __typename
    }
    totalDiscountAmount
    totalSalesAmount
    netAmount
    taxTotals {
      taxType
      amount
      __typename
    }
    totalAmount
    extraDiscountAmount
    totalItemsDiscountAmount
    uuid
    type
    cancelReason
    qrString
    qrImage
    status
    reason
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteInvoiceMutationVariables,
  APITypes.DeleteInvoiceMutation
>;
export const createZatcaInvoice = /* GraphQL */ `mutation CreateZatcaInvoice(
  $input: CreateZatcaInvoiceInput!
  $condition: ModelZatcaInvoiceConditionInput
) {
  createZatcaInvoice(input: $input, condition: $condition) {
    id
    conceptID
    invoiceCounterNumber
    invoiceSerialNumber
    invoiceHash
    isUsed
    pcsid
    billNumber
    invoiceRefrence
    folioNumber
    customerID
    customer {
      id
      accountID
      ArNumber
      type
      name
      registrationNumber
      address {
        branchId
        country
        governate
        regionCity
        citySubdivision
        plotIdentification
        street
        buildingNumber
        postalCode
        floor
        room
        landmark
        additionalInformation
        __typename
      }
      mobileNumber
      paymentNumber
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      __typename
    }
    documentType
    type
    status
    dateTimeIssued
    invoiceLines {
      id
      name
      quantity
      taxExclusivePrice
      vatPercent
      discounts {
        amount
        description
        __typename
      }
      VatExemptionReason
      priceAmount
      taxAmount
      roundingAmount
      __typename
    }
    taxExclusiveAmount
    taxInclusiveAmount
    allowanceTotalAmount
    prepaidAmount
    payableAmount
    xmlDocumentEncoded
    uuid
    failureReason
    qrString
    qrImage
    infoMessages
    warningMessages
    errorMessages
    isCancelled
    clearanceStatus
    reportingStatus
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateZatcaInvoiceMutationVariables,
  APITypes.CreateZatcaInvoiceMutation
>;
export const updateZatcaInvoice = /* GraphQL */ `mutation UpdateZatcaInvoice(
  $input: UpdateZatcaInvoiceInput!
  $condition: ModelZatcaInvoiceConditionInput
) {
  updateZatcaInvoice(input: $input, condition: $condition) {
    id
    conceptID
    invoiceCounterNumber
    invoiceSerialNumber
    invoiceHash
    isUsed
    pcsid
    billNumber
    invoiceRefrence
    folioNumber
    customerID
    customer {
      id
      accountID
      ArNumber
      type
      name
      registrationNumber
      address {
        branchId
        country
        governate
        regionCity
        citySubdivision
        plotIdentification
        street
        buildingNumber
        postalCode
        floor
        room
        landmark
        additionalInformation
        __typename
      }
      mobileNumber
      paymentNumber
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      __typename
    }
    documentType
    type
    status
    dateTimeIssued
    invoiceLines {
      id
      name
      quantity
      taxExclusivePrice
      vatPercent
      discounts {
        amount
        description
        __typename
      }
      VatExemptionReason
      priceAmount
      taxAmount
      roundingAmount
      __typename
    }
    taxExclusiveAmount
    taxInclusiveAmount
    allowanceTotalAmount
    prepaidAmount
    payableAmount
    xmlDocumentEncoded
    uuid
    failureReason
    qrString
    qrImage
    infoMessages
    warningMessages
    errorMessages
    isCancelled
    clearanceStatus
    reportingStatus
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateZatcaInvoiceMutationVariables,
  APITypes.UpdateZatcaInvoiceMutation
>;
export const deleteZatcaInvoice = /* GraphQL */ `mutation DeleteZatcaInvoice(
  $input: DeleteZatcaInvoiceInput!
  $condition: ModelZatcaInvoiceConditionInput
) {
  deleteZatcaInvoice(input: $input, condition: $condition) {
    id
    conceptID
    invoiceCounterNumber
    invoiceSerialNumber
    invoiceHash
    isUsed
    pcsid
    billNumber
    invoiceRefrence
    folioNumber
    customerID
    customer {
      id
      accountID
      ArNumber
      type
      name
      registrationNumber
      address {
        branchId
        country
        governate
        regionCity
        citySubdivision
        plotIdentification
        street
        buildingNumber
        postalCode
        floor
        room
        landmark
        additionalInformation
        __typename
      }
      mobileNumber
      paymentNumber
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      __typename
    }
    documentType
    type
    status
    dateTimeIssued
    invoiceLines {
      id
      name
      quantity
      taxExclusivePrice
      vatPercent
      discounts {
        amount
        description
        __typename
      }
      VatExemptionReason
      priceAmount
      taxAmount
      roundingAmount
      __typename
    }
    taxExclusiveAmount
    taxInclusiveAmount
    allowanceTotalAmount
    prepaidAmount
    payableAmount
    xmlDocumentEncoded
    uuid
    failureReason
    qrString
    qrImage
    infoMessages
    warningMessages
    errorMessages
    isCancelled
    clearanceStatus
    reportingStatus
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteZatcaInvoiceMutationVariables,
  APITypes.DeleteZatcaInvoiceMutation
>;
export const createInvoker = /* GraphQL */ `mutation CreateInvoker(
  $input: CreateInvokerInput!
  $condition: ModelInvokerConditionInput
) {
  createInvoker(input: $input, condition: $condition) {
    id
    conceptID
    userName
    password
    operations
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateInvokerMutationVariables,
  APITypes.CreateInvokerMutation
>;
export const updateInvoker = /* GraphQL */ `mutation UpdateInvoker(
  $input: UpdateInvokerInput!
  $condition: ModelInvokerConditionInput
) {
  updateInvoker(input: $input, condition: $condition) {
    id
    conceptID
    userName
    password
    operations
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateInvokerMutationVariables,
  APITypes.UpdateInvokerMutation
>;
export const deleteInvoker = /* GraphQL */ `mutation DeleteInvoker(
  $input: DeleteInvokerInput!
  $condition: ModelInvokerConditionInput
) {
  deleteInvoker(input: $input, condition: $condition) {
    id
    conceptID
    userName
    password
    operations
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteInvokerMutationVariables,
  APITypes.DeleteInvokerMutation
>;
export const createZatcaInvoicesSequence = /* GraphQL */ `mutation CreateZatcaInvoicesSequence(
  $input: CreateZatcaInvoicesSequenceInput!
  $condition: ModelZatcaInvoicesSequenceConditionInput
) {
  createZatcaInvoicesSequence(input: $input, condition: $condition) {
    id
    conceptID
    invoiceCounterNumber
    invoiceHash
    isUsed
    pcsid
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateZatcaInvoicesSequenceMutationVariables,
  APITypes.CreateZatcaInvoicesSequenceMutation
>;
export const updateZatcaInvoicesSequence = /* GraphQL */ `mutation UpdateZatcaInvoicesSequence(
  $input: UpdateZatcaInvoicesSequenceInput!
  $condition: ModelZatcaInvoicesSequenceConditionInput
) {
  updateZatcaInvoicesSequence(input: $input, condition: $condition) {
    id
    conceptID
    invoiceCounterNumber
    invoiceHash
    isUsed
    pcsid
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateZatcaInvoicesSequenceMutationVariables,
  APITypes.UpdateZatcaInvoicesSequenceMutation
>;
export const deleteZatcaInvoicesSequence = /* GraphQL */ `mutation DeleteZatcaInvoicesSequence(
  $input: DeleteZatcaInvoicesSequenceInput!
  $condition: ModelZatcaInvoicesSequenceConditionInput
) {
  deleteZatcaInvoicesSequence(input: $input, condition: $condition) {
    id
    conceptID
    invoiceCounterNumber
    invoiceHash
    isUsed
    pcsid
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteZatcaInvoicesSequenceMutationVariables,
  APITypes.DeleteZatcaInvoicesSequenceMutation
>;
