// actions
const SET = "admins/SET";
const SET_SEARCH_TEXT = "admins/SET_SEARCH_TEXT";
const SET_SELECTED = "admins/SET_SELECTED";

const DEFAULT_STATE = {
  listing: [],
  searchText: "",
  selected: "",
  nextToken: null,
  nextNextToken: undefined,
  previousTokens: [],
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case SET_SEARCH_TEXT:
      return Object.assign({}, state, { searchText: action.searchText });
    case SET_SELECTED:
      return Object.assign({}, state, { selected: action.id });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setListing = (listing: any[]) => ({ listing, type: SET });

export const setSearchText = (searchText: string) => ({
  searchText,
  type: SET_SEARCH_TEXT,
});

export const setSelected = (id: string) => ({ id, type: SET_SELECTED });
