import { UpdateTimelineInput } from "./../API";
import {
  createTimeline,
  deleteTimeline,
  updateTimeline,
} from "./../graphql/mutations";
import { API } from "aws-amplify";
import { GRAPHQL_AUTH_MODE, GraphQLQuery } from "@aws-amplify/api";
import { useDispatch, useSelector } from "react-redux";
import {
  setListing,
  setSearchText,
  setSelected,
  setNextToken,
} from "../store/ducks/timeline";
import { HeadCell } from "../models/dataTable";
import { TimelineQueryVariables } from "../models/app";
import useApp from "./useApp";
import { getTimeline, listTimelines } from "../graphql/queries";
import { CreateTimelineInput, Timeline } from "../models/GQL_API";

const useTimeline = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { session, showConfirm, showError } = useApp();

  const listing = useSelector(
    (state: any) => state[`${listingName}`]["listing"]
  );
  const searchText = useSelector(
    (state: any) => state[`${listingName}`]["searchText"]
  );
  const selected = useSelector(
    (state: any) => state[`${listingName}`]["selected"]
  );
  const nextToken = useSelector(
    (state: any) => state[`${listingName}`]["nextToken"]
  );

  async function fetch(props: TimelineQueryVariables) {
    const { startIndex, limit, userId, transactionId } = props;

    try {
      const filter: any = {
        deleted: { eq: "0" },
      };

      if (searchText) filter.name = { contains: searchText.toLowerCase() };
      if (userId) filter.userId = { eq: userId };
      if (transactionId) filter.transactionId = { eq: transactionId };

      const timelineList: any = await API.graphql<Timeline>({
        query: listTimelines,
        variables: { filter, limit, nextToken: nextToken },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      const currentNextToken = timelineList.data.listTimelines.nextToken;
      const listing = timelineList.data.listTimelines.items;
      dispatch(setNextToken(currentNextToken));

      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message);
    }
  }

  async function get(id: string) {
    try {
      let single: Timeline | undefined;
      if (listing.length !== 0) {
        single = listing.find((resource: any) => resource.id === id);
      }

      if (single === undefined) {
        const listing: any = await API.graphql<Timeline>({
          query: getTimeline,
          variables: { id },
          authMode: true
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
        single = listing.data.getTimeline;
      }

      return single;
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  }

  async function create(data: any) {
    try {
      const createInput: CreateTimelineInput = {
        actionName: data.actionName ? data.actionName : "",
        oldStatus: data.oldStatus ? data.oldStatus : "",
        newStatus: data.newStatus ? data.newStatus : "",

        userId: data.userId ? data.userId : "",
        transactionId: data.transactionId ? data.transactionId : "",

        deleted: "0",
        createdAt: new Date().getTime().toString(),
        createdByID: session.sub,
        createdByName: session.name,
      };

      const timeline: any = await API.graphql<GraphQLQuery<Timeline>>({
        query: createTimeline,
        variables: { input: createInput },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      const createdTimeline = timeline.data.createTimeline;
      dispatch(setListing([createdTimeline, ...listing]));
    } catch (err) {
      showError(err);
    }
  }

  async function update(id: string, data: any) {
    try {
      const original = await get(id);
      if (!original) {
        showError(`Can not find this ${singleName} entry.`);
        return;
      }

      const updateInput: UpdateTimelineInput = {
        id: original.id,
        actionName: data.actionName ? data.actionName : original!.actionName,
        oldStatus: data.oldStatus ? data.oldStatus : original!.oldStatus,
        newStatus: data.newStatus ? data.newStatus : original!.newStatus,
        userId: data.userId ? data.userId : original!.userId,
        // transactionId: data.transactionId
        //   ? data.transactionId
        //   : original!.transactionId,
        createdAt: data.createdAt ? data.createdAt : original!.createdAt,
      };

      await API.graphql<Timeline>({
        query: updateTimeline,
        variables: { input: updateInput },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      showConfirm(`${singleName} has been updated successfully`);
      return update;
    } catch (err) {
      showError(err);
    }
  }

  async function trash(id: string) {
    try {
      const original = await get(id);

      if (original) {
        const updateInput: UpdateTimelineInput = {
          id: original.id,
          deleted: "1",
        };

        await API.graphql<Timeline>({
          query: updateTimeline,
          variables: { input: updateInput },
          authMode: true
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
      }

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  }

  async function bulkTrash(ids: any) {
    for (let id of ids) {
      try {
        await trash(id);
      } catch (err: Error | any) {
        showError(err);
      }
    }

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(id: any) {
    try {
      await API.graphql<Timeline>({
        query: deleteTimeline,
        variables: { id: id },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      dispatch(
        setListing(
          listing.filter((resource: any) => resource.id !== id)
        )
      );

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  }

  async function exportAll(params: TimelineQueryVariables) {
    try {
      let exportedData = [];

      const data = await fetch(params);

      for (let user of data!) {
        let row: any = { ...user };
        exportedData.push(row);
      }

      return exportedData;
    } catch (err) {
      showError(err);
    }
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "actionName",
      numeric: false,
      disablePadding: false,
      label: "Action Name",
    },
    {
      id: "oldStatus",
      numeric: false,
      disablePadding: false,
      label: "Old Status",
    },
    {
      id: "newStatus",
      numeric: false,
      disablePadding: false,
      label: "New Status",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["actionName", "oldStatus", "newStatus"];

  const options: any[] = [];

  if (listing)
    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }

  const api: any = {};

  api[`${listingName}Listing`] = listing;
  api[`${listingName}Options`] = options;
  api[`${listingName}SearchText`] = searchText;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Selected`] = selected;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}Export`] = exportAll;
  api[`${listingName}ChangeListing`] = (listing: Timeline[]) =>
    dispatch(setListing(listing));
  api[`${listingName}Search`] = (searchText: string) =>
    dispatch(setSearchText(searchText));
  api[`${listingName}ChangeListing`] = (listing: Timeline[]) =>
    dispatch(setListing(listing));
  api[`${listingName}Select`] = (conceptID: string) =>
    dispatch(setSelected(conceptID));

  return api;
};

export default useTimeline;
