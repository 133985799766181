import axios from "axios";
import { getReceipt } from "./../graphql/queries";
import {
  ModelReceiptFilterInput,
  UpdateReceiptInput,
} from "./../models/GQL_API";
import { deleteReceipt, updateReceipt } from "./../graphql/mutations";
import { API } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { useDispatch, useSelector } from "react-redux";
import {
  setListing,
  setListingReceiptsStats,
  setSearchText,
  setSelected,
  setNextToken,
  changeLimit,
  setPreviousToken,
  nextAction,
} from "../store/ducks/receipt";
import { HeadCell } from "../models/dataTable";
import useApp from "./useApp";
import useTimeline from "./useTimeline";
import { listReceipts } from "../graphql/queries";
import { Receipt } from "../API";
import { TransactionListingVariables, TransactionStats } from "../models/app";
import { INVOICE_STATUS, TimelineActions } from "../constants/enums";
import { EG_OPERATIONS, EG_SEND_INVOICE } from "../constants/endPoints";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();
  const { timelinesCreate } = useTimeline("timelines", "timeline");

  const listing = useSelector(
    (state: any) => state[`${listingName}`]["listing"]
  );
  const searchText = useSelector(
    (state: any) => state[`${listingName}`]["searchText"]
  );
  const selected = useSelector(
    (state: any) => state[`${listingName}`]["selected"]
  );

  const storedLimit = useSelector(
    (state: any) => state[`${listingName}`]["limit"]
  );

  const nextToken = useSelector(
    (state: any) => state[`${listingName}`]["nextToken"]
  );
  const previousTokens = useSelector(
    (state: any) => state[`${listingName}`]["previousTokens"]
  );

  async function fetch(props: TransactionListingVariables) {
    try {
      const {
        startIndex,
        limit,
        moveForward,
        conceptID,
        searchText,
        fromDate,
        toDate,
        conceptsSelectedFilters,
      } = props;

      let requestLimit = storedLimit;
      let requestToken = nextToken;
      let requestPreviousTokens = previousTokens;

      // Clear pagination data
      if (limit !== storedLimit || startIndex === 0) {
        requestLimit = limit;
        requestToken = null;
        requestPreviousTokens = [];

        dispatch(changeLimit(limit));
      }

      // Get token from previous tokens
      if (!moveForward) {
        const updatedPreviousTokens = [...requestPreviousTokens];
        updatedPreviousTokens.pop();

        requestToken =
          updatedPreviousTokens.length >= 2
            ? updatedPreviousTokens[updatedPreviousTokens.length - 2]
            : null;
        dispatch(
          setNextToken(updatedPreviousTokens[updatedPreviousTokens.length - 1])
        );
        dispatch(setPreviousToken(updatedPreviousTokens));
      }

      const filter: ModelReceiptFilterInput = {
        deleted: { eq: "0" },
      };

      // Search Filter
      if (searchText) {
        // filter.or = [];
        // filter.or.push({ header: { contains: searchText } });
      }

      // Concepts Filter
      if (conceptsSelectedFilters && conceptsSelectedFilters.length > 0) {
        filter.or = [];
        for (let conceptsSelected of conceptsSelectedFilters) {
          filter.or.push({ conceptID: { eq: conceptsSelected.id } });
        }
      } else {
        if (conceptID) filter.conceptID = { eq: conceptID };
      }

      // Date Filter
      if (fromDate && toDate) {
        filter.and = [];

        filter.and.push({
          createdAt: { ge: new Date(fromDate).getTime().toString() },
        });
        filter.and.push({
          createdAt: {
            lt: new Date(toDate).getTime().toString(),
          },
        });
      }

      const receiptList: any = await API.graphql<Receipt>({
        query: listReceipts,
        variables: { filter, limit: requestLimit, nextToken: requestToken },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      if (moveForward) {
        const currentNextToken = receiptList.data.listReceipts.nextToken;
        dispatch(nextAction(currentNextToken, requestPreviousTokens));
      }

      const listing = receiptList.data.listReceipts.items;

      return listing;
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  }

  /*
   * Fetch all receipt within selected range
   * Filter then using receipt status (Valid, Invalid, Rejected, Cancelled)
   * Return Hash map contains (Status, # of receipts, total, percentage)
   */
  async function fetchStats(props: TransactionListingVariables) {
    try {
      const { limit, conceptID, fromDate, toDate, conceptsSelectedFilters } =
        props;

      const filter: ModelReceiptFilterInput = {
        deleted: { eq: "0" },
      };

      // Concepts Filter
      if (conceptsSelectedFilters && conceptsSelectedFilters.length > 0) {
        filter.or = [];
        for (let conceptsSelected of conceptsSelectedFilters) {
          filter.or.push({ conceptID: { eq: conceptsSelected.id } });
        }
      } else {
        if (conceptID) filter.conceptID = { eq: conceptID };
      }

      // Date Filter
      if (fromDate && toDate) {
        filter.and = [];

        filter.and.push({
          createdAt: { ge: new Date(fromDate).getTime().toString() },
        });
        filter.and.push({
          createdAt: {
            lt: new Date(toDate).getTime().toString(),
          },
        });
      }

      const receiptList: any = await API.graphql<Receipt>({
        query: listReceipts,
        variables: { filter, limit },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      const listing = receiptList.data.listReceipts.items;
      // Calculate stats
      const stats = await receiptsStats(listing);

      return stats;
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  }

  async function receiptsStats(listing: Receipt[]) {
    try {
      let validCounter: TransactionStats = {
        id: 1,
        status: INVOICE_STATUS.VALID,
        counter: 0,
        total: 0,
        percentage: 0,
        fill: "#84c529",
      };

      let rejectedCounter: TransactionStats = {
        id: 2,
        status: INVOICE_STATUS.REJECTED,
        counter: 0,
        total: 0,
        percentage: 0,
        fill: "#03a9f4",
      };

      let cancelledCounter: TransactionStats = {
        id: 3,
        status: INVOICE_STATUS.CANCELLED,
        counter: 0,
        total: 0,
        percentage: 0,
        fill: "#ff6f00",
      };

      let invalidCounter: TransactionStats = {
        id: 4,
        status: INVOICE_STATUS.INVALID,
        counter: 0,
        total: 0,
        percentage: 0,
        fill: "#fc2d42",
      };

      let totalCounter: TransactionStats = {
        id: 5,
        status: "total",
        counter: 0,
        total: 0,
        percentage: 100,
        fill: "",
      };

      let totalReceipts = 0;
      let totalAmount = 0;

      listing.forEach(({ status, totalAmount }) => {
        switch (status) {
          case INVOICE_STATUS.VALID:
            validCounter.counter++;
            validCounter.total += totalAmount;
            break;
          case INVOICE_STATUS.REJECTED:
            rejectedCounter.counter++;
            rejectedCounter.total += totalAmount;
            break;
          case INVOICE_STATUS.CANCELLED:
            cancelledCounter.counter++;
            cancelledCounter.total += totalAmount;
            break;
          case INVOICE_STATUS.INVALID:
            invalidCounter.counter++;
            invalidCounter.total += totalAmount;
            break;
          default:
            break;
        }
      });

      totalReceipts =
        validCounter.counter +
        invalidCounter.counter +
        rejectedCounter.counter +
        cancelledCounter.counter;
      totalAmount =
        validCounter.total +
        invalidCounter.total +
        rejectedCounter.total +
        cancelledCounter.total;

      totalCounter.counter = totalReceipts;
      totalCounter.total = totalAmount;

      if (totalReceipts > 0) {
        validCounter.percentage = (validCounter.counter / totalReceipts) * 100;
        invalidCounter.percentage =
          (invalidCounter.counter / totalReceipts) * 100;
        rejectedCounter.percentage =
          (rejectedCounter.counter / totalReceipts) * 100;
        cancelledCounter.percentage =
          (cancelledCounter.counter / totalReceipts) * 100;
      }

      const receiptsStats: TransactionStats[] = [
        validCounter,
        rejectedCounter,
        cancelledCounter,
        invalidCounter,
        totalCounter,
      ];

      dispatch(setListingReceiptsStats(receiptsStats));
      return receiptsStats;
    } catch (err: Error | any) {
      throw err;
    }
  }

  async function get(id: string) {
    try {
      let single: Receipt | undefined;
      if (listing.length !== 0) {
        single = listing.find((resource: any) => resource.id === id);
      }

      if (single === undefined) {
        const listing: any = await API.graphql<Receipt>({
          query: getReceipt,
          variables: { id },
          authMode: true
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
        single = listing.data.getReceipt;
      }

      return single;
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  }

  /*
   * Check if there is any change in receipt line
   * Recalculate totals
   * Create timeline
   * Update this receipt
   */
  async function update(id: string, data: any, editedLine: any) {
    try {
      const original = await get(id);

      if (original) {
        if (original.itemData.length !== data.itemData.length) {
          const timelineParams: any = {
            actionName: TimelineActions.Delete_TRX_LINE,
            oldStatus: editedLine.description,
            newStatus: "",
            transactionId: id,
          };

          await timelinesCreate(timelineParams);
        }

        const updateInput: UpdateReceiptInput = {
          id: original.id,
          itemData: data.itemData ? data.itemData : original!.itemData,
        };

        const updatedReceipt: any = await API.graphql<Receipt>({
          query: updateReceipt,
          variables: { input: updateInput },
          authMode: true
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
        dispatch(setSelected(updatedReceipt));
      }

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  }

  async function trash(id: string) {
    try {
      const original = await get(id);

      if (original) {
        const updateInput: UpdateReceiptInput = {
          id: original.id,
          deleted: "1",
        };

        await API.graphql<Receipt>({
          query: updateReceipt,
          variables: { input: updateInput },
          authMode: true
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
      }

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  }

  async function bulkTrash(resourceIds: any) {
    for (let id of resourceIds) {
      try {
        await trash(id);
      } catch (err: Error | any) {
        showError(err);
      }
    }

    dispatch(
      setListing(
        listing.filter((resource: any) => !resourceIds.has(resource.id))
      )
    );

    showConfirm(
      `${resourceIds.size} ${listingName} items has been moved to trash`
    );
  }

  async function remove(id: any) {
    try {
      await API.graphql<Receipt>({
        query: deleteReceipt,
        variables: { id: id },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      dispatch(
        setListing(
          listing.filter((resource: any) => resource.id !== id)
        )
      );

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  }

  /*
   * Resend receipt to Egyptian Gateway/Cancel/Reject
   * Update receipt
   * Add timeline
   */
  async function resubmitReceipt(receipt: any, subOperation: string) {
    try {
      const operation = EG_OPERATIONS.RECEIPT;
      const url = EG_SEND_INVOICE;

      const response = await axios.post(
        `${url}?operation=${operation}&subOperation=${subOperation}`,
        {
          data: receipt,
        }
      );

      console.log({ response });
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "conceptID",
      numeric: false,
      disablePadding: false,
      label: "Concept",
    },
    {
      id: "receiptNumber",
      numeric: false,
      disablePadding: false,
      label: "Receipt Number",
    },
    {
      id: "seller",
      numeric: false,
      disablePadding: false,
      label: "Seller",
    },
    {
      id: "buyer",
      numeric: false,
      disablePadding: false,
      label: "Buyer",
    },
    {
      id: "totalAmount",
      numeric: false,
      disablePadding: false,
      label: "Total Amount",
    },
    {
      id: "currency",
      numeric: false,
      disablePadding: false,
      label: "Currency",
    },
    {
      id: "paymentMethod",
      numeric: false,
      disablePadding: false,
      label: "Payment Method",
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Status",
    },
    {
      id: "reason",
      numeric: false,
      disablePadding: false,
      label: "Reason",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: false,
      label: "Actions",
    },
  ];

  const dataCells: readonly string[] = [
    "conceptID",
    "receiptNumber",
    "Seller",
    "Buyer",
    "totalAmount",
    "currency",
    "paymentMethod",
    "status",
    "reason",
  ];

  const lineHeadCells: readonly HeadCell[] = [
    {
      id: "internalCode",
      numeric: false,
      disablePadding: false,
      label: "Internal Code",
    },
    {
      id: "description",
      numeric: false,
      disablePadding: false,
      label: "Description",
    },
    {
      id: "itemCode",
      numeric: false,
      disablePadding: false,
      label: "Item Code",
    },
    {
      id: "itemType",
      numeric: false,
      disablePadding: false,
      label: "Item Type",
    },
    {
      id: "unitPrice",
      numeric: false,
      disablePadding: false,
      label: "Unit Price",
    },
    {
      id: "quantity",
      numeric: false,
      disablePadding: false,
      label: "Quantity",
    },
    {
      id: "netSale",
      numeric: false,
      disablePadding: false,
      label: "Net Sale",
    },
    {
      id: "totalSale",
      numeric: false,
      disablePadding: false,
      label: "Total Sale",
    },
    {
      id: "total",
      numeric: false,
      disablePadding: false,
      label: "Total",
    },
    {
      id: "unitType",
      numeric: false,
      disablePadding: false,
      label: "Unit Type",
    },
    {
      id: "valueDifference",
      numeric: false,
      disablePadding: false,
      label: "Value Difference",
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: false,
      label: "Actions",
    },
  ];

  const options: any[] = [];

  if (listing)
    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }

  const api: any = {};

  api[`${listingName}Listing`] = listing;
  api[`${listingName}Options`] = options;
  api[`${listingName}SearchText`] = searchText;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}LineHeadCells`] = lineHeadCells;
  api[`${listingName}Selected`] = selected;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchStats`] = fetchStats;
  api[`${listingName}Get`] = get;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}ResubmitReceipt`] = resubmitReceipt;

  api[`${listingName}ChangeListing`] = (listing: Receipt[]) =>
    dispatch(setListing(listing));
  api[`${listingName}Search`] = (searchText: string) =>
    dispatch(setSearchText(searchText));
  api[`${listingName}Select`] = (conceptID: string) =>
    dispatch(setSelected(conceptID));

  return api;
};

export default useResource;
