/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getAccount = /* GraphQL */ `query GetAccount($id: ID!) {
  getAccount(id: $id) {
    id
    logo
    domain
    siteTitle
    guestsCount
    tagline
    description
    siteAddress
    defaultLanguage
    languages
    features
    status
    socialLinks
    deleted
    createdAt
    createdByID
    createdByName
    callCenterEmployeesIds
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAccountQueryVariables,
  APITypes.GetAccountQuery
>;
export const listAccounts = /* GraphQL */ `query ListAccounts(
  $filter: ModelAccountFilterInput
  $limit: Int
  $nextToken: String
) {
  listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      logo
      domain
      siteTitle
      guestsCount
      tagline
      description
      siteAddress
      defaultLanguage
      languages
      features
      status
      socialLinks
      deleted
      createdAt
      createdByID
      createdByName
      callCenterEmployeesIds
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAccountsQueryVariables,
  APITypes.ListAccountsQuery
>;
export const getConcept = /* GraphQL */ `query GetConcept($id: ID!) {
  getConcept(id: $id) {
    id
    accountID
    name
    hotelCode
    env
    prefix
    type
    rin
    crn
    productionCertificate
    productionApiSecret
    companyTradeName
    branchCode
    activityCode
    branchAddress {
      branchId
      country
      governate
      regionCity
      citySubdivision
      plotIdentification
      street
      buildingNumber
      postalCode
      floor
      room
      landmark
      additionalInformation
      __typename
    }
    clientId
    clientSecret
    synchronizeProcess
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetConceptQueryVariables,
  APITypes.GetConceptQuery
>;
export const listConcepts = /* GraphQL */ `query ListConcepts(
  $filter: ModelConceptFilterInput
  $limit: Int
  $nextToken: String
) {
  listConcepts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      accountID
      name
      hotelCode
      env
      prefix
      type
      rin
      crn
      productionCertificate
      productionApiSecret
      companyTradeName
      branchCode
      activityCode
      branchAddress {
        branchId
        country
        governate
        regionCity
        citySubdivision
        plotIdentification
        street
        buildingNumber
        postalCode
        floor
        room
        landmark
        additionalInformation
        __typename
      }
      clientId
      clientSecret
      synchronizeProcess
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListConceptsQueryVariables,
  APITypes.ListConceptsQuery
>;
export const getCustomer = /* GraphQL */ `query GetCustomer($id: ID!) {
  getCustomer(id: $id) {
    id
    accountID
    ArNumber
    type
    name
    registrationNumber
    address {
      branchId
      country
      governate
      regionCity
      citySubdivision
      plotIdentification
      street
      buildingNumber
      postalCode
      floor
      room
      landmark
      additionalInformation
      __typename
    }
    mobileNumber
    paymentNumber
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCustomerQueryVariables,
  APITypes.GetCustomerQuery
>;
export const listCustomers = /* GraphQL */ `query ListCustomers(
  $filter: ModelCustomerFilterInput
  $limit: Int
  $nextToken: String
) {
  listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      accountID
      ArNumber
      type
      name
      registrationNumber
      address {
        branchId
        country
        governate
        regionCity
        citySubdivision
        plotIdentification
        street
        buildingNumber
        postalCode
        floor
        room
        landmark
        additionalInformation
        __typename
      }
      mobileNumber
      paymentNumber
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCustomersQueryVariables,
  APITypes.ListCustomersQuery
>;
export const customerByArNumber = /* GraphQL */ `query CustomerByArNumber(
  $ArNumber: String!
  $sortDirection: ModelSortDirection
  $filter: ModelCustomerFilterInput
  $limit: Int
  $nextToken: String
) {
  customerByArNumber(
    ArNumber: $ArNumber
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      accountID
      ArNumber
      type
      name
      registrationNumber
      address {
        branchId
        country
        governate
        regionCity
        citySubdivision
        plotIdentification
        street
        buildingNumber
        postalCode
        floor
        room
        landmark
        additionalInformation
        __typename
      }
      mobileNumber
      paymentNumber
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CustomerByArNumberQueryVariables,
  APITypes.CustomerByArNumberQuery
>;
export const customerByDeleted = /* GraphQL */ `query CustomerByDeleted(
  $deleted: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCustomerFilterInput
  $limit: Int
  $nextToken: String
) {
  customerByDeleted(
    deleted: $deleted
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      accountID
      ArNumber
      type
      name
      registrationNumber
      address {
        branchId
        country
        governate
        regionCity
        citySubdivision
        plotIdentification
        street
        buildingNumber
        postalCode
        floor
        room
        landmark
        additionalInformation
        __typename
      }
      mobileNumber
      paymentNumber
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CustomerByDeletedQueryVariables,
  APITypes.CustomerByDeletedQuery
>;
export const getFeature = /* GraphQL */ `query GetFeature($id: ID!) {
  getFeature(id: $id) {
    id
    name
    icon
    slug
    precedence
    parent
    private
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFeatureQueryVariables,
  APITypes.GetFeatureQuery
>;
export const listFeatures = /* GraphQL */ `query ListFeatures(
  $filter: ModelFeatureFilterInput
  $limit: Int
  $nextToken: String
) {
  listFeatures(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      icon
      slug
      precedence
      parent
      private
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFeaturesQueryVariables,
  APITypes.ListFeaturesQuery
>;
export const getAdminRole = /* GraphQL */ `query GetAdminRole($id: ID!) {
  getAdminRole(id: $id) {
    id
    name
    description
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAdminRoleQueryVariables,
  APITypes.GetAdminRoleQuery
>;
export const listAdminRoles = /* GraphQL */ `query ListAdminRoles(
  $filter: ModelAdminRoleFilterInput
  $limit: Int
  $nextToken: String
) {
  listAdminRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAdminRolesQueryVariables,
  APITypes.ListAdminRolesQuery
>;
export const getAdminGroup = /* GraphQL */ `query GetAdminGroup($id: ID!) {
  getAdminGroup(id: $id) {
    id
    name
    description
    roles
    users
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAdminGroupQueryVariables,
  APITypes.GetAdminGroupQuery
>;
export const listAdminGroups = /* GraphQL */ `query ListAdminGroups(
  $filter: ModelAdminGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  listAdminGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      roles
      users
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAdminGroupsQueryVariables,
  APITypes.ListAdminGroupsQuery
>;
export const getUserConcepts = /* GraphQL */ `query GetUserConcepts($id: ID!) {
  getUserConcepts(id: $id) {
    id
    defaultConcept
    concepts
    conceptsRoles
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserConceptsQueryVariables,
  APITypes.GetUserConceptsQuery
>;
export const listUserConcepts = /* GraphQL */ `query ListUserConcepts(
  $filter: ModelUserConceptsFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserConcepts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      defaultConcept
      concepts
      conceptsRoles
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserConceptsQueryVariables,
  APITypes.ListUserConceptsQuery
>;
export const getLanguage = /* GraphQL */ `query GetLanguage($id: ID!) {
  getLanguage(id: $id) {
    id
    name
    code
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLanguageQueryVariables,
  APITypes.GetLanguageQuery
>;
export const listLanguages = /* GraphQL */ `query ListLanguages(
  $filter: ModelLanguageFilterInput
  $limit: Int
  $nextToken: String
) {
  listLanguages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      code
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListLanguagesQueryVariables,
  APITypes.ListLanguagesQuery
>;
export const getTimeline = /* GraphQL */ `query GetTimeline($id: ID!) {
  getTimeline(id: $id) {
    id
    actionName
    oldStatus
    newStatus
    userId
    transactionId
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTimelineQueryVariables,
  APITypes.GetTimelineQuery
>;
export const listTimelines = /* GraphQL */ `query ListTimelines(
  $filter: ModelTimelineFilterInput
  $limit: Int
  $nextToken: String
) {
  listTimelines(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      actionName
      oldStatus
      newStatus
      userId
      transactionId
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTimelinesQueryVariables,
  APITypes.ListTimelinesQuery
>;
export const getItem = /* GraphQL */ `query GetItem($id: ID!) {
  getItem(id: $id) {
    id
    accountID
    internalCode
    itemCode
    description
    type
    unit
    tax1
    tax2
    neglectedItem
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetItemQueryVariables, APITypes.GetItemQuery>;
export const listItems = /* GraphQL */ `query ListItems(
  $filter: ModelItemFilterInput
  $limit: Int
  $nextToken: String
) {
  listItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      accountID
      internalCode
      itemCode
      description
      type
      unit
      tax1
      tax2
      neglectedItem
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListItemsQueryVariables, APITypes.ListItemsQuery>;
export const getReceipt = /* GraphQL */ `query GetReceipt($id: ID!) {
  getReceipt(id: $id) {
    id
    conceptID
    header {
      dateTimeIssued
      receiptNumber
      uuid
      previousUUID
      currency
      exchangeRate
      grossWeight
      netWeight
      __typename
    }
    documentType {
      receiptType
      typeVersion
      __typename
    }
    seller {
      rin
      companyTradeName
      branchCode
      branchAddress {
        branchId
        country
        governate
        regionCity
        citySubdivision
        plotIdentification
        street
        buildingNumber
        postalCode
        floor
        room
        landmark
        additionalInformation
        __typename
      }
      deviceSerialNumber
      activityCode
      __typename
    }
    buyer {
      type
      id
      name
      mobileNumber
      paymentNumber
      __typename
    }
    itemData {
      internalCode
      description
      itemType
      itemCode
      unitType
      quantity
      unitPrice
      netSale
      totalSale
      total
      commercialDiscountData {
        amount
        description
        __typename
      }
      itemDiscountData {
        amount
        description
        __typename
      }
      valueDifference
      taxableItems {
        taxType
        amount
        subType
        rate
        __typename
      }
      __typename
    }
    totalSales
    totalCommercialDiscount
    totalItemsDiscount
    extraReceiptDiscountData {
      amount
      description
      __typename
    }
    netAmount
    feesAmount
    totalAmount
    taxTotals {
      taxType
      amount
      __typename
    }
    paymentMethod
    uuid
    qrString
    qrImage
    status
    reason
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetReceiptQueryVariables,
  APITypes.GetReceiptQuery
>;
export const listReceipts = /* GraphQL */ `query ListReceipts(
  $filter: ModelReceiptFilterInput
  $limit: Int
  $nextToken: String
) {
  listReceipts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      conceptID
      header {
        dateTimeIssued
        receiptNumber
        uuid
        previousUUID
        currency
        exchangeRate
        grossWeight
        netWeight
        __typename
      }
      documentType {
        receiptType
        typeVersion
        __typename
      }
      seller {
        rin
        companyTradeName
        branchCode
        branchAddress {
          branchId
          country
          governate
          regionCity
          citySubdivision
          plotIdentification
          street
          buildingNumber
          postalCode
          floor
          room
          landmark
          additionalInformation
          __typename
        }
        deviceSerialNumber
        activityCode
        __typename
      }
      buyer {
        type
        id
        name
        mobileNumber
        paymentNumber
        __typename
      }
      itemData {
        internalCode
        description
        itemType
        itemCode
        unitType
        quantity
        unitPrice
        netSale
        totalSale
        total
        commercialDiscountData {
          amount
          description
          __typename
        }
        itemDiscountData {
          amount
          description
          __typename
        }
        valueDifference
        taxableItems {
          taxType
          amount
          subType
          rate
          __typename
        }
        __typename
      }
      totalSales
      totalCommercialDiscount
      totalItemsDiscount
      extraReceiptDiscountData {
        amount
        description
        __typename
      }
      netAmount
      feesAmount
      totalAmount
      taxTotals {
        taxType
        amount
        __typename
      }
      paymentMethod
      uuid
      qrString
      qrImage
      status
      reason
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReceiptsQueryVariables,
  APITypes.ListReceiptsQuery
>;
export const getInvoice = /* GraphQL */ `query GetInvoice($id: ID!) {
  getInvoice(id: $id) {
    id
    conceptID
    issuer {
      type
      id
      name
      address {
        branchId
        country
        governate
        regionCity
        citySubdivision
        plotIdentification
        street
        buildingNumber
        postalCode
        floor
        room
        landmark
        additionalInformation
        __typename
      }
      __typename
    }
    receiver {
      type
      id
      name
      address {
        branchId
        country
        governate
        regionCity
        citySubdivision
        plotIdentification
        street
        buildingNumber
        postalCode
        floor
        room
        landmark
        additionalInformation
        __typename
      }
      __typename
    }
    documentType
    documentTypeVersion
    dateTimeIssued
    taxpayerActivityCode
    internalID
    invoiceLines {
      description
      itemType
      itemCode
      unitType
      quantity
      internalCode
      salesTotal
      total
      valueDifference
      totalTaxableFees
      netTotal
      itemsDiscount
      unitValue {
        currencySold
        amountEGP
        amountSold
        currencyExchangeRate
        __typename
      }
      taxableItems {
        taxType
        amount
        subType
        rate
        __typename
      }
      __typename
    }
    invoiceNeglectedLines {
      description
      itemType
      itemCode
      unitType
      quantity
      internalCode
      salesTotal
      total
      valueDifference
      totalTaxableFees
      netTotal
      itemsDiscount
      unitValue {
        currencySold
        amountEGP
        amountSold
        currencyExchangeRate
        __typename
      }
      taxableItems {
        taxType
        amount
        subType
        rate
        __typename
      }
      __typename
    }
    totalDiscountAmount
    totalSalesAmount
    netAmount
    taxTotals {
      taxType
      amount
      __typename
    }
    totalAmount
    extraDiscountAmount
    totalItemsDiscountAmount
    uuid
    type
    cancelReason
    qrString
    qrImage
    status
    reason
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetInvoiceQueryVariables,
  APITypes.GetInvoiceQuery
>;
export const listInvoices = /* GraphQL */ `query ListInvoices(
  $filter: ModelInvoiceFilterInput
  $limit: Int
  $nextToken: String
) {
  listInvoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      conceptID
      issuer {
        type
        id
        name
        address {
          branchId
          country
          governate
          regionCity
          citySubdivision
          plotIdentification
          street
          buildingNumber
          postalCode
          floor
          room
          landmark
          additionalInformation
          __typename
        }
        __typename
      }
      receiver {
        type
        id
        name
        address {
          branchId
          country
          governate
          regionCity
          citySubdivision
          plotIdentification
          street
          buildingNumber
          postalCode
          floor
          room
          landmark
          additionalInformation
          __typename
        }
        __typename
      }
      documentType
      documentTypeVersion
      dateTimeIssued
      taxpayerActivityCode
      internalID
      invoiceLines {
        description
        itemType
        itemCode
        unitType
        quantity
        internalCode
        salesTotal
        total
        valueDifference
        totalTaxableFees
        netTotal
        itemsDiscount
        unitValue {
          currencySold
          amountEGP
          amountSold
          currencyExchangeRate
          __typename
        }
        taxableItems {
          taxType
          amount
          subType
          rate
          __typename
        }
        __typename
      }
      invoiceNeglectedLines {
        description
        itemType
        itemCode
        unitType
        quantity
        internalCode
        salesTotal
        total
        valueDifference
        totalTaxableFees
        netTotal
        itemsDiscount
        unitValue {
          currencySold
          amountEGP
          amountSold
          currencyExchangeRate
          __typename
        }
        taxableItems {
          taxType
          amount
          subType
          rate
          __typename
        }
        __typename
      }
      totalDiscountAmount
      totalSalesAmount
      netAmount
      taxTotals {
        taxType
        amount
        __typename
      }
      totalAmount
      extraDiscountAmount
      totalItemsDiscountAmount
      uuid
      type
      cancelReason
      qrString
      qrImage
      status
      reason
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInvoicesQueryVariables,
  APITypes.ListInvoicesQuery
>;
export const getZatcaInvoice = /* GraphQL */ `query GetZatcaInvoice($id: ID!) {
  getZatcaInvoice(id: $id) {
    id
    conceptID
    invoiceCounterNumber
    invoiceSerialNumber
    invoiceHash
    isUsed
    pcsid
    billNumber
    invoiceRefrence
    folioNumber
    customerID
    customer {
      id
      accountID
      ArNumber
      type
      name
      registrationNumber
      address {
        branchId
        country
        governate
        regionCity
        citySubdivision
        plotIdentification
        street
        buildingNumber
        postalCode
        floor
        room
        landmark
        additionalInformation
        __typename
      }
      mobileNumber
      paymentNumber
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      __typename
    }
    documentType
    type
    status
    dateTimeIssued
    invoiceLines {
      id
      name
      quantity
      taxExclusivePrice
      vatPercent
      discounts {
        amount
        description
        __typename
      }
      VatExemptionReason
      priceAmount
      taxAmount
      roundingAmount
      __typename
    }
    taxExclusiveAmount
    taxInclusiveAmount
    allowanceTotalAmount
    prepaidAmount
    payableAmount
    xmlDocumentEncoded
    uuid
    failureReason
    qrString
    qrImage
    infoMessages
    warningMessages
    errorMessages
    isCancelled
    clearanceStatus
    reportingStatus
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetZatcaInvoiceQueryVariables,
  APITypes.GetZatcaInvoiceQuery
>;
export const listZatcaInvoices = /* GraphQL */ `query ListZatcaInvoices(
  $filter: ModelZatcaInvoiceFilterInput
  $limit: Int
  $nextToken: String
) {
  listZatcaInvoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      conceptID
      invoiceCounterNumber
      invoiceSerialNumber
      invoiceHash
      isUsed
      pcsid
      billNumber
      invoiceRefrence
      folioNumber
      customerID
      customer {
        id
        accountID
        ArNumber
        type
        name
        registrationNumber
        address {
          branchId
          country
          governate
          regionCity
          citySubdivision
          plotIdentification
          street
          buildingNumber
          postalCode
          floor
          room
          landmark
          additionalInformation
          __typename
        }
        mobileNumber
        paymentNumber
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        __typename
      }
      documentType
      type
      status
      dateTimeIssued
      invoiceLines {
        id
        name
        quantity
        taxExclusivePrice
        vatPercent
        discounts {
          amount
          description
          __typename
        }
        VatExemptionReason
        priceAmount
        taxAmount
        roundingAmount
        __typename
      }
      taxExclusiveAmount
      taxInclusiveAmount
      allowanceTotalAmount
      prepaidAmount
      payableAmount
      xmlDocumentEncoded
      uuid
      failureReason
      qrString
      qrImage
      infoMessages
      warningMessages
      errorMessages
      isCancelled
      clearanceStatus
      reportingStatus
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListZatcaInvoicesQueryVariables,
  APITypes.ListZatcaInvoicesQuery
>;
export const zatcaInvoicesByBillNumber = /* GraphQL */ `query ZatcaInvoicesByBillNumber(
  $billNumber: String!
  $sortDirection: ModelSortDirection
  $filter: ModelZatcaInvoiceFilterInput
  $limit: Int
  $nextToken: String
) {
  zatcaInvoicesByBillNumber(
    billNumber: $billNumber
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      conceptID
      invoiceCounterNumber
      invoiceSerialNumber
      invoiceHash
      isUsed
      pcsid
      billNumber
      invoiceRefrence
      folioNumber
      customerID
      customer {
        id
        accountID
        ArNumber
        type
        name
        registrationNumber
        address {
          branchId
          country
          governate
          regionCity
          citySubdivision
          plotIdentification
          street
          buildingNumber
          postalCode
          floor
          room
          landmark
          additionalInformation
          __typename
        }
        mobileNumber
        paymentNumber
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        __typename
      }
      documentType
      type
      status
      dateTimeIssued
      invoiceLines {
        id
        name
        quantity
        taxExclusivePrice
        vatPercent
        discounts {
          amount
          description
          __typename
        }
        VatExemptionReason
        priceAmount
        taxAmount
        roundingAmount
        __typename
      }
      taxExclusiveAmount
      taxInclusiveAmount
      allowanceTotalAmount
      prepaidAmount
      payableAmount
      xmlDocumentEncoded
      uuid
      failureReason
      qrString
      qrImage
      infoMessages
      warningMessages
      errorMessages
      isCancelled
      clearanceStatus
      reportingStatus
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ZatcaInvoicesByBillNumberQueryVariables,
  APITypes.ZatcaInvoicesByBillNumberQuery
>;
export const zatcaInvoicesByDeleted = /* GraphQL */ `query ZatcaInvoicesByDeleted(
  $deleted: String!
  $invoiceCounterNumber: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelZatcaInvoiceFilterInput
  $limit: Int
  $nextToken: String
) {
  zatcaInvoicesByDeleted(
    deleted: $deleted
    invoiceCounterNumber: $invoiceCounterNumber
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      conceptID
      invoiceCounterNumber
      invoiceSerialNumber
      invoiceHash
      isUsed
      pcsid
      billNumber
      invoiceRefrence
      folioNumber
      customerID
      customer {
        id
        accountID
        ArNumber
        type
        name
        registrationNumber
        address {
          branchId
          country
          governate
          regionCity
          citySubdivision
          plotIdentification
          street
          buildingNumber
          postalCode
          floor
          room
          landmark
          additionalInformation
          __typename
        }
        mobileNumber
        paymentNumber
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        __typename
      }
      documentType
      type
      status
      dateTimeIssued
      invoiceLines {
        id
        name
        quantity
        taxExclusivePrice
        vatPercent
        discounts {
          amount
          description
          __typename
        }
        VatExemptionReason
        priceAmount
        taxAmount
        roundingAmount
        __typename
      }
      taxExclusiveAmount
      taxInclusiveAmount
      allowanceTotalAmount
      prepaidAmount
      payableAmount
      xmlDocumentEncoded
      uuid
      failureReason
      qrString
      qrImage
      infoMessages
      warningMessages
      errorMessages
      isCancelled
      clearanceStatus
      reportingStatus
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ZatcaInvoicesByDeletedQueryVariables,
  APITypes.ZatcaInvoicesByDeletedQuery
>;
export const zatcaInvoicesByDeletedSortedByDate = /* GraphQL */ `query ZatcaInvoicesByDeletedSortedByDate(
  $deleted: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelZatcaInvoiceFilterInput
  $limit: Int
  $nextToken: String
) {
  zatcaInvoicesByDeletedSortedByDate(
    deleted: $deleted
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      conceptID
      invoiceCounterNumber
      invoiceSerialNumber
      invoiceHash
      isUsed
      pcsid
      billNumber
      invoiceRefrence
      folioNumber
      customerID
      customer {
        id
        accountID
        ArNumber
        type
        name
        registrationNumber
        address {
          branchId
          country
          governate
          regionCity
          citySubdivision
          plotIdentification
          street
          buildingNumber
          postalCode
          floor
          room
          landmark
          additionalInformation
          __typename
        }
        mobileNumber
        paymentNumber
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        __typename
      }
      documentType
      type
      status
      dateTimeIssued
      invoiceLines {
        id
        name
        quantity
        taxExclusivePrice
        vatPercent
        discounts {
          amount
          description
          __typename
        }
        VatExemptionReason
        priceAmount
        taxAmount
        roundingAmount
        __typename
      }
      taxExclusiveAmount
      taxInclusiveAmount
      allowanceTotalAmount
      prepaidAmount
      payableAmount
      xmlDocumentEncoded
      uuid
      failureReason
      qrString
      qrImage
      infoMessages
      warningMessages
      errorMessages
      isCancelled
      clearanceStatus
      reportingStatus
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ZatcaInvoicesByDeletedSortedByDateQueryVariables,
  APITypes.ZatcaInvoicesByDeletedSortedByDateQuery
>;
export const getInvoker = /* GraphQL */ `query GetInvoker($id: ID!) {
  getInvoker(id: $id) {
    id
    conceptID
    userName
    password
    operations
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetInvokerQueryVariables,
  APITypes.GetInvokerQuery
>;
export const listInvokers = /* GraphQL */ `query ListInvokers(
  $filter: ModelInvokerFilterInput
  $limit: Int
  $nextToken: String
) {
  listInvokers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      conceptID
      userName
      password
      operations
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInvokersQueryVariables,
  APITypes.ListInvokersQuery
>;
export const invokersByUserName = /* GraphQL */ `query InvokersByUserName(
  $userName: String!
  $deleted: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelInvokerFilterInput
  $limit: Int
  $nextToken: String
) {
  invokersByUserName(
    userName: $userName
    deleted: $deleted
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      conceptID
      userName
      password
      operations
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.InvokersByUserNameQueryVariables,
  APITypes.InvokersByUserNameQuery
>;
export const invokersByDeleted = /* GraphQL */ `query InvokersByDeleted(
  $deleted: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelInvokerFilterInput
  $limit: Int
  $nextToken: String
) {
  invokersByDeleted(
    deleted: $deleted
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      conceptID
      userName
      password
      operations
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.InvokersByDeletedQueryVariables,
  APITypes.InvokersByDeletedQuery
>;
export const getZatcaInvoicesSequence = /* GraphQL */ `query GetZatcaInvoicesSequence($id: ID!) {
  getZatcaInvoicesSequence(id: $id) {
    id
    conceptID
    invoiceCounterNumber
    invoiceHash
    isUsed
    pcsid
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetZatcaInvoicesSequenceQueryVariables,
  APITypes.GetZatcaInvoicesSequenceQuery
>;
export const listZatcaInvoicesSequences = /* GraphQL */ `query ListZatcaInvoicesSequences(
  $filter: ModelZatcaInvoicesSequenceFilterInput
  $limit: Int
  $nextToken: String
) {
  listZatcaInvoicesSequences(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      conceptID
      invoiceCounterNumber
      invoiceHash
      isUsed
      pcsid
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListZatcaInvoicesSequencesQueryVariables,
  APITypes.ListZatcaInvoicesSequencesQuery
>;
export const zatcaInvoicesSequenceByConceptID = /* GraphQL */ `query ZatcaInvoicesSequenceByConceptID(
  $conceptID: ID!
  $deleted: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelZatcaInvoicesSequenceFilterInput
  $limit: Int
  $nextToken: String
) {
  zatcaInvoicesSequenceByConceptID(
    conceptID: $conceptID
    deleted: $deleted
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      conceptID
      invoiceCounterNumber
      invoiceHash
      isUsed
      pcsid
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ZatcaInvoicesSequenceByConceptIDQueryVariables,
  APITypes.ZatcaInvoicesSequenceByConceptIDQuery
>;
