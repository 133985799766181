// actions
const SET = "concepts/SET";
const SET_SEARCH_TEXT = "concepts/SET_SEARCH_TEXT";
const SET_SELECTED = "concepts/SET_SELECTED";
const SET_BRANCHES = "concepts/SET_BRANCHES";
const SET_FILTERS = "concepts/SET_FILTERS";
const SET_SELECTED_FILTERS = "concepts/SET_SELECTED_FILTERS";
const SET_NEXT_TOKEN = "concepts/SET_NEXT_TOKEN";

const DEFAULT_STATE = {
  listing: [],
  filters: [],
  selectedFilters: [],
  searchText: "",
  selected: "",
  branches: [],
  nextToken: null,
  nextNextToken: undefined,
  previousTokens: [],
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case SET_SELECTED_FILTERS:
      return Object.assign({}, state, { selectedFilters: action.listing });
    case SET_FILTERS:
      return Object.assign({}, state, { filters: action.filters });
    case SET_SEARCH_TEXT:
      return Object.assign({}, state, { searchText: action.searchText });
    case SET_SELECTED:
      return Object.assign({}, state, { selected: action.id });
    case SET_BRANCHES:
      return Object.assign({}, state, { branches: action.branches });
    case SET_NEXT_TOKEN:
      return Object.assign({}, state, { nextToken: action.id });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setListing = (listing: any[]) => ({ listing, type: SET });

export const setSearchText = (searchText: string) => ({
  searchText,
  type: SET_SEARCH_TEXT,
});

export const setSelected = (id: string) => ({ id, type: SET_SELECTED });

export const setBranches = (branches: any) => ({
  branches,
  type: SET_BRANCHES,
});

export const setSelectedFilters = (listing: any[]) => ({
  listing,
  type: SET_SELECTED_FILTERS,
});
export const setFilters = (filters: any[]) => ({ filters, type: SET_FILTERS });
export const setNextToken = (id: any) => ({ id, type: SET_NEXT_TOKEN });
