import { getAccount } from "./../graphql/queries";
import { CreateAccountInput, UpdateAccountInput } from "./../models/GQL_API";
import {
  createAccount,
  deleteAccount,
  updateAccount,
} from "./../graphql/mutations";
import { extractSelectedCheckboxes, getSiteAddress } from "./../helpers/utils";
import { API } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { useDispatch, useSelector } from "react-redux";
import {
  setListing,
  setSearchText,
  setSelected,
  setNextToken,
} from "../store/ducks/account";
import { Account } from "../models";
import { HeadCell } from "../models/dataTable";
import useApp from "./useApp";
import { listAccounts } from "../graphql/queries";

const useAccount = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { session, showConfirm, showError } = useApp();

  const listing = useSelector(
    (state: any) => state[`${listingName}`]["listing"]
  );

  const searchText = useSelector(
    (state: any) => state[`${listingName}`]["searchText"]
  );

  const selected = useSelector(
    (state: any) => state[`${listingName}`]["selected"]
  );

  const nextToken = useSelector(
    (state: any) => state[`${listingName}`]["nextToken"]
  );

  async function fetch(startIndex: number, limit: number) {
    try {
      const filter: any = {
        deleted: { eq: "0" },
      };

      if (searchText)
        filter.or = {
          domain: { contains: searchText.toLowerCase() },
          siteTitle: { contains: searchText.toLowerCase() },
          siteAddress: { contains: searchText.toLowerCase() },
        };

      const accountList: any = await API.graphql<Account>({
        query: listAccounts,
        variables: { filter, limit },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      const currentNextToken = accountList.data.listAccounts.nextToken;
      const listing = accountList.data.listAccounts.items;

      dispatch(setNextToken(currentNextToken));

      return listing;
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  }

  async function bySiteAddress(session: any) {
    try {
      let siteAddress = getSiteAddress();

      const filter: any = {
        // siteAddress: { contains: siteAddress },
      };

      filter.or = [];
      filter.or.push({ siteAddress: { contains: siteAddress } });

      // Remove this after testing
      filter.or.push({
        siteAddress: { contains: "dev.d2eenvwnjbxpin.amplifyapp.com" },
      });

      const listing: any = await API.graphql({
        query: listAccounts,
        variables: { filter, limit: 1 },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      if (listing.data.listAccounts.items.length > 0) {
        const account = listing.data.listAccounts.items[0];

        dispatch(setSelected(account));
        return account;
      }
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  }

  async function get(id: string) {
    try {
      let single: Account | undefined;
      if (listing.length !== 0) {
        single = listing.find((resource: any) => resource.id === id);
      }

      if (single === undefined) {
        const listing: any = await API.graphql<Account>({
          query: getAccount,
          variables: { id },
          authMode: true
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
        single = listing.data.getAccount;
      }

      return single;
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  }

  async function getDomainName() {
    return selected.domain;
  }

  async function create(data: any, stateData: any) {
    const languages: string[] = extractSelectedCheckboxes("languages_", data);
    const features: string[] = extractSelectedCheckboxes("features_", data);

    try {
      const createInput: CreateAccountInput = {
        domain: data.domain,
        siteTitle: data.siteTitle,
        tagline: data.tagline,
        description: data.description,
        siteAddress: data.siteAddress,
        defaultLanguage: data.defaultLanguage,
        status: "active",
        languages: languages,
        features: features,
        deleted: "0",
        createdAt: new Date().getTime().toString(),
        createdByID: session ? session.sub : "",
        createdByName: session ? session.name : "",
      };

      await API.graphql<Account>({
        query: createAccount,
        variables: { input: createInput },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      showConfirm(`New ${singleName} has been created successfully`);
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  }

  async function update(id: string, data: any) {
    const languages: string[] = extractSelectedCheckboxes("languages_", data);
    const features: string[] = extractSelectedCheckboxes("features_", data);

    try {
      const original = await get(id);
      if (original) {
        const updateInput: UpdateAccountInput = {
          id: original.id,
          domain: data.domain ? data.domain : original!.domain,
          description: data.description
            ? data.description
            : original!.description,
          siteAddress: data.siteAddress
            ? data.siteAddress
            : original!.siteAddress,
          defaultLanguage: data.defaultLanguage
            ? data.defaultLanguage
            : original!.defaultLanguage,
          languages: languages,
          features: features,
        };

        await API.graphql<Account>({
          query: updateAccount,
          variables: { input: updateInput },
          authMode: true
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
      }

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  }

  async function trash(id: string) {
    try {
      const original = await get(id);

      if (original) {
        const updateInput: UpdateAccountInput = {
          id: original.id,
          deleted: "1",
        };

        await API.graphql<Account>({
          query: updateAccount,
          variables: { input: updateInput },
          authMode: true
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
      }

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  }

  async function bulkTrash(resourceIds: any) {
    for (let id of resourceIds) {
      try {
        await trash(id);
      } catch (err: Error | any) {
        showError(err);
      }
    }

    dispatch(
      setListing(
        listing.filter((resource: any) => !resourceIds.has(resource.id))
      )
    );

    showConfirm(
      `${resourceIds.size} ${listingName} items has been moved to trash`
    );
  }

  async function remove(id: any) {
    try {
      await API.graphql<Account>({
        query: deleteAccount,
        variables: { id: id },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      dispatch(
        setListing(
          listing.filter((resource: any) => resource.id !== id)
        )
      );

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "siteTitle",
      numeric: false,
      disablePadding: false,
      label: "Site Title",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["siteTitle"];

  const options: any[] = [];

  if (listing)
    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }

  const api: any = {};

  api[`${listingName}Listing`] = listing;
  api[`${listingName}Options`] = options;
  api[`${listingName}SearchText`] = searchText;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Model`] = Account as any;
  api[`${listingName}Selected`] = selected;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchBySiteAddress`] = bySiteAddress;
  api[`${listingName}Get`] = get;
  api[`${listingName}GetDomainName`] = getDomainName;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}ChangeListing`] = (listing: Account[]) =>
    dispatch(setListing(listing));
  api[`${listingName}Search`] = (searchText: string) =>
    dispatch(setSearchText(searchText));
  api[`${listingName}Select`] = (conceptID: string) =>
    dispatch(setSelected(conceptID));

  return api;
};

export default useAccount;
