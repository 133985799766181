import useAccount from "./useAccount";
import useConcept from "./useConcept";
import useCustomer from "./useCustomer";
import useFeature from "./useFeature";
import useTimeline from "./useTimeline";
import useAdminRole from "./useAdminRole";
import useAdminGroup from "./useAdminGroup";
import useItem from "./useItem";
import useReceipt from "./useReceipt";
import useInvoice from "./useInvoice";
import useZatcaInvoice from "./useZatcaInvoices";
import useInvokers from "./useInvokers";

const useResource = () => {
  const accountsApi = useAccount("accounts", "account");
  const conceptsApi = useConcept("concepts", "concept");
  const customerApi = useCustomer("customers", "customer");
  const featuresApi = useFeature("features", "feature");
  const adminRolesApi = useAdminRole("adminRoles", "adminRole");
  const adminGroupsApi = useAdminGroup("adminGroups", "adminGroup");
  const timeLinesApi = useTimeline("timelines", "timeline");
  const itemsApi = useItem("items", "item");
  const receiptsApi = useReceipt("receipts", "receipt");
  const invoicesApi = useInvoice("invoices", "invoice");
  const zatcaInvoicesApi = useZatcaInvoice("zatcaInvoices", "zatcaInvoice")
  const invokersApi = useInvokers("invokers", "invoker")

  return {
    ...accountsApi,
    ...conceptsApi,
    ...customerApi,
    ...featuresApi,
    ...adminRolesApi,
    ...adminGroupsApi,
    ...timeLinesApi,
    ...itemsApi,
    ...receiptsApi,
    ...invoicesApi,
    ...zatcaInvoicesApi,
    ...invokersApi,
  };
};

export default useResource;
