import * as XLSX from "xlsx";
import { Invoice } from "./../API";
import { getInvoice } from "./../graphql/queries";
import {
  Concept,
  Customer,
  InvoiceLine,
  Item,
  ModelInvoiceFilterInput,
  UpdateInvoiceInput,
} from "./../models/GQL_API";
import { deleteInvoice, updateInvoice } from "./../graphql/mutations";
import { API } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { useDispatch, useSelector } from "react-redux";
import {
  setListing,
  setListingInvoicesStats,
  setListingCreditNotesStats,
  setSearchText,
  setSelected,
  setNextToken,
  changeLimit,
  setPreviousToken,
  nextAction,
} from "../store/ducks/invoice";
import { HeadCell } from "../models/dataTable";
import useApp from "./useApp";
import { listInvoices } from "../graphql/queries";
import {
  ListingByAccountVariables,
  TransactionListingVariables,
  TransactionStats,
} from "../models/app";
import {
  INVOICE_STATUS,
  INVOICE_TYPES,
  TimelineActions,
} from "../constants/enums";
import { EG_OPERATIONS, EG_SEND_INVOICE } from "../constants/endPoints";
import useTimeline from "./useTimeline";
import { getFormattedDate } from "../helpers/utils";
import useCustomer from "./useCustomer";
import useItems from "./useItem";
import useConcept from "./useConcept";

const useInvoice = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { session, showConfirm, showError } = useApp();
  const { conceptsGet } = useConcept("concepts", "concept");
  const { timelinesCreate } = useTimeline("timelines", "timeline");
  const { customersFetch } = useCustomer("customers", "customer");
  const { itemsFetch } = useItems("items", "item");

  const listing = useSelector(
    (state: any) => state[`${listingName}`]["listing"]
  );
  const searchText = useSelector(
    (state: any) => state[`${listingName}`]["searchText"]
  );

  const selected = useSelector(
    (state: any) => state[`${listingName}`]["selected"]
  );

  const storedLimit = useSelector(
    (state: any) => state[`${listingName}`]["limit"]
  );

  const nextToken = useSelector(
    (state: any) => state[`${listingName}`]["nextToken"]
  );
  const previousTokens = useSelector(
    (state: any) => state[`${listingName}`]["previousTokens"]
  );

  async function fetch(props: TransactionListingVariables) {
    try {
      const {
        startIndex,
        limit,
        moveForward,
        conceptID,
        searchText,
        type,
        fromDate,
        toDate,
        conceptsSelectedFilters,
      } = props;

      let requestLimit = storedLimit;
      let requestToken = nextToken;
      let requestPreviousTokens = previousTokens;

      // Clear pagination data
      if (limit !== storedLimit || startIndex === 0) {
        requestLimit = limit;
        requestToken = null;
        requestPreviousTokens = [];

        dispatch(changeLimit(limit));
      }

      // Get token from previous tokens
      if (!moveForward) {
        const updatedPreviousTokens = [...requestPreviousTokens];
        updatedPreviousTokens.pop();

        requestToken =
          updatedPreviousTokens.length >= 2
            ? updatedPreviousTokens[updatedPreviousTokens.length - 2]
            : null;
        dispatch(
          setNextToken(updatedPreviousTokens[updatedPreviousTokens.length - 1])
        );
        dispatch(setPreviousToken(updatedPreviousTokens));
      }

      const filter: any = {
        deleted: { eq: "0" },
      };

      if (type) {
        filter.type = { eq: type };
      }

      if (searchText) {
        // Search Filter
        filter.or = [];
        filter.or.push({ internalID: { contains: searchText } });
      }

      // Concepts Filter
      if (conceptsSelectedFilters && conceptsSelectedFilters.length > 0) {
        filter.or = [];
        for (let conceptsSelected of conceptsSelectedFilters) {
          filter.or.push({ conceptID: { eq: conceptsSelected.id } });
        }
      } else {
        if (conceptID) filter.conceptID = { eq: conceptID };
      }

      // Date Filter
      if (fromDate && toDate) {
        filter.and = [];

        filter.and.push({
          createdAt: { ge: new Date(fromDate).getTime() },
        });
        filter.and.push({
          createdAt: {
            lt: new Date(toDate).getTime(),
          },
        });
      }

      const invoicesList: any = await API.graphql<Invoice>({
        query: listInvoices,
        variables: {
          filter,
          limit: requestLimit,
          nextToken: requestToken,
        },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      if (moveForward) {
        const currentNextToken = invoicesList.data.listInvoices.nextToken;
        dispatch(nextAction(currentNextToken, requestPreviousTokens));
      }

      const listing = invoicesList.data.listInvoices.items;

      return listing;
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  }

  /*
   * Fetch all invoice within selected range
   * Filter then using invoice status (Valid, Invalid, Rejected, Cancelled)
   * Return Hash map contains (Status, # of invoices, total, percentage)
   */
  async function fetchStats(props: TransactionListingVariables) {
    try {
      const {
        limit,
        conceptID,
        fromDate,
        toDate,
        conceptsSelectedFilters,
        type,
      } = props;

      const filter: ModelInvoiceFilterInput = {
        deleted: { eq: "0" },
      };

      if (type) {
        filter.type = { eq: type };
      }

      if (conceptsSelectedFilters && conceptsSelectedFilters.length > 0) {
        // Concepts Filter
        filter.or = [];
        for (let conceptsSelected of conceptsSelectedFilters) {
          filter.or.push({ conceptID: { eq: conceptsSelected.id } });
        }
      } else {
        if (conceptID) filter.conceptID = { eq: conceptID };
      }

      // Date Filter
      if (fromDate && toDate) {
        filter.and = [];

        filter.and.push({
          createdAt: { ge: new Date(fromDate).getTime().toString() },
        });
        filter.and.push({
          createdAt: {
            lt: new Date(toDate).getTime().toString(),
          },
        });
      }

      const invoiceList: any = await API.graphql<Invoice>({
        query: listInvoices,
        variables: { filter, limit },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      const listing = invoiceList.data.listInvoices.items;
      // Calculate stats
      let DocumentType = type;
      if (DocumentType === undefined) DocumentType = INVOICE_TYPES.INVOICE;

      const stats = await InvoicesStats(listing, DocumentType);

      return stats;
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  }

  async function InvoicesStats(listing: Invoice[], type: string) {
    try {
      let submitCounter: TransactionStats = {
        id: 0,
        status: INVOICE_STATUS.SUBMITTED,
        counter: 0,
        total: 0,
        percentage: 0,
        fill: "#B835E0",
      };

      let validCounter: TransactionStats = {
        id: 1,
        status: INVOICE_STATUS.VALID,
        counter: 0,
        total: 0,
        percentage: 0,
        fill: "#84c529",
      };

      let rejectedCounter: TransactionStats = {
        id: 2,
        status: INVOICE_STATUS.REJECTED,
        counter: 0,
        total: 0,
        percentage: 0,
        fill: "#03a9f4",
      };

      let cancelledCounter: TransactionStats = {
        id: 3,
        status: INVOICE_STATUS.CANCELLED,
        counter: 0,
        total: 0,
        percentage: 0,
        fill: "#ff6f00",
      };

      let invalidCounter: TransactionStats = {
        id: 4,
        status: INVOICE_STATUS.INVALID,
        counter: 0,
        total: 0,
        percentage: 0,
        fill: "#fc2d42",
      };

      let totalCounter: TransactionStats = {
        id: 5,
        status: "total",
        counter: 0,
        total: 0,
        percentage: 100,
        fill: "#172329",
      };

      let totalInvoices = 0;
      let totalAmount = 0;

      listing.forEach(({ status, totalAmount }) => {
        switch (status) {
          case INVOICE_STATUS.SUBMITTED:
            submitCounter.counter++;
            validCounter.total += totalAmount;
            break;
          case INVOICE_STATUS.VALID:
            validCounter.counter++;
            validCounter.total += totalAmount;
            break;
          case INVOICE_STATUS.REJECTED:
            rejectedCounter.counter++;
            rejectedCounter.total += totalAmount;
            break;
          case INVOICE_STATUS.CANCELLED:
            cancelledCounter.counter++;
            cancelledCounter.total += totalAmount;
            break;
          case INVOICE_STATUS.INVALID:
            invalidCounter.counter++;
            invalidCounter.total += totalAmount;
            break;
          default:
            break;
        }
      });

      totalInvoices =
        submitCounter.counter +
        validCounter.counter +
        invalidCounter.counter +
        rejectedCounter.counter +
        cancelledCounter.counter;

      totalAmount =
        submitCounter.total +
        validCounter.total +
        invalidCounter.total +
        rejectedCounter.total +
        cancelledCounter.total;

      totalCounter.counter = totalInvoices;
      totalCounter.total = totalAmount;

      if (totalInvoices > 0) {
        submitCounter.percentage =
          (submitCounter.counter / totalInvoices) * 100;
        validCounter.percentage = (validCounter.counter / totalInvoices) * 100;
        invalidCounter.percentage =
          (invalidCounter.counter / totalInvoices) * 100;
        rejectedCounter.percentage =
          (rejectedCounter.counter / totalInvoices) * 100;
        cancelledCounter.percentage =
          (cancelledCounter.counter / totalInvoices) * 100;
      }

      const InvoicesStats: TransactionStats[] = [
        submitCounter,
        validCounter,
        rejectedCounter,
        cancelledCounter,
        invalidCounter,
        totalCounter,
      ];

      if (type === INVOICE_TYPES.INVOICE)
        dispatch(setListingInvoicesStats(InvoicesStats));
      else if (type === INVOICE_TYPES.CREDIT_NOTE)
        dispatch(setListingCreditNotesStats(InvoicesStats));

      return InvoicesStats;
    } catch (err: Error | any) {
      throw err;
    }
  }

  async function get(id: string) {
    try {
      let single: Invoice | undefined;
      if (listing.length !== 0) {
        single = listing.find((resource: any) => resource.id === id);
      }

      if (single === undefined) {
        const listing: any = await API.graphql<Invoice>({
          query: getInvoice,
          variables: { id },
          authMode: true
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
        single = listing.data.getInvoice;
      }

      return single;
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  }

  async function update(
    id: string,
    data: any,
    editedLine: any,
    action: string
  ) {
    try {
      const original = await get(id);
      if (original) {
        let updateInput: UpdateInvoiceInput = {
          id: original.id,

          dateTimeIssued: data.dateTimeIssued
            ? data.dateTimeIssued
            : original!.dateTimeIssued,
          totalDiscountAmount: data.totalDiscountAmount
            ? data.totalDiscountAmount
            : original!.totalDiscountAmount,
          totalSalesAmount: data.totalSalesAmount
            ? data.totalSalesAmount
            : original!.totalSalesAmount,
          netAmount: data.netAmount ? data.netAmount : original!.netAmount,
          totalAmount: data.totalAmount
            ? data.totalAmount
            : original!.totalAmount,
          extraDiscountAmount: data.extraDiscountAmount
            ? data.extraDiscountAmount
            : original!.extraDiscountAmount,
          totalItemsDiscountAmount: data.totalItemsDiscountAmount
            ? data.totalItemsDiscountAmount
            : original!.totalItemsDiscountAmount,
          invoiceLines: data.invoiceLines,
        };

        if (editedLine) {
          let timelineParams: any = {};

          // Edit (description, code, type, unit) only
          timelineParams = {
            actionName: TimelineActions.UPDATE_TRX_LINE,
            oldStatus: "",
            newStatus: "",
            transactionId: original.id,
          };
          await timelinesCreate(timelineParams);
        }

        await API.graphql<Invoice>({
          query: updateInvoice,
          variables: { input: updateInput },
          authMode: true
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
      }

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function recalculateDocumentAfterDelete(data: Invoice, item: any) {
    let updateInvoice = { ...data };
    let newNet: number = updateInvoice.netAmount;
    let newTotalSales: number = updateInvoice.totalSalesAmount;
    let newTotalAmount: number = updateInvoice.totalAmount;

    let tax = item.total - item.totalTaxableFees - item.salesTotal;
    let serviceCharge = item?.totalTaxableFees ? item.totalTaxableFees : 0;

    newNet -= item?.netTotal ? item.netTotal : 0;
    newTotalSales -= item?.salesTotal ? item.salesTotal : 0;
    newTotalAmount -= item?.total ? item.total : 0;

    updateInvoice.netAmount = newNet;
    updateInvoice.totalSalesAmount = newTotalAmount;
    updateInvoice.totalAmount = newTotalSales; // (net + tax - discount)

    for (let taxItem of updateInvoice.taxTotals) {
      // 14.00
      if (taxItem && taxItem.taxType === "T1") {
        taxItem.amount -= tax;
      }
      // 12.00
      else if (taxItem && taxItem.taxType === "T9") {
        taxItem.amount -= serviceCharge;
      }
    }

    return updateInvoice;
  }

  async function trash(id: string) {
    try {
      const original = await get(id);

      if (original) {
        const updateInput: UpdateInvoiceInput = {
          id: original.id,
          deleted: "1",
        };

        await API.graphql<Invoice>({
          query: updateInvoice,
          variables: { input: updateInput },
          authMode: true
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
      }

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  }

  async function bulkTrash(resourceIds: any) {
    for (let id of resourceIds) {
      try {
        await trash(id);
      } catch (err: Error | any) {
        showError(err);
      }
    }

    dispatch(
      setListing(
        listing.filter((resource: any) => !resourceIds.has(resource.id))
      )
    );

    showConfirm(
      `${resourceIds.size} ${listingName} items has been moved to trash`
    );
  }

  async function remove(id: any) {
    try {
      await API.graphql<Invoice>({
        query: deleteInvoice,
        variables: { id: id },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      dispatch(
        setListing(listing.filter((resource: any) => resource.id !== id))
      );

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  }

  /*
   * Cancel document in Egyptian Gateway
   */
  async function cancelInvoice(document: Invoice, cancelReason: string) {
    try {
      if (
        document.status !== INVOICE_STATUS.SUBMITTED &&
        document.status !== INVOICE_STATUS.VALID
      ) {
        showError(`Can't cancel ${document.status} document`);
        return;
      }

      const branchId = document.conceptID;
      const operation = EG_OPERATIONS.CANCEL_INVOICE;
      const requestPayload = {
        createdByID: session ? session.sub : "",
        createdByName: session ? session.name : "",

        document: document,
        cancelReason: cancelReason ? cancelReason : "Invalid document.",
      };

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestPayload),
      };

      await window
        .fetch(
          `${EG_SEND_INVOICE}?operation=${operation}&branchId=${branchId}`,
          requestOptions
        )
        .then((response) => response.text())
        .then((text) => {
          let responseBody = JSON.parse(text);
          if (responseBody.status === 200) {
            showConfirm(responseBody.message);
          } else {
            showError(responseBody.message);
          }
        })
        .catch((err) => {
          showError(
            typeof err.message === "string"
              ? err.message
              : "error occurred while sending SMS"
          );
        });
    } catch (err) {
      console.log(err);
    }
  }

  async function submitInvoice(document: Invoice) {
    try {
      const branchId = document.conceptID;
      const operation = EG_OPERATIONS.SUBMIT_INVOICE;
      const requestPayload = {
        createdByID: session ? session.sub : "",
        createdByName: session ? session.name : "",

        document: document,
      };

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestPayload),
      };

      await window
        .fetch(
          `${EG_SEND_INVOICE}?operation=${operation}&branchId=${branchId}`,
          requestOptions
        )
        .then((response) => response.text())
        .then((text) => {
          let responseBody = JSON.parse(text);
          console.log({ responseBody });

          let StatusMessages = responseBody.StatusMessages.Messages;
          if (StatusMessages.length > 0) {
            let errorMessage = "";
            StatusMessages.forEach((message: any) => {
              errorMessage += message.Description;
            });

            showError(errorMessage);
          }
        })
        .catch((err) => {
          showError(
            typeof err.message === "string"
              ? err.message
              : "error occurred while sending SMS"
          );
        });
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  }

  async function resubmitInvoice(document: Invoice) {
    try {
      // Check document status
      if (
        document.status === INVOICE_STATUS.SUBMITTED ||
        document.status === INVOICE_STATUS.VALID
      ) {
        showError(`Can resubmit ${document.status} document.`);
        return;
      }

      const branchId = document.conceptID;
      const operation = EG_OPERATIONS.RESUBMIT_INVOICE;
      const requestPayload = {
        createdByID: session ? session.sub : "",
        createdByName: session ? session.name : "",

        document: document,
      };

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestPayload),
      };

      await window
        .fetch(
          `${EG_SEND_INVOICE}?operation=${operation}&branchId=${branchId}`,
          requestOptions
        )
        .then((response) => response.text())
        .then((text) => {
          let responseBody = JSON.parse(text);
          console.log({ responseBody });

          let StatusMessages = responseBody.StatusMessages.Messages;
          if (StatusMessages.length > 0) {
            let errorMessage = "";
            StatusMessages.forEach((message: any) => {
              errorMessage += message.Description;
            });

            showError(errorMessage);
          }
        })
        .catch((err) => {
          showError(
            typeof err.message === "string"
              ? err.message
              : "error occurred while sending SMS"
          );
        });
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  }

  async function submitCreditNote(document: Invoice, invoiceLine: InvoiceLine) {
    try {
      // Check document status
      if (
        document.status !== INVOICE_STATUS.SUBMITTED &&
        document.status !== INVOICE_STATUS.VALID
      ) {
        showError(`Can send credit note for ${document.status} document.`);
        return;
      }

      const branchId = document.conceptID;
      const operation = EG_OPERATIONS.CANCEL_INVOICE_LINE;

      /* Create new credit note document */
      let taxTotals = [];
      if (invoiceLine.taxableItems)
        for (const taxLine of invoiceLine.taxableItems) {
          if (taxLine) {
            let tax = {
              taxType: taxLine.taxType,
              amount: taxLine.amount,
            };
            taxTotals.push(tax);
          }
        }

      let creditNoteDoc: any = {
        // Static Fields
        issuer: document.issuer,
        receiver: document.receiver,
        documentType: "C",
        documentTypeVersion: document.documentTypeVersion,
        dateTimeIssued: getFormattedDate(new Date()),
        taxpayerActivityCode: document.taxpayerActivityCode,
        internalID: document.internalID,
        // Zeros Fields
        totalDiscountAmount: document.totalDiscountAmount,
        extraDiscountAmount: document.extraDiscountAmount,
        totalItemsDiscountAmount: document.totalItemsDiscountAmount,
        // Calculated Fields
        invoiceLines: [invoiceLine],
        netAmount: invoiceLine.netTotal,
        totalSalesAmount: invoiceLine.salesTotal,
        totalAmount: invoiceLine.total,
        taxTotals: taxTotals,
      };

      const requestPayload = {
        createdByID: session ? session.sub : "",
        createdByName: session ? session.name : "",
        document: document,
        creditNoteDoc: creditNoteDoc,
      };

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestPayload),
      };

      await window
        .fetch(
          `${EG_SEND_INVOICE}?operation=${operation}&branchId=${branchId}`,
          requestOptions
        )
        .then((response) => response.text())
        .then((text) => {
          let responseBody = JSON.parse(text);
          console.log({ responseBody });

          let StatusMessages = responseBody.StatusMessages.Messages;
          if (StatusMessages.length > 0) {
            let errorMessage = "";
            StatusMessages.forEach((message: any) => {
              errorMessage += message.Description;
            });

            showError(errorMessage);
          }
        })
        .catch((err) => {
          showError(
            typeof err.message === "string"
              ? err.message
              : "error occurred while sending SMS"
          );
        });
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  }

  /*
   * Get invoices listed in CSV format
   * Convert them to JSON
   * Save this JSON in local file
   * Loop over this list to submit invoice/credit note
   *
   * */
  async function importDataExcelFile(
    file: File,
    accountID: string,
    conceptID: string
  ) {
    let folioNo = "";
    let quantity = 0;
    let unitPrice = 0;
    let netAmount = 0;
    let salesAmount = 0;
    let tax = 0;
    let serviceCharge = 0;
    let grossAmount = 0;
    let currency = "";
    let exchangeRate = 0;
    let transactionCode = "";
    let accountNumber = "";
    let firstName = "";
    let lastName = "";
    let identificationID = "";

    let documentType = "C"; // I for Invoice, C for Credit Note
    let documentTypeVersion = "1.0";
    let date = "2023-10-30T00:00:00Z";
    let prefix = ``; // V for Village, I for Island

    let invoiceLines: any[] = [];
    let invoices: any[] = [];

    const listingByAccountParams: ListingByAccountVariables = {
      accountID: accountID,
      conceptID: "",
      searchText: "",
      startIndex: 0,
      limit: 1000,
      session,
    };

    try {
      /* Database requests for mapping tables */
      const databaseMapping = await Promise.all([
        conceptsGet(conceptID),
        customersFetch(listingByAccountParams),
        itemsFetch(listingByAccountParams),
      ]);

      const branch: Concept = databaseMapping[0];
      const customers = databaseMapping[1];
      const itemsMapping = databaseMapping[2];
      prefix = branch.prefix ? `${branch.prefix}-` : "";

      let issuer: any = {};
      if (branch && branch.branchAddress) {
        const branchAddress: any = {};
        branchAddress.branchId = branch.branchAddress.branchId;
        branchAddress.country = branch.branchAddress.country;
        branchAddress.governate = branch.branchAddress.governate;
        branchAddress.regionCity = branch.branchAddress.regionCity;
        branchAddress.street = branch.branchAddress.street;
        branchAddress.buildingNumber = branch.branchAddress.buildingNumber;
        branchAddress.postalCode = branch.branchAddress.postalCode;
        branchAddress.floor = branch.branchAddress.floor;
        branchAddress.room = branch.branchAddress.room;
        branchAddress.landmark = branch.branchAddress.landmark;
        branchAddress.additionalInformation =
          branch.branchAddress.additionalInformation;

        issuer.type = branch.type ? branch.type : "B";
        issuer.id = branch.rin;
        issuer.name = branch.companyTradeName;
        issuer.address = branchAddress;
      }

      const reader = new FileReader();
      reader.readAsBinaryString(file);

      reader.onload = async (event) => {
        const data = event.target?.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const rows = XLSX.utils.sheet_to_json(worksheet);

        let currentFolioNumber = "";
        let guestName = "";
        let arrivalDate = "";
        let departureDate = "";
        let roomNumber = "";

        let currentCustomer: any = null;
        let totalNet = 0;
        let totalTax = 0;
        let totalService = 0;
        let totalSalesAmount = 0;
        let totalAmount = 0;
        let rate = 1;

        for (let i = 0; i < rows.length; i++) {
          const row: any = rows[i];
          folioNo = row["folioNo"] || "";

          // Receiver Data
          accountNumber = row["accountNumber"] || "";
          firstName = row["firstName"] || "";
          lastName = row["lastName"] || "";
          firstName = firstName.replace("'", " ").trim();
          lastName = lastName.replace("'", " ").trim();

          identificationID = row["identificationID"] || "";

          // First Invoice Only
          if (currentFolioNumber === "") {
            currentFolioNumber = folioNo;
            guestName = (firstName + " " + lastName).trim();
            currentCustomer = await getDocumentCustomer(
              accountNumber,
              firstName,
              lastName,
              customers,
              identificationID
            );
          }

          if (currentFolioNumber !== folioNo) {
            // Loop over invoice lines and add foreign currency
            let convertCurrency = false;
            let invoiceCurrency = "SAR";
            let invoiceRate = 1;
            for (let invoiceLine of invoiceLines) {
              if (invoiceLine.unitValue.currencySold !== "SAR") {
                convertCurrency = true;
                invoiceCurrency = invoiceLine.unitValue.currencySold;
                invoiceRate = invoiceLine.unitValue.currencyExchangeRate;

                break;
              }
            }

            let updateInvoiceLines = [];
            if (convertCurrency) {
              for (let invoiceLine of invoiceLines) {
                invoiceLine = {
                  ...invoiceLine,
                  unitValue: {
                    amountEGP: invoiceLine.unitValue.amountEGP,
                    amountSold: +(
                      invoiceLine.unitValue.amountEGP / invoiceRate
                    ).toFixed(5),
                    currencyExchangeRate: +invoiceRate.toFixed(5),
                    currencySold: invoiceCurrency,
                  },
                };

                updateInvoiceLines.push(invoiceLine);
              }
            } else {
              updateInvoiceLines = invoiceLines;
            }

            // Add created invoice
            let invoice = {
              // Static Fields
              documentType: documentType,
              documentTypeVersion: documentTypeVersion,
              dateTimeIssued: date,

              issuer: issuer,
              receiver: currentCustomer.address
                ? {
                    type: currentCustomer.type,
                    id: currentCustomer.registrationNumber,
                    name: currentCustomer.name,
                    address: currentCustomer.address,
                  }
                : {
                    type: currentCustomer.type,
                    id: currentCustomer.registrationNumber || "",
                    name: currentCustomer.name,
                  },
              purchaseOrderReference:
                guestName !== "" ? "Guest Name: " + guestName : "",
              purchaseOrderDescription: `Arrival Date ${arrivalDate} Departure Date ${departureDate} Room Number ${roomNumber}`,

              taxpayerActivityCode: branch.activityCode,
              internalID: `${prefix}${currentFolioNumber.toString()}`,

              // Zeros Fields
              totalDiscountAmount: 0,
              extraDiscountAmount: 0,
              totalItemsDiscountAmount: 0,

              // Calculated Fields
              invoiceLines: updateInvoiceLines,
              netAmount: +totalNet.toFixed(5),
              totalSalesAmount: +totalSalesAmount.toFixed(5),
              totalAmount: +totalAmount.toFixed(5),
              taxTotals: [
                {
                  taxType: "T1",
                  amount: +totalTax.toFixed(5),
                },
                {
                  taxType: "T9",
                  amount: +totalService.toFixed(5),
                },
              ],
            };

            if (invoiceLines.length > 0) invoices.push(invoice);

            // Init new invoice
            totalNet = 0;
            totalTax = 0;
            totalService = 0;
            totalSalesAmount = 0;
            totalAmount = 0;
            invoiceLines = [];
            convertCurrency = false;

            currentFolioNumber = folioNo;
            guestName = (firstName + " " + lastName).trim();
            currentCustomer = await getDocumentCustomer(
              accountNumber,
              firstName,
              lastName,
              customers,
              identificationID
            );
          }

          netAmount = row["netAmount"] || 0;
          quantity = Math.abs(row["quantity"]) || 0;
          salesAmount = Math.abs(row["netAmount"]) || 0;
          tax = Math.abs(row["tax"]) || 0;
          serviceCharge = Math.abs(row["serviceCharge"]) || 0;
          grossAmount = Math.abs(row["grossAmount"]) || 0;
          unitPrice = +salesAmount / +quantity;
          currency = row["currency"] || "";
          exchangeRate = row["exchangeRate"] || "";
          transactionCode = row["transactionCode"] || "";

          // Reservation Info
          arrivalDate = row["arrivalDate"] || "";
          departureDate = row["departureDate"] || "";
          roomNumber = row["roomNumber"] || "";

          if (typeof arrivalDate === "number") {
            arrivalDate = await getDocumentDateFormatted(arrivalDate);
          }

          if (typeof departureDate === "number") {
            departureDate = await getDocumentDateFormatted(departureDate);
          }
          // Skip zero lines
          if (+netAmount === 0) {
            continue;
          }

          if (
            (documentType === "I" && +netAmount < 0) ||
            (documentType === "C" && +netAmount > 0)
          ) {
            continue;
          }
          netAmount = Math.abs(netAmount);

          totalTax += +tax;
          totalService += +serviceCharge;
          totalNet += +netAmount;
          totalSalesAmount += +salesAmount;
          totalAmount += +grossAmount;
          rate = +(1 / exchangeRate).toFixed(5);

          const itemMapping = itemsMapping.find(
            // eslint-disable-next-line no-loop-func
            (obj: Item) => obj.internalCode === transactionCode.toString()
          );

          let line = {
            description: itemMapping ? itemMapping.description : "",
            internalCode: transactionCode.toString(),
            itemCode: itemMapping ? itemMapping.itemCode : "",
            itemType: itemMapping ? itemMapping.type : "",
            unitType: itemMapping ? itemMapping.unit : "EA",

            itemsDiscount: 0,
            netTotal: +netAmount.toFixed(5),
            quantity: quantity,
            salesTotal: +salesAmount.toFixed(5),
            total: +grossAmount.toFixed(5),
            totalTaxableFees: +serviceCharge.toFixed(5),

            unitValue:
              currency !== "SAR"
                ? {
                    amountEGP: +unitPrice.toFixed(5),
                    amountSold: +(unitPrice / rate).toFixed(5),
                    currencyExchangeRate: rate,
                    currencySold: currency,
                  }
                : {
                    amountEGP: +unitPrice.toFixed(5),
                    currencySold: currency,
                  },
            valueDifference: 0,

            taxableItems: [
              {
                amount: +tax.toFixed(5),
                rate: 14,
                subType: "V009",
                taxType: "T1",
              },
              {
                amount: +serviceCharge.toFixed(5),
                rate: 12,
                subType: "SC01",
                taxType: "T9",
              },
            ],
          };

          invoiceLines.push(line);
        }

        // Add last invoice
        let invoice = {
          // Static Fields
          documentType: documentType,
          documentTypeVersion: documentTypeVersion,
          dateTimeIssued: date,

          issuer: issuer,
          receiver: currentCustomer.address
            ? {
                type: currentCustomer.type,
                id: currentCustomer.registrationNumber,
                name: currentCustomer.name,
                address: currentCustomer.address,
              }
            : {
                type: currentCustomer.type,
                id: currentCustomer.registrationNumber || "",
                name: currentCustomer.name,
              },
          purchaseOrderReference:
            guestName !== "" ? "Guest Name: " + guestName : "",
          purchaseOrderDescription: `Arrival Date ${arrivalDate} Departure Date ${departureDate} Room Number ${roomNumber}`,

          taxpayerActivityCode: branch.activityCode,
          internalID: `${prefix}${currentFolioNumber}`,

          // Zeros Fields
          totalDiscountAmount: 0,
          extraDiscountAmount: 0,
          totalItemsDiscountAmount: 0,

          // Calculated Fields
          invoiceLines: invoiceLines,
          netAmount: +totalNet.toFixed(5),
          totalSalesAmount: +totalSalesAmount.toFixed(5),
          totalAmount: +totalAmount.toFixed(5),
          taxTotals: [
            {
              taxType: "T1",
              amount: +totalTax.toFixed(5),
            },
            {
              taxType: "T9",
              amount: +totalService.toFixed(5),
            },
          ],
        };
        if (invoiceLines.length > 0) invoices.push(invoice);

        console.log(JSON.stringify(invoices));
        return invoices;
      };
    } catch (err: Error | any) {
      console.log(err);
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
    }
  }

  async function getDocumentCustomer(
    accountNumber: string,
    firstName: string,
    lastName: string,
    customers: Customer[],
    identificationID: string
  ) {
    let currentCustomer;

    if (accountNumber !== "") {
      for (let i = 0; i < customers.length; i++) {
        if (customers[i].ArNumber === accountNumber + "") {
          currentCustomer = customers[i];

          // Use customer passport number in case of "F", and there is identificationID
          if (identificationID !== "" && currentCustomer.type === "F") {
            currentCustomer.registrationNumber = identificationID;
          }

          break;
        }
      }
    } else {
      if (identificationID !== "" && identificationID.length !== 14) {
        currentCustomer = {
          type: "F",
          registrationNumber: identificationID,
          name: (firstName + " " + lastName).trim(),
        };
      } else {
        currentCustomer = {
          // type: identificationID.length === 14 ? "P" : "F",
          // id: identificationID,
          type: "P",
          registrationNumber: "",
          name: (firstName + " " + lastName).trim(),
        };
      }
    }

    return currentCustomer;
  }

  async function getDocumentDateFormatted(dateNumber: number) {
    let converted_date = new Date(Math.round((dateNumber - 25569) * 864e5));
    let converted_date_str = String(converted_date).slice(4, 15);

    let date = converted_date_str.split(" ");
    let day = date[1];
    let month = date[0];
    let monthNumber =
      "JanFebMarAprMayJunJulAugSepOctNovDec".indexOf(month) / 3 + 1;
    let year = date[2];

    let result = String(day + "-" + monthNumber + "-" + year);
    return result;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "internalID",
      numeric: false,
      disablePadding: false,
      label: "Folio Number",
    },
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: "Type",
    },
    {
      id: "conceptID",
      numeric: false,
      disablePadding: false,
      label: "Concept",
    },
    {
      id: "netAmount",
      numeric: false,
      disablePadding: false,
      label: "Net Amount",
    },
    {
      id: "totalAmount",
      numeric: false,
      disablePadding: false,
      label: "Amount",
    },
    {
      id: "issuer",
      numeric: false,
      disablePadding: false,
      label: "Branch",
    },
    {
      id: "receiver",
      numeric: false,
      disablePadding: false,
      label: "Customer",
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Status",
    },
    {
      id: "reason",
      numeric: false,
      disablePadding: false,
      label: "Reason",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: false,
      label: "",
    },
  ];

  const lineHeadCells: readonly HeadCell[] = [
    {
      id: "internalCode",
      numeric: false,
      disablePadding: false,
      label: "Internal Code",
    },
    {
      id: "description",
      numeric: false,
      disablePadding: false,
      label: "Description",
    },
    {
      id: "itemCode",
      numeric: false,
      disablePadding: false,
      label: "Item Code",
    },
    {
      id: "itemType",
      numeric: false,
      disablePadding: false,
      label: "Item Type",
    },
    {
      id: "quantity",
      numeric: false,
      disablePadding: false,
      label: "Quantity",
    },
    {
      id: "netTotal",
      numeric: false,
      disablePadding: false,
      label: "Net Total",
    },
    {
      id: "total",
      numeric: false,
      disablePadding: false,
      label: "Total",
    },
    {
      id: "unitType",
      numeric: false,
      disablePadding: false,
      label: "Unit Type",
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: false,
      label: "Actions",
    },
  ];

  const dataCells: readonly string[] = [
    "internalID",
    "type",
    "conceptID",
    "netAmount",
    "totalSalesAmount",
    "issuer",
    "receiver",
    "status",
    "reason",
  ];

  const options: any[] = [];

  if (listing)
    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }

  const api: any = {};

  api[`${listingName}Listing`] = listing;
  api[`${listingName}Options`] = options;
  api[`${listingName}SearchText`] = searchText;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}LineHeadCells`] = lineHeadCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Selected`] = selected;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchStats`] = fetchStats;
  api[`${listingName}Get`] = get;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}CancelInvoice`] = cancelInvoice;
  api[`${listingName}ResubmitInvoice`] = resubmitInvoice;
  api[`${listingName}SubmitCreditNote`] = submitCreditNote;
  api[`${listingName}ImportData`] = importDataExcelFile;

  api[`${listingName}ChangeListing`] = (listing: Invoice[]) =>
    dispatch(setListing(listing));
  api[`${listingName}Search`] = (searchText: string) =>
    dispatch(setSearchText(searchText));
  api[`${listingName}Select`] = (conceptID: string) =>
    dispatch(setSelected(conceptID));

  return api;
};

export default useInvoice;
