import { useDispatch, useSelector } from "react-redux";
import { API } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

import {
  setListing,
  setSearchText,
  setSelected,
  setNextToken,
} from "../store/ducks/language";
import { Language } from "../models";
import { HeadCell } from "../models/dataTable";
import useApp from "./useApp";
import { CreateLanguageInput, UpdateLanguageInput } from "../models/GQL_API";
import { getLanguage, listLanguages } from "../graphql/queries";
import {
  createLanguage,
  deleteLanguage,
  updateLanguage,
} from "../graphql/mutations";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { session, showConfirm, showError } = useApp();
  const listing = useSelector(
    (state: any) => state[`${listingName}`]["listing"]
  );
  const searchText = useSelector(
    (state: any) => state[`${listingName}`]["searchText"]
  );
  const selected = useSelector(
    (state: any) => state[`${listingName}`]["selected"]
  );
  const nextToken = useSelector(
    (state: any) => state[`${listingName}`]["nextToken"]
  );

  async function fetch(startIndex: number, limit: number) {
    try {
      const filter: any = {
        deleted: { eq: "0" },
      };

      if (searchText) {
        filter.or = [];
        filter.or.push({ name: { contains: searchText.toLowerCase() } });
        filter.or.push({ code: { contains: searchText.toLowerCase() } });
      }

      const groupsList: any = await API.graphql<Language>({
        query: listLanguages,
        variables: { filter, limit, nextToken: nextToken },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      const currentNextToken = groupsList.data.listLanguages.nextToken;
      const listing = groupsList.data.listLanguages.items;

      dispatch(setListing(listing));
      dispatch(setNextToken(currentNextToken));

      return listing;
    } catch (err) {
      showError(err);
    }
  }

  async function get(id: string) {
    try {
      let single: Language | undefined;
      if (listing.length !== 0) {
        single = listing.find((resource: any) => resource.id === id);
      }

      if (single === undefined) {
        const listing: any = await API.graphql<Language>({
          query: getLanguage,
          variables: { id },
          authMode: true
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
        single = listing.data.getLanguage;
      }
      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function create(data: any) {
    try {
      const createInput: CreateLanguageInput = {
        name: data.name,
        code: data.code,
        deleted: "0",
        createdAt: new Date().getTime().toString(),
        createdByID: session.sub,
        createdByName: session.name,
      };

      await API.graphql<Language>({
        query: createLanguage,
        variables: { input: createInput },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      showConfirm(`New ${singleName} has been created successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function update(id: string, data: any) {
    try {
      const original = await get(id);
      if (original) {
        const updateInput: UpdateLanguageInput = {
          id: original.id,
          name: data.name ? data.name.toLowerCase() : original!.name,
          code: data.code ? data.code.toLowerCase() : original!.code,
        };

        await API.graphql<Language>({
          query: updateLanguage,
          variables: { input: updateInput },
          authMode: true
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
      }

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function trash(id: string) {
    try {
      const original = await get(id);

      if (original) {
        const updateInput: UpdateLanguageInput = {
          id: original.id,
          deleted: "1",
        };

        await API.graphql<Language>({
          query: updateLanguage,
          variables: { input: updateInput },
          authMode: true
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
      }

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(resourceIds: any) {
    for (let id of resourceIds) {
      try {
        await trash(id);
      } catch (err: Error | any) {
        showError(err);
      }
    }

    dispatch(
      setListing(
        listing.filter((resource: any) => !resourceIds.has(resource.id))
      )
    );

    showConfirm(
      `${resourceIds.size} ${listingName} items has been moved to trash`
    );
  }

  async function remove(id: any) {
    try {
      await API.graphql<Language>({
        query: deleteLanguage,
        variables: { id: id },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      dispatch(
        setListing(
          listing.filter((resource: any) => resource.id !== id)
        )
      );

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      console.log(err);
      showError(err);
    }
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["name"];

  const options: any[] = [{ label: "None", value: "-1" }];

  for (let option of listing) {
    options.push({ label: option.name, value: option.id });
  }

  const api: any = {};

  api[`${listingName}Listing`] = listing;
  api[`${listingName}Options`] = options;
  api[`${listingName}SearchText`] = searchText;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Model`] = Language as any;
  api[`${listingName}Selected`] = selected;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}ChangeListing`] = (listing: Language[]) =>
    dispatch(setListing(listing));
  api[`${listingName}Search`] = (searchText: string) =>
    dispatch(setSearchText(searchText));
  api[`${listingName}Select`] = (conceptID: string) =>
    dispatch(setSelected(conceptID));

  return api;
};

export default useResource;
