// import * as APITypes from "../API";
// type GeneratedQuery<InputType, OutputType> = string & {
//   __generatedQueryInput: InputType;
//   __generatedQueryOutput: OutputType;
// };

// export const zatcaInvoicesByDeletedCustom =
//   /* GraphQL */ `query ZatcaInvoicesByDeleted(
//   $deleted: String!
//   $invoiceCounterNumber: ModelIntKeyConditionInput
//   $sortDirection: ModelSortDirection
//   $filter: ModelZatcaInvoiceFilterInput
//   $limit: Int
//   $nextToken: String
// ) {
//   zatcaInvoicesByDeleted(
//     deleted: $deleted
//     invoiceCounterNumber: $invoiceCounterNumber
//     sortDirection: $sortDirection
//     filter: $filter
//     limit: $limit
//     nextToken: $nextToken
//   ) {
//     items {
//       id
//       status
//       taxInclusiveAmount
//     type
//     }
//     nextToken
//     __typename
//   }
// }
// ` as GeneratedQuery<
//     APITypes.ZatcaInvoicesByDeletedQueryVariables,
//     APITypes.ZatcaInvoicesByDeletedQuery
//   >;

export const zatcaInvoicesByDeletedCustom = /* GraphQL */ `
  query ZatcaInvoicesByDeletedSortedByDate(
    $deleted: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelZatcaInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    zatcaInvoicesByDeletedSortedByDate(
      deleted: $deleted
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        taxInclusiveAmount
        type
        createdAt
      }
      nextToken
      __typename
    }
  }
`;
