import { UpdateConceptInput } from "./../models/GQL_API";
import { API } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

import CryptoJS from "crypto-js";

import {
  setBranches,
  setListing,
  setSearchText,
  setSelected,
  setFilters,
  setSelectedFilters,
  setNextToken,
} from "../store/ducks/concept";
import { Concept } from "../models";
import { HeadCell } from "../models/dataTable";
import useApp from "./useApp";
import useAccount from "./useAccount";
import { getConcept, listConcepts } from "../graphql/queries";
import { ConceptListingVariables } from "../models/app";
import {
  createConcept,
  deleteConcept,
  updateConcept,
} from "../graphql/mutations";
import { CreateConceptInput } from "../API";



const useConcept: any = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { session, showConfirm, showError } = useApp();
  const { accountsSelected } = useAccount("accounts", "account");
  const listing = useSelector(
    (state: any) => state[`${listingName}`]["listing"]
  );
  const searchText = useSelector(
    (state: any) => state[`${listingName}`]["searchText"]
  );
  const selected = useSelector(
    (state: any) => state[`${listingName}`]["selected"]
  );
  const filters = useSelector(
    (state: any) => state[`${listingName}`]["filters"]
  );
  const selectedFilters = useSelector(
    (state: any) => state[`${listingName}`]["selectedFilters"]
  );
  const nextToken = useSelector(
    (state: any) => state[`${listingName}`]["nextToken"]
  );

  async function fetch(params: ConceptListingVariables) {
    try {
      const { accountID, limit } = params;

      const filter: any = {
        deleted: { eq: "0" },
      };

      if (searchText) filter.name = { contains: searchText.toLowerCase() };
      if (accountID) filter.accountID = { eq: accountID };

      const conceptListing: any = await API.graphql({
        query: listConcepts,
        variables: {
          limit: limit,
          filter
        },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      const currentNextToken = conceptListing.data.listConcepts.nextToken;
      const listing: any = conceptListing.data.listConcepts.items;

      if (listing.length > 0) {
        if (selected.length === 0) dispatch(setSelected(listing[0].id));
      }
      dispatch(setNextToken(currentNextToken));

      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message);
    }
  }

  async function get(id: string): Promise<Concept | undefined> {
    try {
      let single: Concept | undefined;
      if (listing.length !== 0) {
        single = listing.find((resource: any) => resource.id === id);
      }

      if (single === undefined) {
        const listing: any = await API.graphql<Concept>({
          query: getConcept,
          variables: { id, deleted: {eq: "0"} },
          authMode: true
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
        single = listing.data.getAccount;
      }

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function create(data: any) {
    if (accountsSelected.id.length === 0)
      throw new Error("Account must be selected");

    const encryptedProductionApiSecret = data.productionApiSecret
    const encryptedClientSecret = data.clientSecret

    try {
      let branchAddress = {
        branchId: data.branchId,
        country: data.country,
        governate: data.governate,
        regionCity: data.regionCity,
        citySubdivision: data.citySubdivision,
        plotIdentification: data.plotIdentification,
        street: data.street,
        buildingNumber: data.buildingNumber,
        postalCode: data.postalCode,
        floor: data.floor,
        room: data.room,
        landmark: data.landmark,
        additionalInformation: data.additionalInformation,
      };

      const createInput: CreateConceptInput = {
        accountID: accountsSelected ? accountsSelected.id : "",
        name: data.name,
        hotelCode: data.hotelCode,

        prefix: data.prefix,
        type: data.type,
        rin: data.rin,
        crn: data.rin,
        companyTradeName: data.companyTradeName,
        activityCode: data.activityCode,
        branchCode: data.branchCode,
        branchAddress: branchAddress,

        productionCertificate: data.productionCertificate,
        productionApiSecret: encryptedProductionApiSecret,

        clientId: data.clientId,
        clientSecret: encryptedClientSecret,
        synchronizeProcess: data.synchronizeProcess,

        deleted: "0",
        createdAt: new Date().getTime().toString(),
        createdByID: session.sub,
        createdByName: session.name,
      };

      await API.graphql<Concept>({
        query: createConcept,
        variables: { input: createInput },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      showConfirm(`New ${singleName} has been created successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function update(id: string, data: any) {
    try {
      const original = await get(id);

      if (original) {
        let branchAddress = {
          branchId: data.branchId,
          country: data.country,
          governate: data.governate,
          regionCity: data.regionCity,
          citySubdivision: data.citySubdivision,
          plotIdentification: data.plotIdentification,
          street: data.street,
          buildingNumber: data.buildingNumber,
          postalCode: data.postalCode,
          floor: data.floor,
          room: data.room,
          landmark: data.landmark,
          additionalInformation: data.additionalInformation,
        };

        let encryptedProductionApiSecret = original.productionApiSecret;
        let encryptedClientSecret = original.clientSecret;
        if (encryptedProductionApiSecret !== data.productionApiSecret && data.productionApiSecret) {
          encryptedProductionApiSecret = data.productionApiSecret
        } 
        if (encryptedClientSecret !== data.clientSecret && data.clientSecret) {
          encryptedClientSecret = data.clientSecret;
        }

        const updateInput: UpdateConceptInput = {
          id: original.id,
          name: data.name ? data.name : original!.name,
          hotelCode: data.hotelCode ? data.hotelCode : original!.hotelCode,

          prefix: data.prefix ? data.prefix : original!.prefix,
          type: data.type ? data.type : original!.type,
          rin: data.rin,
          crn: data.rin,
          companyTradeName: data.companyTradeName
            ? data.companyTradeName
            : original!.companyTradeName,
          activityCode: data.activityCode
            ? data.activityCode
            : original!.activityCode,
          
          productionCertificate: data.productionCertificate ? data.productionCertificate : original!.productionCertificate,
          productionApiSecret: encryptedProductionApiSecret,

          clientId: data.clientId ? data.clientId : original!.clientId,
          clientSecret: encryptedClientSecret,
          synchronizeProcess: data.synchronizeProcess,

          branchCode: data.branchCode ? data.branchCode : original!.branchCode,
          branchAddress: branchAddress,
        };

        await API.graphql<Concept>({
          query: updateConcept,
          variables: { input: updateInput },
          authMode: true
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
      }

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function trash(id: string) {
    try {
      const original = await get(id);

      if (original) {
        const updateInput: UpdateConceptInput = {
          id: original.id,
          deleted: "1",
        };

        await API.graphql<Concept>({
          query: updateConcept,
          variables: { input: updateInput },
          authMode: true
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
      }

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(resourceIds: any) {
    for (let id of resourceIds) {
      try {
        await trash(id);
      } catch (err: Error | any) {
        showError(err);
      }
    }

    dispatch(
      setListing(
        listing.filter((resource: any) => !resourceIds.has(resource.id))
      )
    );

    showConfirm(
      `${resourceIds.size} ${listingName} items has been moved to trash`
    );
  }

  async function remove(id: any) {
    try {
      await API.graphql<Concept>({
        query: deleteConcept,
        variables: { id: id },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      dispatch(
        setListing(listing.filter((resource: any) => resource.id !== id))
      );

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      console.log(err);
      showError(err);
    }
  }

  /**
   * Get Resource Name
   *
   * @param id id: string
   *
   * @returns string
   */
  const getName = (id: string) => {
    if (listing.length > 0) {
      const model = listing.find((model: Concept) => model.id === id);
      return model ? model.name : "";
    }

    return "";
  };

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "hotelCode",
      numeric: false,
      disablePadding: false,
      label: "Hotel Code",
    },
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: "Type",
    },
    {
      id: "rin",
      numeric: false,
      disablePadding: false,
      label: "RIN",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["name", "hotelCode", "type", "rin"];

  const options: any[] = [];

  if (listing)
    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }

  const api: any = {};

  api[`${listingName}Listing`] = listing;
  api[`${listingName}Filters`] = filters;
  api[`${listingName}SelectedFilters`] = selectedFilters;
  api[`${listingName}Options`] = options;
  api[`${listingName}SearchText`] = searchText;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Model`] = Concept as any;
  api[`${listingName}Selected`] = selected;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}GetName`] = getName;
  api[`${listingName}ChangeListing`] = (listing: Concept[]) => {
    dispatch(setListing(listing));
    dispatch(setFilters(listing.map((model: any) => model.name)));
  };
  api[`${listingName}Search`] = (searchText: string) =>
    dispatch(setSearchText(searchText));
  api[`${listingName}Select`] = (conceptID: string) =>
    dispatch(setSelected(conceptID));
  api[`${listingName}SetBranches`] = (branches: any) =>
    dispatch(setBranches(branches));
  api[`${listingName}ChangeFilters`] = (filters: string[]) =>
    dispatch(setFilters(filters));
  api[`${listingName}ChangeSelectedFilters`] = (filters: any) =>
    dispatch(setSelectedFilters(filters));

  return api;
};

export default useConcept;
