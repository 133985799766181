import * as XLSX from "xlsx";
import { UpdateItemInput } from "./../API";
import { createItem, deleteItem, updateItem } from "./../graphql/mutations";
import { API } from "aws-amplify";
import { GRAPHQL_AUTH_MODE, GraphQLQuery } from "@aws-amplify/api";
import { useDispatch, useSelector } from "react-redux";
import {
  setListing,
  setSearchText,
  setSelected,
  setNextToken,
  setPreviousToken,
  changeLimit,
  nextAction,
} from "../store/ducks/item";
import { HeadCell } from "../models/dataTable";
import { ListingByAccountVariables } from "../models/app";
import useApp from "./useApp";
import { getItem, listItems } from "../graphql/queries";
import { CreateItemInput, Item } from "../models/GQL_API";
import useAccount from "./useAccount";

const useItems = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { session, showConfirm, showError } = useApp();
  const { accountsSelected } = useAccount("accounts", "account");

  const listing = useSelector(
    (state: any) => state[`${listingName}`]["listing"]
  );
  const searchText = useSelector(
    (state: any) => state[`${listingName}`]["searchText"]
  );
  const selected = useSelector(
    (state: any) => state[`${listingName}`]["selected"]
  );

  const storedLimit = useSelector(
    (state: any) => state[`${listingName}`]["limit"]
  );

  const nextToken = useSelector(
    (state: any) => state[`${listingName}`]["nextToken"]
  );
  const previousTokens = useSelector(
    (state: any) => state[`${listingName}`]["previousTokens"]
  );

  async function fetch(props: ListingByAccountVariables) {
    const { startIndex, limit, accountID, moveForward } = props;

    let requestLimit = storedLimit;
    let requestToken = nextToken;
    let requestPreviousTokens = previousTokens;

    try {
      // Clear pagination data
      if (limit !== storedLimit || startIndex === 0) {
        requestLimit = limit;
        requestToken = null;
        requestPreviousTokens = [];

        dispatch(changeLimit(limit));
      }

      // Get token from previous tokens
      if (!moveForward) {
        const updatedPreviousTokens = [...requestPreviousTokens];
        updatedPreviousTokens.pop();

        requestToken =
          updatedPreviousTokens.length >= 2
            ? updatedPreviousTokens[updatedPreviousTokens.length - 2]
            : null;
        dispatch(
          setNextToken(updatedPreviousTokens[updatedPreviousTokens.length - 1])
        );
        dispatch(setPreviousToken(updatedPreviousTokens));
      }

      /* Filter part */
      const filter: any = {
        deleted: { eq: "0" },
        accountID: { eq: accountID },
      };

      if (searchText) {
        filter.or = [];
        filter.or.push({ description: { contains: searchText.toLowerCase() } });
        filter.or.push({
          itemCode: { contains: searchText },
        });
        filter.or.push({
          internalCode: { contains: searchText },
        });
      }

      const itemList: any = await API.graphql<Item>({
        query: listItems,
        variables: { filter, limit: requestLimit, nextToken: requestToken },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      if (moveForward) {
        const currentNextToken = itemList.data.listItems.nextToken;
        dispatch(nextAction(currentNextToken, requestPreviousTokens));
      }

      const listing = itemList.data.listItems.items;
      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message);
    }
  }

  async function get(id: string) {
    try {
      let single: Item | undefined;
      if (listing.length !== 0) {
        single = listing.find((resource: any) => resource.id === id);
      }

      if (single === undefined) {
        const listing: any = await API.graphql<Item>({
          query: getItem,
          variables: { id },
          authMode: true
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
        single = listing.data.getItem;
      }

      return single;
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  }

  async function create(data: any) {
    try {
      const createInput: CreateItemInput = {
        accountID: accountsSelected ? accountsSelected.id : "",

        description: data.description ? data.description.toLowerCase() : "",
        internalCode: data.internalCode ? data.internalCode : "",
        itemCode: data.itemCode ? data.itemCode : "",
        type: data.type ? data.type : "",
        unit: data.unit ? data.unit : "",
        tax1: data.tax1 ? data.tax1 : "",
        tax2: data.tax2 ? data.tax2 : "",
        neglectedItem: data.neglectedItem,

        deleted: "0",
        createdAt: new Date().getTime().toString(),
        createdByID: session.sub,
        createdByName: session.name,
      };

      const item: any = await API.graphql<GraphQLQuery<Item>>({
        query: createItem,
        variables: { input: createInput },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      const createdItem = item.data.createItem;
      dispatch(setListing([createdItem, ...listing]));
    } catch (err) {
      showError(err);
    }
  }

  async function update(id: string, data: any) {
    try {
      const original = await get(id);
      if (!original) {
        showError(`Can not find this ${singleName} entry.`);
        return;
      }

      const updateInput: UpdateItemInput = {
        id: original.id,

        accountID: data.accountID ? data.accountID : original!.accountID,
        description: data.description
          ? data.description
          : original!.description,
        internalCode: data.internalCode
          ? data.internalCode
          : original!.internalCode,
        itemCode: data.itemCode ? data.itemCode : original!.itemCode,
        type: data.type ? data.type : original!.type,
        unit: data.unit ? data.unit : original!.unit,
        tax1: data.tax1 ? data.tax1 : original!.tax1,
        tax2: data.tax2 ? data.tax2 : original!.tax2,
        neglectedItem: data.neglectedItem,

        createdAt: data.createdAt ? data.createdAt : original!.createdAt,
      };

      await API.graphql<Item>({
        query: updateItem,
        variables: { input: updateInput },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      showConfirm(`${singleName} has been updated successfully`);
      return update;
    } catch (err) {
      showError(err);
    }
  }

  async function trash(id: string) {
    try {
      const original = await get(id);

      if (original) {
        const updateInput: UpdateItemInput = {
          id: original.id,
          deleted: "1",
        };

        await API.graphql<Item>({
          query: updateItem,
          variables: { input: updateInput },
          authMode: true
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
      }

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  }

  async function bulkTrash(ids: any) {
    for (let id of ids) {
      try {
        await trash(id);
      } catch (err: Error | any) {
        showError(err);
      }
    }

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(id: any) {
    try {
      await API.graphql<Item>({
        query: deleteItem,
        variables: { id: id },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      dispatch(
        setListing(listing.filter((resource: any) => resource.id !== id))
      );

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  }

  async function exportAll(params: ListingByAccountVariables) {
    try {
      let exportedData = [];

      const data = await fetch(params);

      for (let user of data!) {
        let row: any = { ...user };
        exportedData.push(row);
      }

      return exportedData;
    } catch (err) {
      showError(err);
    }
  }

  async function importDataExcelFile(file: File, accountID: string) {
    const reader = new FileReader();
    reader.readAsBinaryString(file);

    reader.onload = async (event) => {
      const data = event.target?.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const rows = XLSX.utils.sheet_to_json(worksheet);

      for (let i = 0; i < rows.length; i++) {
        const row: any = rows[i];

        let description = row["description"];
        let internalCode = row["internalCode"];
        let itemCode = row["itemCode"];

        let data = {
          accountID: accountID,
          internalCode: internalCode,
          itemCode: itemCode,
          description: description,
          type: "EGS",
          unit: "EA",
          tax1: "14",
          tax2: "12",
          neglectedItem: false,
        };

        await create(data);
      }
    };
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "description",
      numeric: false,
      disablePadding: false,
      label: "Description",
    },
    {
      id: "internalCode",
      numeric: false,
      disablePadding: false,
      label: "Internal Code",
    },
    {
      id: "itemCode",
      numeric: false,
      disablePadding: false,
      label: "Item Code",
    },
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: "Type",
    },
    {
      id: "unit",
      numeric: false,
      disablePadding: false,
      label: "Unit",
    },
    {
      id: "tax1",
      numeric: false,
      disablePadding: false,
      label: "Tax1",
    },
    {
      id: "tax2",
      numeric: false,
      disablePadding: false,
      label: "Tax 2",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = [
    "description",
    "internalCode",
    "itemCode",
    "type",
    "unit",
    "tax1",
    "tax2",
  ];

  const options: any[] = [];

  if (listing)
    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }

  const api: any = {};

  api[`${listingName}Listing`] = listing;
  api[`${listingName}Options`] = options;
  api[`${listingName}SearchText`] = searchText;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Selected`] = selected;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}Export`] = exportAll;
  api[`${listingName}ImportData`] = importDataExcelFile;

  api[`${listingName}ChangeListing`] = (listing: Item[]) =>
    dispatch(setListing(listing));
  api[`${listingName}Search`] = (searchText: string) =>
    dispatch(setSearchText(searchText));
  api[`${listingName}ChangeListing`] = (listing: Item[]) =>
    dispatch(setListing(listing));
  api[`${listingName}Select`] = (conceptID: string) =>
    dispatch(setSelected(conceptID));

  return api;
};

export default useItems;
