import { Order } from "../models/order";
import { Orders, Zatca_Tax_Rate } from "../constants/enums";
import { HeadCell } from "../models/dataTable";
import * as XLSX from "xlsx";
import AWS from "aws-sdk";
import { ZatcaInvoice, ZatcaInvoiceLine } from "../API";

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

/**
 * Capitalize first letter of a given string
 *
 * @param string string: string
 *
 * @returns string
 */
export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === Orders.DSC
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array: any[], comparator: any) {
  if (array) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  } else {
    return [];
  }
}

export function exportXLSX(
  slug: string,
  headCells: readonly HeadCell[],
  data: any[],
  rowKeys: string[],
  selected?: Set<string>,
  filename?: string,
  sorted?: boolean
) {
  console.log({slug,data})
  const heads: any = [];
  const rows: any = [];

  let exportData = stableSort(data, getComparator(Orders.ASC, "createdAt"));
  if (sorted) {
    exportData = data;
  }

  if (selected) {
    exportData = data.filter((row: any) => selected.has(row.id));
  }

  for (let i = 0; i < headCells.length - 1; i++) {
    // csv format doesn't accept empty symbols
    // if (headCells[i].label === "branch") continue;
    headCells[i].label === "# of Guests"
      ? heads.push("Number of Guests")
      : heads.push(headCells[i].label);
    // headCells[i].label === "concept" 
    //   ? heads.push("Branch")
    //   : heads.push(headCells[i].label);
  }

  rows.push(heads);

  /* Prepare Table Data */
  for (let row of exportData) {
    const rowItems: any = [];

    console.log({row})

    for (let key of rowKeys) {
      console.log({ key });
      console.log({ rowAtKey: row[key] })
      if (key === "customer") {
        const customer = row[key] ? row[key] : "N/A";
      } else if (key === "errorMessages") {
        const errorMessagesArr = JSON.parse(row.errorMessages);
        const errorMessage = errorMessagesArr?.length ? errorMessagesArr.join(" - ") : 'N/A'; 
        rowItems.push(errorMessage);
      } else {
        rowItems.push(row[key]);
      }
    }

    rowItems.push(row.createdByName ? row.createdByName : "Admin");
    rowItems.push(row.createdAt.split(",")[0]);

    rows.push(rowItems);
  }

  const wb = XLSX.utils.book_new();
  const newWs = XLSX.utils.aoa_to_sheet(rows);
  XLSX.utils.book_append_sheet(wb, newWs);
  const rawExcel = XLSX.write(wb, { type: "base64" });

  const encodedUri = encodeURI(rawExcel);
  const link = document.createElement("a");
  link.setAttribute(
    "href",
    "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
      encodedUri
  );
  link.setAttribute("download", `${filename ? filename : slug}.xlsx`);
  document.body.appendChild(link); // Required for FF

  link.click();
}

export function getDateFormatted(date: Date) {
  const today = date ? new Date(date) : new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0");
  const yyyy = today.getFullYear();

  return yyyy + "-" + mm + "-" + dd;
}

// get hours and minutes
export function getFullDateFormatted(date: String) {
  const formattedDate = date.replace("T", " ").replace("Z", "");

  return formattedDate;
}

// Convert date in Timestamp to readable date
export function convertTimestampToDate(timestamp: string) {
  const date = new Date(Number(timestamp));
  const formattedDate = date.toLocaleString();

  return formattedDate;
}

export function getFormattedDate(inputDate: Date) {
  const date = inputDate ? new Date(inputDate) : new Date();

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  const milliseconds = String(date.getMilliseconds()).padStart(3, "0");

  const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
  return formattedDate;
}

export function capitalizeWords(string: string) {
  return string.replace(/(?:^|\s)\S/g, function (a) {
    return a.toUpperCase();
  });
}

export const getSiteAddress = () => {
  let siteAddress = window.location.hostname;
  return siteAddress;
};

export const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "kioskdev" ||
    // [::1] is the IPv6 kioskdev address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    ) ||
    window.location.hostname.match(
      /^192(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export const isProduction = Boolean(
  window.location.hostname === "192.168.42.194:3000" ||
    window.location.hostname === "prod.d1uxgdbmrbcym6.amplifyapp.com"
);

export function convertToCamelCase(text: string) {
  return text.replace(/-([a-z])/g, function (g) {
    return g[1].toUpperCase();
  });
}

export function extractSelectedCheckboxes(keyName: string, data: any) {
  const selected: string[] = [];

  for (let key of Object.getOwnPropertyNames(data)) {
    if (key.includes(keyName)) {
      if (data[key] === true) {
        selected.push(key.replace(keyName, ""));
      }
    }
  }

  return selected;
}

export function validatePhone(phone: string) {
  var re = /^[0][1-9]\d{9}$|^[1-9]\d{9}$/;
  return re.test(phone);
}

export async function clearBrowser() {
  // remove indexedDB
  const indexedDBs = await window.indexedDB.databases();
  const amplifyDB = indexedDBs.find(
    (db: any) => db.name === "amplify-datastore"
  )?.name;
  if (amplifyDB) window.indexedDB.deleteDatabase(amplifyDB);

  // clear localStorage
  window.localStorage.clear();

  // clear sessionStorage
  window.sessionStorage.clear();

  // reload
  window.location.reload();
}

// export const LambdaNames = {
//   Zatca: "sendInvoiceZatca",
// };

// export async function invokeLambda(functionName: any, requestBody: any) {
//   let lambda = new AWS.Lambda();

//   try {
//     const options = {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(requestBody),
//     };

//     let response: any = await lambda
//       .invoke({
//         FunctionName: `${functionName}-dev`,
//         Payload: JSON.stringify(options),
//       })
//       .promise();

//     if (response.StatusCode !== 200) {
//       throw new Error(
//         `Lambda invocation returned status code: ${response.StatusCode}`
//       );
//     }

//     if (!response.Payload) {
//       throw new Error("No payload received from lambda function");
//     }
//     let payload;

//     payload = JSON.parse(response.Payload);
//     if (payload.statusCode !== 200) {
//       throw new Error(
//         `Lambda function returned error with status code: ${payload.statusCode}`
//       );
//     }
//     if (!payload.body) {
//       throw new Error("No body in the payload");
//     }

//     let body = JSON.parse(payload.body);

//     return body;
//   } catch (error) {
//     console.error("Error invoking lambda function:", error);
//     // Depending on your use case, you might want to rethrow the error, return a default value, or handle it in another way.
//     throw error; // Rethrow the error if you want the caller to handle it.
//   }
// }
export function toFixedNoRounding(num:number, n:number) {
    const reg = new RegExp("^-?\\d+(?:\\.\\d{0," + n + "})?", "g");
    let x = Math.round((Number(num) + Number.EPSILON) * 100) / 100; //added rounding, zatca returns error without rouding
    let m = x.toString().match(reg);
    if (m?.length) {
        const a = m[0];
        const dot = a.indexOf(".");
        if (dot === -1) {
            return a + "." + "0".repeat(n);
        }
        const b = n - (a.length - dot) + 1;
        return b > 0 ? (a + "0".repeat(b)) : a;
    }
    return "0.00";
};

export function calcZatcaPrices(zatcaInvoice: ZatcaInvoice) {
  let taxExclusiveAmount = 0;
  let totalTaxes = 0;
  const items: ZatcaInvoiceLine[] = (zatcaInvoice?.invoiceLines ?? []).filter(line => line !== null) as ZatcaInvoiceLine[];
  for (const item of items) {
    taxExclusiveAmount += item.taxExclusivePrice ?? 0;
    totalTaxes += item.taxExclusivePrice * Zatca_Tax_Rate;
  }
  const taxExclusiveTotal = parseFloat(toFixedNoRounding(taxExclusiveAmount, 2));
  const taxes_total_rounded = parseFloat(toFixedNoRounding(totalTaxes, 2));
  const taxInclusiveTotal = parseFloat(taxExclusiveTotal.toString()) + parseFloat(taxes_total_rounded.toString());
  return { taxExclusiveTotal, taxInclusiveTotal };
};

export function renderPickedDates(
  startDate: Date,
  endDate: Date,
  BookingDateStatus: boolean = false
): string {
  const startWeekday = startDate.toLocaleString("default", {
    weekday: "short",
  });
  const endWeekday = endDate.toLocaleString("default", {
    weekday: "short",
  });

  if (BookingDateStatus) {
    if (getDateFormatted(startDate) === getDateFormatted(endDate))
      return `${startWeekday} ${getDateFormatted(startDate)}`;

    return `${startWeekday} ${getDateFormatted(
      startDate
    )} to ${endWeekday} ${getDateFormatted(endDate)}`;
  } else {
    if (getDateFormatted(startDate) === getDateFormatted(endDate))
      return `${startWeekday} ${getDateFormatted(
        startDate
      )}`;

    return `${startWeekday} ${getDateFormatted(
      startDate
    )} to ${endWeekday} ${getDateFormatted(endDate)}`;
  }
}