import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";

import useApp from "../../../hooks/useApp";
import { FormActions, Pages } from "../../../constants/enums";
import useListings from "../../../hooks/useListings";
import FormField from "../FormFields";
import useFormFields from "../../../hooks/useFormFields";
import useFormValidations from "../../../hooks/useFormValidations";

interface Props {
  slug: string;
}

const CreateModal: React.FC<Props> = ({ slug }) => {
  const { hideDeletePopup } = useApp();
  const { createResource } = useListings(slug);
  const { getFormFields } = useFormFields(slug, null);
  const { getValidationSchema } = useFormValidations(slug, FormActions.ADD);
  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<any>({ resolver: yupResolver(getValidationSchema()) });

  const renderTitle = () => {
    switch (slug) {
      case Pages.MEDIA:
        return "permanently media file";
      default:
        return slug.slice(0, -1);
    }
  };

  const onSubmit: SubmitHandler<any> = async (data: any) => {
    await createResource(data);

    hideDeletePopup();
  };

  return (
    <Box
      sx={{
        width: "90%",
        maxWidth: 600,
        position: "relative",
        top: "50%",
        transform: "translateY(-50%)",
      }}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box
        sx={{
          background: (theme) => theme.palette.grey[300],
          padding: "8px 16px",
          color: "black",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h6"
          textTransform={"capitalize"}
        >{`New ${renderTitle()}`}</Typography>
        <CloseIcon
          aria-label="close modal"
          onClick={hideDeletePopup}
          sx={{ cursor: "pointer" }}
        />
      </Box>
      <Paper
        square
        elevation={0}
        sx={{
          border: (theme) => `1px solid ${theme.palette.grey[300]}`,
        }}
      >
        <Box sx={{ p: 2 }}>
          {getFormFields().map((field: any) => (
            <FormField
              key={field.name}
              control={control}
              name={field.name}
              label={field.label}
              type={field.type}
              autoFocus={field.autoFocus}
              defaultValue={field.defaultValue}
              options={field.options}
              register={register}
              setValue={setValue}
              errors={errors}
            />
          ))}
        </Box>
        <Box
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "flex-end",
            borderTop: (theme) => `1px solid ${theme.palette.grey[300]}`,
          }}
        >
          <Button
            disableElevation
            sx={{ mr: 2 }}
            variant="text"
            size="small"
            onClick={hideDeletePopup}
          >
            Cancel
          </Button>
          <Button
            disableElevation
            variant="contained"
            size="small"
            type="submit"
            disabled={isSubmitting}
          >
            Save
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default CreateModal;
