// Egyptian Gateway
export const environmentURL: string =
  "https://api.preprod.invoicing.eta.gov.eg";

export const EG_INVOICING_TOKEN: string = `https://id.preprod.eta.gov.eg/connect/token`;
export const EG_SUBMIT_DOCUMENT: string = `${environmentURL}/api/v1.0/documentsubmissions`;
export const EG_CANCEL_DOCUMENT: string = `${environmentURL}/api/v1.0/documents/state/`;

// Lambda Function
export const EG_SEND_INVOICE: string =
  "https://pjyfkfrgud7rww2fhqjxxx4jse0yrgwf.lambda-url.us-east-2.on.aws/";

export enum EG_OPERATIONS {
  INVOICE = "invoice",
  CANCEL_INVOICE = "cancelInvoice",
  CANCEL_INVOICE_LINE = "cancelInvoiceLine",
  SUBMIT_INVOICE = "submitInvoice",
  RESUBMIT_INVOICE = "resubmitInvoice",
  RECEIPT = "receipt",
  CREDIT_NOTE = "creditNote",
}

// export const testingCancelZatcaEndPoint =
//   "https://fgwywdii72l2etplcbuih7rzpy0uwnsa.lambda-url.us-east-2.on.aws/?branchId=2155bee8-5e68-47e6-a592-3b371199559e&operation=creditNote&env=test&type=simphony";
// export const testingResendZatcaEndPoint =
//   "https://fgwywdii72l2etplcbuih7rzpy0uwnsa.lambda-url.us-east-2.on.aws/?branchId=2155bee8-5e68-47e6-a592-3b371199559e&operation=resubmitInvoice&env=test&type=simphony";

export const createZatcaEndpoint = ({
  branchId,
  operation,
  env,
  type,
}: any) => {
  return `https://fgwywdii72l2etplcbuih7rzpy0uwnsa.lambda-url.us-east-2.on.aws/?branchId=${branchId}&operation=${operation}&env=${env}&type=${type}`;
};
