// actions
const SET = "accounts/SET";
const SET_SEARCH_TEXT = "accounts/SET_SEARCH_TEXT";
const SET_SELECTED = "accounts/SET_SELECTED";
const SET_NEXT_TOKEN = "accounts/SET_NEXT_TOKEN";

const DEFAULT_STATE = {
  listing: [],
  searchText: "",
  selected: null,
  nextToken: null,
  nextNextToken: undefined,
  previousTokens: [],
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case SET_SEARCH_TEXT:
      return Object.assign({}, state, { searchText: action.searchText });
    case SET_SELECTED:
      return Object.assign({}, state, { selected: action.model });
    case SET_NEXT_TOKEN:
      return Object.assign({}, state, { nextToken: action.id });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setListing = (listing: any[]) => ({ listing, type: SET });

export const setSearchText = (searchText: string) => ({
  searchText,
  type: SET_SEARCH_TEXT,
});

export const setSelected = (model: any) => ({ model, type: SET_SELECTED });
export const setNextToken = (id: any) => ({ id, type: SET_NEXT_TOKEN });
