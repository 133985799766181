import { lazy, Suspense, useCallback, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import ErrorBoundary from "./components/UI/ErrorBoundary";
import MainLoader from "./components/UI/MainLoader";
import MainModal from "./components/UI/MainModal";
import MainSnackbar from "./components/UI/MainSnackbar";
import useApp from "./hooks/useApp";
import useLoading from "./hooks/useLoading";
import useAccount from "./hooks/useAccount";
import useFeature from "./hooks/useFeature";
import useConcept from "./hooks/useConcept";
import { AdminPermissions, ListingByAccountVariables, ListingVariables } from "./models/app";
import usePermissions from "./hooks/usePermissions";
import useAdminGroup from "./hooks/useAdminGroup";
import useUserConcepts from "./hooks/useUserConcepts";

const Admin = lazy(() => import("./containers/Dashboard"));
const Auth = lazy(() => import("./containers/Auth"));

const App: React.FC = () => {
  const { loading, changeLoading } = useLoading();
  const { modalOpen, snackBarOpen, fetchSession, setSession, clearSession , setPermissions, setPermissionsLoaded, setConcepts} =
    useApp();
  const { accountsFetchBySiteAddress, accountsSelected } = useAccount(
    "accounts",
    "account"
  );
  const { featuresFetchAll, featuresAllListing, featuresChangeListing } =
    useFeature("features", "feature");

  const { conceptsFetch, conceptsChangeListing } = useConcept(
    "concepts",
    "concept"
  );

  const { adminGroupsFetchCurrentUser } = useAdminGroup(
    'adminGroups',
    'adminGroup',
  );
  const { userConceptsFetch } = useUserConcepts("userConcepts", "userConcept");
  const { getAdminPermissions } = usePermissions();

  const initApp = useCallback(async (session: any) => {
    try {
      changeLoading(true);

      setSession(session);

      const filteredConcepts: any[] = [];

      const listingByAccountParams: ListingByAccountVariables = {
        accountID: "",
        searchText: "",
        startIndex: 0,
        limit: 1000,
        session,
      };
      const params: ListingVariables = {
        searchText: "",
        startIndex: 0,
        limit: 1000,
      };
      const userConceptParams: any = {
        ...params,
        userID: session?.sub,
      };
      const data = await Promise.all([
        accountsFetchBySiteAddress(session),
        featuresFetchAll(listingByAccountParams),
        // userConceptsFetch(userConceptParams),
      ]);

      const account = data[0];
      const allFeatures = data[1];
      // const userConcepts = data[2][0];

      let concepts, userGroup;

      if (session) {
        listingByAccountParams.accountID = account.id;
        // const concepts = await conceptsFetch(listingByAccountParams);
        const resultData: any[] = await Promise.all([
          conceptsFetch(listingByAccountParams),
          adminGroupsFetchCurrentUser(session.sub),
        ]);
        concepts = resultData[0];
        userGroup = resultData[1];
        conceptsChangeListing(concepts);
        const permissions: AdminPermissions = await getAdminPermissions({
          userGroup,
        });

        // setUserPermissions(permissions);
        setPermissions(permissions);
        setPermissionsLoaded(true);

        
        // if (userConcepts) {
        // for (let concept of concepts) {
        //   if (userConcepts.concepts!.includes(concept.id)) {
        //     filteredConcepts.push(concept);
        //   }
        // }

        // setConcepts(userConcepts);
        // }
        

      }
      console.log({session})


      featuresChangeListing(allFeatures);
      changeLoading(false);
    } catch (err: Error | any) {
      clearSession();
      changeLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  const getSession = async () => {
    try {
      const session = await fetchSession();

      initApp(session);
    } catch (err: Error | any) {
      sync();
    }
  };

  const sync = async () => {
    initApp(null);
  };

  useEffect(() => {
    changeLoading(true);
    getSession();
    changeLoading(false);

    // eslint-disable-next-line
  }, []);

  if (loading && (!accountsSelected || !featuresAllListing))
    return <MainLoader />;

  return (
    <ErrorBoundary>
      <DndProvider backend={HTML5Backend}>
        <Suspense fallback={<MainLoader />}>
          <Routes>
            <Route path="/*" element={<Auth />} />
            <Route path="dashboard/*" element={<Admin />} />
          </Routes>
        </Suspense>
      </DndProvider>
      {modalOpen && <MainModal />}
      {snackBarOpen && <MainSnackbar />}
    </ErrorBoundary>
  );
};

export default App;
