import React, { useState } from "react";
import Button from "@mui/material/Button";
import { commonWhite } from "../../../constants/colors";
import { Box, Tooltip } from "@mui/material";

interface Props {
  label: string;
  attachment: any;
  qrString?: string;
}

const SetImage: React.FC<Props> = ({ label, attachment, qrString }) => {
  const [copied, setCopied] = useState(false);

  const handleClose = () => {
    setCopied(false);
  };

  const handleButtonClick = () => {
    if (qrString) {
      const element = document.createElement("textarea");
      element.value = qrString;
      document.body.appendChild(element);
      element.select();
      document.execCommand("copy");
      document.body.removeChild(element);
      setCopied(true);
    }
  };

  return (
    <>
      {attachment && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img src={`data:image/png;base64,${attachment}`} alt={label} />
        </Box>
      )}
      <Tooltip
        open={copied}
        onClose={handleClose}
        title={"Link Copied"}
        leaveDelay={400}
      >
        <Button
          size="small"
          fullWidth
          sx={{
            textDecoration: "underline",
            textTransform: "capitalize",
            border: (theme) => `1px solid ${theme.palette.divider}`,
            backgroundColor: (theme) => commonWhite(theme),
            borderTop: "none",
            p: 1,
            cursor: "pointer",
            "&:hover": {
              background: "transparent",
              textDecoration: "underline",
            },
            whiteSpace: "pre-line",
            overflowWrap: "anywhere",
          }}
          onClick={handleButtonClick}
        >
          {qrString}
        </Button>
      </Tooltip>
    </>
  );
};

export default SetImage;
